import { useLocation } from 'react-router-dom';
import React, { ReactElement, useEffect, useMemo } from 'react';
import Cookies from 'js-cookie';
import { APP_BASE_URL } from '@config/constants';
import { useAuth } from '@hooks/useAuth';

const silentLoginErrors = [
  'login_required',
  'consent_required',
  'interaction_required'
];

interface AuthorizationBoundaryProps {
  readonly element: ReactElement;
  readonly loader?: ReactElement | null;
  readonly renderError?: (
    error: Error,
    errorCode: string | undefined
  ) => ReactElement | null;
}

const rememberCookieName = 'producer-remember-me';
function setRememberCookie(userId: string, rememberMe: boolean) {
  const { host } = window.location;
  const defaultHosts = APP_BASE_URL.map((x) => new URL(x).hostname);
  const domain = `.${
    defaultHosts.find((x) => host === x || host.endsWith(`.${x}`)) ??
    defaultHosts[0]
  }`;
  Cookies.set(rememberCookieName, userId, {
    path: '/',
    domain,
    expires: rememberMe ? 365 : undefined
  });
}

function isSessionExpired(userId: string) {
  const rememberedUserId = Cookies.get(rememberCookieName);
  return rememberedUserId !== userId;
}

export function AuthorizationBoundary(
  props: Required<AuthorizationBoundaryProps>
) {
  const { element, loader, renderError } = props;
  const { isAuthenticated, user, isLoading, error, login, logout } = useAuth(
    false,
    'internal'
  );
  const location = useLocation();
  const authErrorCode = (error as any)?.error as string | undefined;
  const isSilentLoginError =
    authErrorCode && silentLoginErrors.includes(authErrorCode);
  const redirectToAuth =
    !isLoading && !isAuthenticated && (!error || isSilentLoginError);
  const userId: string | null = user ? user.id : null;
  const rememberMe: boolean | null = user ? user.rememberMe : null;
  const sessionExpired = useMemo(
    () =>
      userId !== null &&
      rememberMe === null && // If it's not null then it is first page load after login
      isSessionExpired(userId),
    [userId, rememberMe]
  );

  useEffect(() => {
    if (location.pathname.includes('sign-up')) {
      login({
        prompt: 'login',
        appState: {
          returnTo: `/accept-invite${window.location.search}`
        },
        targetPage: 'register'
      });

      return;
    }
    if (!redirectToAuth) return;

    if (location.pathname.includes('accept-invite')) {
      login({
        prompt: 'login',
        appState: {
          returnTo: `/accept-invite${window.location.search}`
        }
      });

      return;
    }
    if (location.pathname.includes('invalid-link')) {
      return;
    }

    login({
      prompt: isSilentLoginError ? 'login' : undefined,
      appState: { returnTo: window.location.pathname + window.location.search }
    });
  }, [redirectToAuth, login, isSilentLoginError, location.pathname]);

  useEffect(() => {
    if (userId !== null && rememberMe !== null) {
      setRememberCookie(userId, rememberMe);
    }
  }, [userId, rememberMe]);

  useEffect(() => {
    if (sessionExpired) logout({ returnTo: window.location.origin });
  }, [sessionExpired, logout]);

  if (redirectToAuth || isLoading || sessionExpired) return loader;
  if (error) return renderError(error, authErrorCode ?? undefined);

  return element;
}

AuthorizationBoundary.defaultProps = {
  loader: null,
  renderError: () => <span>Auth Error</span>
};
