import { OrganizationItemDto } from '@api/Api';
import { User } from '@context/AuthContext/types';
import { Mixpanel } from 'mixpanel-browser';

/* eslint-disable max-classes-per-file */
export enum MixpanelEventType {
  MANAGE_TASKS = 'manage_tasks',
  MANAGE_CAMPAIGNS = 'manage_campaigns',
  MANAGE_WORKSPACES = 'manage_workspaces',
  CHANGE_PLAN = 'change_plan',
  UPLOAD_ASSETS = 'upload_assets',
  MANAGE_ASSETS = 'manage_assets',
  SIGN_UP_FOR_TRIAL = 'sign_up_for_trial',
  CONFIRM_EMAIL = 'confirm_email',
  SIGN_IN = 'sign_in',
  DASHBOARD_USAGE = 'dashboard_usage',
  ACCOUNT = 'account'
}

export type PlanNames = OrganizationItemDto['planType'];
export type PlanPeriods = OrganizationItemDto['planPeriod'];

interface SubscriptionPlanBase {
  readonly planType: PlanNames;
}

const mixpanelEnabled = process.env.NODE_ENV === 'production';

export class MixpanelService {
  private static currentUser:
    | User<'internal' | 'external-reviewer'>
    | undefined = undefined;

  public static timeEvent(
    mixpanel: Mixpanel,
    userId: string,
    eventType: MixpanelEventType.DASHBOARD_USAGE
  ) {
    if (!mixpanelEnabled) {
      console.log('MIXPANEL TIME EVENT', {
        userId,
        eventType
      });
      return;
    }
    mixpanel.identify(userId);
    mixpanel.time_event(eventType);
  }

  public static track(
    mixpanel: Mixpanel,
    userId: string,
    eventType: MixpanelEventType,
    payload:
      | TrackManageTasksInput
      | TrackManageCampaignsInput
      | TrackManageWorkspacesInput
      | TrackChangePlanInput
      | TrackUploadAssetsInput
      | TrackManageAssetsInput
      | TrackSignUpForTrialInput
      | TrackSignInInput
      | TrackAccountInput = {} as any
  ) {
    if (this.currentUser) {
      if (!('userType' in payload) || !payload.userType) {
        (payload as any).userType = this.currentUser.type;
      }
      if (!('email' in payload) || !payload.email) {
        (payload as any).email = this.currentUser.email;
      }
      if (!('emailVerified' in payload) || !payload.emailVerified) {
        (payload as any).emailVerified = this.currentUser.emailVerified;
      }
      if (!('firstName' in payload) || !payload.firstName) {
        (payload as any).firstName = this.currentUser.firstName;
      }
      if (!('lastName' in payload) || !payload.lastName) {
        (payload as any).lastName = this.currentUser.lastName;
      }
    }

    if (!mixpanelEnabled) {
      console.log(
        'MIXPANEL EVENT',
        {
          userId,
          eventType
        },
        payload
      );
      return;
    }

    mixpanel.identify(userId);

    if (
      eventType === MixpanelEventType.SIGN_UP_FOR_TRIAL ||
      eventType === MixpanelEventType.SIGN_IN
    ) {
      const { email, name, emailVerified }: any = payload;
      mixpanel.people.set({ $email: email, $name: name, emailVerified });
    }

    if (eventType === MixpanelEventType.CONFIRM_EMAIL) {
      mixpanel.people.set({ emailVerified: true });
    }

    if (
      payload &&
      eventType === MixpanelEventType.CHANGE_PLAN &&
      this.isChangePlanPayload(payload)
    ) {
      this.trackChangePlan(mixpanel, payload);
      return;
    }

    mixpanel.track(eventType, payload);
  }

  public static identify(
    mixpanel: Mixpanel,
    user: User<'internal' | 'external-reviewer'>
  ) {
    this.currentUser = user;
    if (!mixpanelEnabled) return;
    mixpanel.identify(user.id);
    mixpanel.people.set({
      $email: user.email,
      $name: `${user.firstName || ''} ${user.lastName || ''}`.trim(),
      userType: user.type,
      emailVerified: user.emailVerified
    });
  }

  public static reset(mixpanel: Mixpanel) {
    this.currentUser = undefined;
    if (!mixpanelEnabled) return;
    mixpanel.reset();
  }

  private static trackChangePlan(
    mixpanel: Mixpanel,
    payload: TrackChangePlanInput
  ) {
    const {
      newPlanType,
      oldPlanType,
      period,
      oldPeriod,
      updateDate,
      usersCount
    } = payload;
    const newPlanRating = planRating[newPlanType];
    const oldPlanRating = planRating[oldPlanType];
    const newPeriodRating = period ? periodRating[period] : 0;
    const oldPeriodRating = oldPeriod ? periodRating[oldPeriod] : 0;
    const isUpgrade =
      newPlanRating > oldPlanRating ||
      (newPlanRating === oldPlanRating && newPeriodRating > oldPeriodRating);
    const isDowngrade =
      newPlanRating < oldPlanRating ||
      (newPlanRating === oldPlanRating && newPeriodRating < oldPeriodRating);
    if (isUpgrade) {
      mixpanel.track(`upgrade_to_${newPlanType}_plan`, {
        period,
        oldPeriod,
        updateDate,
        usersCount
      });
    }
    if (isDowngrade) {
      mixpanel.track(`downgrade_to_${newPlanType}_plan`, {
        period,
        oldPeriod,
        updateDate,
        usersCount
      });
    }
  }

  private static isChangePlanPayload(i: any): i is TrackChangePlanInput {
    const keys: Array<keyof TrackChangePlanInput> = [
      'newPlanType',
      'oldPlanType',
      'period',
      'oldPeriod',
      'updateDate',
      'usersCount'
    ];
    return keys.every((x) => x in i);
  }
}

type PlanRating = { [i in PlanNames]: number };

export const planRating: PlanRating = {
  free: 1,
  starter_20240711: 2,
  starter: 3,
  team_20240711: 4,
  team: 5,
  enterprise: 6
};

type PeriodRating = { [i in PlanPeriods]: number };
export const periodRating: PeriodRating = {
  monthly: 1,
  annually: 2
};

export interface TrackManageTasksInput extends SubscriptionPlanBase {
  readonly createTask: boolean;
  readonly taskName: string;
  readonly campaignName: string;
  readonly asset: string | null; // '${asset.name}.${asset.extension}'
  readonly description: string;
  readonly deleteTask: boolean;
}
export interface TrackManageCampaignsInput {
  readonly newCampaign: boolean;
  readonly campaignName: string;
  readonly sharedCampaign: boolean;
  readonly privateCampaign: boolean;
  readonly deleteCampaign: boolean;
  readonly addTaskGroup: boolean;
  readonly addContributor: boolean;
}

export interface TrackManageWorkspacesInput extends SubscriptionPlanBase {
  readonly oldWorkspaceName: string | null;
  readonly newWorkspaceName: string;
  readonly uploadProfilePhoto: boolean;
  readonly updateWorkspace: boolean;
  readonly accessLink: boolean;
  readonly deleteWorkspace: boolean;
  readonly addMember: boolean;
}

export interface TrackChangePlanInput {
  readonly oldPlanType: PlanNames;
  readonly newPlanType: PlanNames;
  readonly updateDate: string;
  readonly period: PlanPeriods | null;
  readonly oldPeriod: PlanPeriods | null;
  readonly usersCount: number;
}

export interface TrackUploadAssetsInput {
  readonly mediaType: string;
  readonly assignCampaign: string;
  readonly numberOfAssets: number;
}

export interface TrackManageAssetsInput {
  readonly newAsset: boolean;
  readonly addToFavorite: boolean;
  readonly deleteAsset: boolean;
  readonly approvalRouting: boolean;
  readonly externalLinkShare: boolean;
  readonly newCommentAsset: boolean;
  readonly annotationLeft: boolean;
  readonly taskFromComment: boolean;
}

export interface TrackSignUpForTrialInput extends SubscriptionPlanBase {
  readonly email: string;
  readonly name: string;
  readonly registrationDate: string;
  readonly registrationMethod: 'google' | 'website';
  readonly emailVerified: boolean;
  readonly trialEndDate: string | null;
  readonly trialDays: number;
}

export interface TrackSignInInput extends SubscriptionPlanBase {
  readonly email: string;
  readonly name: string;
}

export interface TrackAccountInput extends SubscriptionPlanBase {
  readonly accountDeletion: boolean;
}
