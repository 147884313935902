import {
  IWorkspaceCountersAction,
  IWorkspaceCountersState,
  WorkspaceCountersTypes
} from '@redux/types/workspaceCountersType';

const INITIAL_STATE: IWorkspaceCountersState = {
  allCampaignsCount: 0,
  myCampaignsCount: 0,
  favoriteCampaignsCount: 0,
  deletedCampaignsCount: 0,
  allAssetsCount: 0,
  myAssetsCount: 0,
  favoriteAssetsCount: 0,
  deletedAssetsCount: 0,
  recentlyOpenedAssetsCount: 0,
  allTasks: {
    count: 0,
    campaignGroupsCount: 0,
    taskBoardGroupsCount: 0
  },
  myTasks: {
    count: 0,
    campaignGroupsCount: 0,
    taskBoardGroupsCount: 0
  },
  assignedToMeTasks: {
    count: 0,
    campaignGroupsCount: 0,
    taskBoardGroupsCount: 0
  },
  deletedTasks: {
    count: 0,
    campaignGroupsCount: 0,
    taskBoardGroupsCount: 0
  }
};

const workspaceCountersReducer = (
  state = INITIAL_STATE,
  action: IWorkspaceCountersAction
): IWorkspaceCountersState => {
  switch (action.type) {
    case WorkspaceCountersTypes.SET_WORKSPACE_COUNTERS: {
      return action.payload;
    }
    case WorkspaceCountersTypes.UPDATE_WORKSPACE_COUNTERS: {
      const { entity, count, isTaskCounter } = action.payload;
      if (isTaskCounter) {
        return {
          ...state,
          [entity]: { count }
        };
      }
      return {
        ...state,
        [entity]: count
      };
    }
    case WorkspaceCountersTypes.UPDATE_ASSETS_WORKSPACE_COUNTERS: {
      const { event, asset } = action.payload;
      let newState = state;

      if (event === 'delete') {
        newState = {
          ...newState,
          allAssetsCount: Math.max(0, state.allAssetsCount - 1),
          deletedAssetsCount: state.deletedAssetsCount + 1
        };
        if (asset?.isFavorite)
          newState = {
            ...newState,
            favoriteAssetsCount: Math.max(0, state.favoriteAssetsCount - 1)
          };
        if (asset?.owner?.user.me)
          newState = {
            ...newState,
            myAssetsCount: Math.max(0, state.myAssetsCount - 1)
          };
      }
      if (event === 'deletePermanent') {
        newState = {
          ...newState,
          deletedAssetsCount: Math.max(0, state.deletedAssetsCount - 1)
        };
      }
      if (event === 'restore') {
        newState = {
          ...newState,
          allAssetsCount: state.allAssetsCount + 1,
          deletedAssetsCount: Math.max(0, state.deletedAssetsCount - 1)
        };
        if (asset?.isFavorite)
          newState = {
            ...newState,
            favoriteAssetsCount: state.favoriteAssetsCount + 1
          };
        if (asset?.owner?.user.me)
          newState = {
            ...newState,
            myAssetsCount: state.myAssetsCount + 1
          };
      }
      if (event === 'setFavorite') {
        newState = {
          ...newState,
          favoriteAssetsCount: state.favoriteAssetsCount + 1
        };
      }
      if (event === 'unsetFavorite') {
        newState = {
          ...newState,
          favoriteAssetsCount: Math.max(0, state.favoriteAssetsCount - 1)
        };
      }
      if (event === 'create') {
        newState = {
          ...newState,
          allAssetsCount: state.allAssetsCount + 1
        };
        if (asset?.owner?.user.me)
          newState = {
            ...newState,
            myAssetsCount: state.myAssetsCount + 1
          };
        if (asset?.isFavorite)
          newState = {
            ...newState,
            favoriteAssetsCount: state.favoriteAssetsCount + 1
          };
      }
      if (event === 'addToStack') {
        newState = {
          ...newState,
          allAssetsCount: Math.max(0, state.allAssetsCount - 1)
        };
        if (asset?.isFavorite)
          newState = {
            ...newState,
            favoriteAssetsCount: state.favoriteAssetsCount - 1
          };
        if (asset?.owner?.user.me)
          newState = {
            ...newState,
            myAssetsCount: Math.max(0, state.myAssetsCount - 1)
          };
      }
      if (event === 'revertFromStack') {
        newState = {
          ...newState,
          allAssetsCount: state.allAssetsCount + 1
        };
        if (asset?.isFavorite)
          newState = {
            ...newState,
            favoriteAssetsCount: state.favoriteAssetsCount + 1
          };
        if (asset?.owner?.user.me)
          newState = {
            ...newState,
            myAssetsCount: state.myAssetsCount + 1
          };
      }
      return newState;
    }
    case WorkspaceCountersTypes.UPDATE_CAMPAIGNS_WORKSPACE_COUNTERS: {
      const { event, campaign } = action.payload;
      let newState = state;
      if (event === 'delete') {
        newState = {
          ...newState,
          allCampaignsCount: Math.max(0, state.allCampaignsCount - 1),
          deletedCampaignsCount: state.deletedCampaignsCount + 1
        };
        if (campaign?.isFavorite)
          newState = {
            ...newState,
            favoriteCampaignsCount: Math.max(
              0,
              state.favoriteCampaignsCount - 1
            )
          };
        if (campaign?.owner?.user.me)
          newState = {
            ...newState,
            myCampaignsCount: Math.max(0, state.myCampaignsCount - 1)
          };
      }
      if (event === 'deletePermanent') {
        newState = {
          ...newState,
          deletedCampaignsCount: Math.max(0, state.deletedCampaignsCount - 1)
        };
      }
      if (event === 'restore') {
        newState = {
          ...newState,
          allCampaignsCount: state.allCampaignsCount + 1,
          deletedCampaignsCount: Math.max(0, state.deletedCampaignsCount - 1)
        };
        if (campaign?.isFavorite)
          newState = {
            ...newState,
            favoriteCampaignsCount: state.favoriteCampaignsCount + 1
          };
        if (campaign?.owner?.user.me)
          newState = {
            ...newState,
            myCampaignsCount: state.myCampaignsCount + 1
          };
      }
      if (event === 'setFavorite') {
        newState = {
          ...newState,
          favoriteCampaignsCount: state.favoriteCampaignsCount + 1
        };
      }
      if (event === 'unsetFavorite') {
        newState = {
          ...newState,
          favoriteCampaignsCount: Math.max(0, state.favoriteCampaignsCount - 1)
        };
      }
      if (event === 'create') {
        newState = {
          ...newState,
          allCampaignsCount: state.allCampaignsCount + 1
        };
        if (campaign?.owner?.user.me)
          newState = {
            ...newState,
            myCampaignsCount: state.myCampaignsCount + 1
          };
        if (campaign?.isFavorite)
          newState = {
            ...newState,
            favoriteCampaignsCount: state.favoriteCampaignsCount + 1
          };
      }
      if (event === 'lostAccess') {
        newState = {
          ...newState,
          allCampaignsCount: Math.max(0, state.allCampaignsCount - 1)
        };
        if (campaign?.isFavorite)
          newState = {
            ...newState,
            favoriteCampaignsCount: Math.max(
              0,
              state.favoriteCampaignsCount - 1
            )
          };
      }
      return newState;
    }
    default: {
      return state;
    }
  }
};

export default workspaceCountersReducer;
