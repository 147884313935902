import {
  CreateCampaignSuccessDto,
  GetAssetSuccessDto,
  MemberDto,
  TaskItemAssetDto,
  TaskItemBoardDto,
  TaskItemCampaignDto,
  WorkspaceCountersDto
} from '@api/Api';
import { noAssignedBoardName, archivedBoardName } from '@config/tasks';
import { nonNull } from '@helpers/non-null';
import {
  deleteTask,
  fetchAssigneesForFilter,
  fetchCampaignGroups,
  fetchCampaignsForFilter,
  fetchCampaignTaskBoards,
  fetchTaskListInCampaign,
  fetchTasksCountInCampaign,
  fetchTaskBoardGroups,
  fetchTaskById,
  fetchTaskList,
  fetchTasksCount,
  restoreTask,
  updateTask,
  moveTaskBoard,
  renameTaskBoard,
  createTask,
  createTaskBoard,
  deleteTaskBoard,
  fetchTaskCustomFields,
  createTaskCustomField,
  renameTaskCustomField,
  deleteTaskCustomField,
  fetchTaskAssignees,
  fetchTaskDescriptionMentions,
  fetchTaskCommentMentions,
  fetchTaskEvents,
  createTaskComment,
  fetchTaskCampaigns,
  fetchTaskBoards,
  fetchTaskAssets,
  deleteTaskAttachment,
  fetchTaskAttachment,
  fetchDashboardTaskList,
  fetchDashboardTasksCount,
  fetchTaskListInAssetVerion,
  fetchTasksCountInAssetVerion,
  fetchAssetAssignees,
  UpdateTaskPayload
} from '@redux/actions/tasks';
import {
  groupInitialState,
  initialState,
  itemInitialState
} from '@redux/initial-states/tasks';
import { getPersistedState } from '@redux/persistors/tasks';
import {
  getOrSetWsState,
  newRequest,
  getOrSetCampaignState,
  getOrSetTaskState,
  handleDeletedTask,
  refreshCounts,
  handleRestoredTask,
  getCurrentTaskLocation,
  removeTaskFromLists,
  replaceBoardNames,
  handleUpdatedTask,
  handleTaskLocationChange,
  addTaskToLists,
  setTaskEntitiesFromList,
  withUndo,
  moveTaskInBoard,
  cancelUndo,
  undo,
  applyTaskUpdate,
  getNewTaskLocation,
  getOrSetAssetVersionState,
  getOrSetAssetState,
  formatTaskEvents,
  formatTaskEventsList,
  applyTaskEventState
} from '@redux/reducers/helpers/tasks';
import { AssetsActionTypes } from '@redux/types/assetsType';
import { CampaignActionTypes } from '@redux/types/campaignType';
import { MediaViewerActionTypes } from '@redux/types/mediaViewerType';
import {
  TasksInDashboardListType,
  TaskGroupItemState,
  TaskListsSharedState,
  TaskListType,
  TasksGroupBy,
  TasksInAssetListType,
  TasksOrderBy
} from '@redux/types/tasks';
import { WorkspaceCountersTypes } from '@redux/types/workspaceCountersType';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import produce, { original } from 'immer';
import { nanoid } from 'nanoid';

const tasksSlice = createSlice({
  name: 'tasks',
  initialState: initialState(getPersistedState()),
  reducers: {
    setListParams(
      state,
      action: PayloadAction<{
        workspaceId: string;
        searchQuery?: string | null;
        campaigns?: { id: string; name: string }[];
        statuses?: TaskListsSharedState['statuses'];
        dueDate?: [string, string] | null;
        assignees?: MemberDto[];
        orderBy?: TasksOrderBy;
      }>
    ) {
      const {
        workspaceId,
        searchQuery,
        campaigns,
        statuses,
        dueDate,
        assignees,
        orderBy
      } = action.payload;
      const wsState = getOrSetWsState(state, workspaceId);
      const params = wsState.listsShared;
      let debounce = 0;
      if (searchQuery !== undefined) {
        params.searchQuery = searchQuery;
        if (searchQuery) debounce = 300;
      }
      if (campaigns !== undefined) params.selectedCampaigns = campaigns;
      if (statuses !== undefined) params.statuses = statuses;
      if (dueDate !== undefined) params.dueDate = dueDate;
      if (assignees !== undefined) params.assignees = assignees;
      if (orderBy !== undefined) params.orderBy = orderBy;
      Object.values(wsState.lists).forEach((listState) => {
        newRequest(listState.fetch, 'reload', debounce);
        newRequest(listState.countFetch, 'refresh', debounce);
        Object.values(listState.groups).forEach((groups) => {
          newRequest(groups.fetch, 'reload', debounce);
        });
      });
    },
    setGroupBy(
      state,
      action: PayloadAction<{ value: TasksGroupBy | null; workspaceId: string }>
    ) {
      const wsState = getOrSetWsState(state, action.payload.workspaceId);
      wsState.listsShared.groupBy = action.payload.value;
    },
    loadMoreTasks(
      state,
      action: PayloadAction<{
        workspaceId: string;
        list: TaskListType;
        group?: {
          id: string;
          by: TasksGroupBy;
        };
      }>
    ) {
      const { workspaceId, list, group } = action.payload;
      const wsState = getOrSetWsState(state, workspaceId);
      const listOrGroup = group
        ? wsState.lists[list].groups[group.by].items[group.id]
        : wsState.lists[list];
      if (listOrGroup) {
        newRequest(listOrGroup.fetch, 'more');
      }
    },
    setScrollTop(
      state,
      action: PayloadAction<{
        value: number;
        workspaceId: string;
        list: TaskListType;
      }>
    ) {
      const wsState = getOrSetWsState(state, action.payload.workspaceId);
      const list = wsState.lists[action.payload.list];
      list.scrollTop = action.payload.value;
    },
    setGroupOpened(
      state,
      action: PayloadAction<{
        value: boolean;
        workspaceId: string;
        list: TaskListType;
        groupBy: TasksGroupBy;
        groupId: string;
      }>
    ) {
      const wsState = getOrSetWsState(state, action.payload.workspaceId);
      const group =
        wsState.lists[action.payload.list].groups[action.payload.groupBy];
      if (
        action.payload.value &&
        !group.openedIds.includes(action.payload.groupId)
      ) {
        group.openedIds.push(action.payload.groupId);
      }
      if (!action.payload.value) {
        group.openedIds = group.openedIds.filter(
          (id) => id !== action.payload.groupId
        );
      }
    },
    setListNeedRefresh(
      state,
      action: PayloadAction<{
        workspaceId: string;
        list: TaskListType;
      }>
    ) {
      const wsState = getOrSetWsState(state, action.payload.workspaceId);
      newRequest(wsState.listsShared.campaigns.fetch, 'refresh');
      newRequest(wsState.listsShared.potentialAssignees.fetch, 'refresh');
      const listState = wsState.lists[action.payload.list];
      newRequest(listState.fetch, 'refresh');
      newRequest(listState.countFetch, 'refresh');
      Object.values(listState.groups).forEach((groups) => {
        newRequest(groups.fetch, 'refresh');
        Object.values(groups.items).forEach((group) => {
          if (!group) return;
          newRequest(group.fetch, 'refresh');
          newRequest(group.countFetch, 'refresh');
        });
      });
    },
    setListAssigneesSearch(
      state,
      action: PayloadAction<{
        workspaceId: string;
        value: string;
      }>
    ) {
      const { workspaceId, value } = action.payload;
      const wsState = getOrSetWsState(state, workspaceId);
      const assignees = wsState.listsShared.potentialAssignees;
      assignees.searchQuery = value;
      newRequest(assignees.fetch, 'reload', value ? 300 : 0);
    },
    setListParamsInCampaign(
      state,
      action: PayloadAction<{
        campaignId: string;
        searchQuery?: string | null;
        orderBy?: TasksOrderBy;
      }>
    ) {
      const { campaignId, searchQuery, orderBy } = action.payload;
      const campaignState = getOrSetCampaignState(state, campaignId);
      let debounce = 0;
      if (searchQuery !== undefined) {
        campaignState.searchQuery = searchQuery;
        if (searchQuery) debounce = 300;
      }
      if (orderBy !== undefined) campaignState.orderBy = orderBy;
      newRequest(campaignState.boards.fetch, 'reload', debounce);
    },
    loadMoreCampaignTasks(
      state,
      action: PayloadAction<{
        campaignId: string;
        boardId: string;
      }>
    ) {
      const { campaignId, boardId } = action.payload;
      const campaignState = getOrSetCampaignState(state, campaignId);
      const board = campaignState.boards.items[boardId];
      if (board) newRequest(board.fetch, 'more');
    },
    setScrollTopInCampaign(
      state,
      action: PayloadAction<{
        value: number;
        campaignId: string;
      }>
    ) {
      const { campaignId, value } = action.payload;
      const campaignState = getOrSetCampaignState(state, campaignId);
      campaignState.scrollTop = value;
    },
    setTaskBoardOpenedInCampaign(
      state,
      action: PayloadAction<{
        value: boolean;
        campaignId: string;
        boardId: string;
      }>
    ) {
      const { campaignId, boardId, value } = action.payload;
      const campaignState = getOrSetCampaignState(state, campaignId);
      const { boards } = campaignState;
      if (value && !boards.openedIds.includes(boardId)) {
        boards.openedIds.push(boardId);
      }
      if (!action.payload.value) {
        boards.openedIds = boards.openedIds.filter((id) => id !== boardId);
      }
    },
    setListsNeedRefreshInCampaign(
      state,
      action: PayloadAction<{ campaignId: string }>
    ) {
      const { campaignId } = action.payload;
      const campaignState = getOrSetCampaignState(state, campaignId);
      newRequest(campaignState.boards.fetch, 'refresh');
      Object.values(campaignState.boards.items).forEach((board) => {
        if (!board) return;
        newRequest(board.fetch, 'refresh');
        newRequest(board.countFetch, 'refresh');
      });
      newRequest(campaignState.customFields.fetch, 'refresh');
    },
    openNewTaskModal(
      state,
      action: PayloadAction<{
        organizationId: string;
        workspaceId: string;
        campaign?: TaskItemCampaignDto | null;
        board?: TaskItemBoardDto | null;
        asset?: TaskItemAssetDto | null;
        descriptionPlaceholder?: string | null;
        taskPosition?: 'start-of-list' | 'end-of-list';
        autoAssignMe?: boolean;
      }>
    ) {
      const {
        organizationId,
        workspaceId,
        campaign,
        board,
        asset,
        descriptionPlaceholder,
        autoAssignMe
      } = action.payload;
      if (state.modal) {
        if (state.modal.type === 'new') {
          delete state.items[state.modal.taskId];
        }
      }
      const key = nanoid();
      state.modal = {
        type: 'new',
        open: true,
        taskId: key,
        key,
        descriptionPlaceholder: descriptionPlaceholder ?? null,
        taskPosition: action.payload.taskPosition ?? null,
        autoAssignMe
      };
      const taskState = itemInitialState();
      state.items[key] = taskState;
      taskState.fetch.initiallyLoaded = true;
      taskState.fetch.newRequest = null;
      taskState.entity = {
        id: key,
        organizationId,
        workspaceId,
        campaign: campaign ?? { id: '', name: '' },
        board: board ?? { id: 'no_assigned', name: noAssignedBoardName },
        asset: asset ?? null,
        name: '',
        description: '',
        descriptionMentions: [],
        status: 'todo',
        assignees: [],
        watchers: [],
        customFields: [],
        attachments: [],
        archived: false,
        commentsCount: 0,
        createdAt: new Date().toISOString(),
        lastEventTime: new Date().toISOString(),
        demo: false,
        permissions: {
          update: true,
          delete: false,
          comment: false
        }
      };
      const wsState = getOrSetWsState(state, workspaceId);
      if (wsState.listsShared.campaigns.fetch.initiallyLoaded) {
        taskState.campaigns.list = wsState.listsShared.campaigns.list;
        taskState.campaigns.fetch.initiallyLoaded = true;
        newRequest(taskState.campaigns.fetch, 'refresh');
      }
      if (wsState.listsShared.potentialAssignees.fetch.initiallyLoaded) {
        taskState.potentialAssignees.list =
          wsState.listsShared.potentialAssignees.list;
        taskState.potentialAssignees.fetch.initiallyLoaded = true;
        newRequest(taskState.potentialAssignees.fetch, 'refresh');
      }
    },
    openTaskModal(
      state,
      action: PayloadAction<{
        taskId: string;
        descriptionPlaceholder?: string | null;
      }>
    ) {
      const { taskId, descriptionPlaceholder } = action.payload;
      if (state.modal) {
        if (state.modal.type === 'new') {
          delete state.items[state.modal.taskId];
        }
      }
      state.modal = {
        type: 'edit',
        open: true,
        key: nanoid(),
        taskId,
        descriptionPlaceholder: descriptionPlaceholder ?? null,
        taskPosition: null
      };
      const taskState = getOrSetTaskState(state, action.payload.taskId);
      newRequest(taskState.fetch, 'refresh');
      if (taskState.entity && !taskState.boards.fetch.initiallyLoaded) {
        const campaignId = taskState.entity.campaign.id;
        const campaignState = getOrSetCampaignState(state, campaignId);
        if (campaignState.boards.fetch.initiallyLoaded) {
          taskState.boards.list = campaignState.boards.ids
            .filter((id) => id !== 'archived')
            .map((id) => ({
              id,
              name: nonNull(campaignState.boards.items[id]).name
            }));
          taskState.boards.fetch.initiallyLoaded = true;
        }
      }
      newRequest(taskState.boards.fetch, 'reload');
      newRequest(taskState.potentialAssignees.fetch, 'reload');
      newRequest(taskState.assets.fetch, 'reload');
      newRequest(taskState.events.fetch, 'reload');
      taskState.assets.hasMore = true;
    },
    closeTaskModal(state) {
      if (state.modal) {
        state.modal.open = false;
      }
    },
    disposeTaskModal(state) {
      if (state.modal) {
        if (state.modal.type === 'new') {
          delete state.items[state.modal.taskId];
        }
        state.modal = null;
      }
    },
    updateNewTask(
      state,
      action: PayloadAction<
        Omit<UpdateTaskPayload, 'mixpanel' | 'userId' | 'planType'> & {
          campaign?: TaskItemCampaignDto;
        }
      >
    ) {
      const taskState = getOrSetTaskState(state, action.payload.id);
      const task = nonNull(taskState.entity);
      const prevCampaignId = task.campaign.id;
      const prevAssetId = task.asset?.id;
      applyTaskUpdate(task, action.payload);
      if (prevCampaignId !== task.campaign.id) {
        taskState.assets.list = [];
        taskState.assets.hasMore = false;
        newRequest(taskState.assets.fetch, 'initial');
        taskState.boards.list = [];
        newRequest(taskState.assets.fetch, 'initial');
      }
      if (
        prevCampaignId !== task.campaign.id ||
        prevAssetId !== task.asset?.id
      ) {
        taskState.potentialAssignees.list = [];
        newRequest(taskState.potentialAssignees.fetch, 'initial');
        taskState.descriptionMentions.list = [];
        newRequest(taskState.descriptionMentions.fetch, 'initial');
        taskState.commentMentions.list = [];
        newRequest(taskState.commentMentions.fetch, 'initial');
      }
    },
    loadMoreTaskEvents(state, action: PayloadAction<{ taskId: string }>) {
      const taskState = getOrSetTaskState(state, action.payload.taskId);
      newRequest(taskState.events.fetch, 'more');
    },
    toggleTaskEventsGroup(
      state,
      action: PayloadAction<{ taskId: string; eventId: string }>
    ) {
      const { taskId, eventId } = action.payload;
      const taskState = getOrSetTaskState(state, taskId);
      const item = taskState.events.list.find((it) => it.id === eventId);
      if (!item) return;
      item.isExpanded = !item.isExpanded;
    },
    setTaskAssigneesSearch(
      state,
      action: PayloadAction<{
        taskId: string;
        value: string;
      }>
    ) {
      const { taskId, value } = action.payload;
      const taskState = getOrSetTaskState(state, taskId);
      taskState.potentialAssignees.searchQuery = value;
      newRequest(taskState.potentialAssignees.fetch, 'reload', value ? 300 : 0);
    },
    setTaskDescriptionMentionsSearch(
      state,
      action: PayloadAction<{
        taskId: string;
        value: string;
      }>
    ) {
      const { taskId, value } = action.payload;
      const taskState = getOrSetTaskState(state, taskId);
      taskState.descriptionMentions.searchQuery = value;
      newRequest(
        taskState.descriptionMentions.fetch,
        'reload',
        value ? 300 : 0
      );
    },
    setTaskCommentMentionsSearch(
      state,
      action: PayloadAction<{
        taskId: string;
        value: string;
      }>
    ) {
      const { taskId, value } = action.payload;
      const taskState = getOrSetTaskState(state, taskId);
      taskState.commentMentions.searchQuery = value;
      newRequest(taskState.commentMentions.fetch, 'reload', value ? 300 : 0);
    },
    loadMoreTaskAssets(state, action: PayloadAction<{ taskId: string }>) {
      const taskState = getOrSetTaskState(state, action.payload.taskId);
      newRequest(taskState.assets.fetch, 'more');
    },
    setTaskAssetsSearch(
      state,
      action: PayloadAction<{
        taskId: string;
        value: string;
      }>
    ) {
      const { taskId, value } = action.payload;
      const taskState = getOrSetTaskState(state, taskId);
      taskState.assets.searchQuery = value;
      newRequest(taskState.assets.fetch, 'reload', value ? 300 : 0);
    },
    handleTaskAttachmentDelete(
      state,
      action: PayloadAction<{
        taskId: string;
        id: string;
      }>
    ) {
      const taskState = state.items[action.payload.taskId];
      if (!taskState?.entity) return;
      const { entity: task } = taskState;
      if (task.attachments.some((x) => x.id === action.payload.id)) {
        task.attachments = task.attachments.filter(
          (x) => x.id !== action.payload.id
        );
        newRequest(taskState.events.fetch, 'refresh');
      }
    },
    handleTaskDelete(
      state,
      action: PayloadAction<{
        taskId: string;
        permanent: boolean;
      }>
    ) {
      const { taskId, permanent } = action.payload;
      const toRefresh = handleDeletedTask(taskId, permanent, state);
      refreshCounts(toRefresh, state);
      const taskState = state.items[taskId];
      if (taskState && !permanent) {
        newRequest(taskState.events.fetch, 'refresh');
      }
    },
    handleTaskRestore(state, action: PayloadAction<{ taskId: string }>) {
      const { taskId } = action.payload;
      const toRefresh = handleRestoredTask(taskId, state);
      refreshCounts(toRefresh, state);
      const taskState = state.items[taskId];
      if (taskState) {
        newRequest(taskState.events.fetch, 'refresh');
      }
    },
    handleTaskCommentCreate(state, action: PayloadAction<{ taskId: string }>) {
      const taskState = state.items[action.payload.taskId];
      if (!taskState?.entity) return;
      newRequest(taskState.events.fetch, 'refresh');
    },
    setDashboardListNeedRefresh(
      state,
      action: PayloadAction<{ workspaceId: string }>
    ) {
      const wsState = getOrSetWsState(state, action.payload.workspaceId);
      newRequest(wsState.dashboard.fetch, 'refresh');
      newRequest(wsState.dashboard.countFetch, 'refresh');
      newRequest(wsState.listsShared.campaigns.fetch, 'refresh');
    },
    loadMoreDashboardTasks(
      state,
      action: PayloadAction<{
        workspaceId: string;
      }>
    ) {
      const { workspaceId } = action.payload;
      const wsState = getOrSetWsState(state, workspaceId);
      newRequest(wsState.dashboard.fetch, 'more');
    },
    setDashboardTaskListParams(
      state,
      action: PayloadAction<{
        workspaceId: string;
        listType: TasksInDashboardListType;
      }>
    ) {
      const { workspaceId, listType } = action.payload;
      const { dashboard } = getOrSetWsState(state, workspaceId);
      dashboard.listType = listType;
      newRequest(dashboard.countFetch, 'refresh');
      newRequest(dashboard.fetch, 'reload');
    },
    setAssetVersionListParams(
      state,
      action: PayloadAction<{
        assetVersionId: string;
        searchQuery?: string | null;
        listType?: TasksInAssetListType;
        hideResolvedTasks?: boolean;
        orderBy?: TasksOrderBy;
      }>
    ) {
      const {
        assetVersionId,
        searchQuery,
        listType,
        hideResolvedTasks,
        orderBy
      } = action.payload;
      const avState = getOrSetAssetVersionState(state, assetVersionId);
      let debounce = 0;
      if (searchQuery !== undefined) {
        avState.searchQuery = searchQuery;
        if (searchQuery) debounce = 300;
      }
      if (listType !== undefined) avState.listType = listType;
      if (hideResolvedTasks !== undefined)
        avState.hideResolvedTasks = hideResolvedTasks;
      if (orderBy !== undefined) avState.orderBy = orderBy;
      newRequest(avState.countFetch, 'refresh', debounce);
      newRequest(avState.fetch, 'reload', debounce);
    },
    loadMoreAssetVersionTasks(
      state,
      action: PayloadAction<{
        assetVersionId: string;
      }>
    ) {
      const { assetVersionId } = action.payload;
      const avState = getOrSetAssetVersionState(state, assetVersionId);
      newRequest(avState.fetch, 'more');
    },
    setAssetVersionListNeedRefresh(
      state,
      action: PayloadAction<{ assetVersionId: string }>
    ) {
      const { assetVersionId } = action.payload;
      const avState = getOrSetAssetVersionState(state, assetVersionId);
      newRequest(avState.fetch, 'refresh');
      newRequest(avState.countFetch, 'refresh');
    },
    setAssetListNeedRefresh(state, action: PayloadAction<{ assetId: string }>) {
      const { assetId } = action.payload;
      const aState = getOrSetAssetState(state, assetId);
      newRequest(aState.potentialAssignees.fetch, 'refresh');
    }
  },
  extraReducers(builder) {
    builder.addCase<
      string,
      PayloadAction<WorkspaceCountersDto & { workspaceId: string }>
    >(WorkspaceCountersTypes.SET_WORKSPACE_COUNTERS, (state, action) => {
      const wsState = getOrSetWsState(state, action.payload.workspaceId);
      if (!wsState.lists.all.countFetch.initiallyLoaded) {
        wsState.lists.all.count = action.payload.allTasks.count;
      }
      if (!wsState.lists.all.groups.board.fetch.initiallyLoaded) {
        wsState.lists.all.groups.board.count =
          action.payload.allTasks.taskBoardGroupsCount;
      }
      if (!wsState.lists.all.groups.campaign.fetch.initiallyLoaded) {
        wsState.lists.all.groups.campaign.count =
          action.payload.allTasks.campaignGroupsCount;
      }
      if (!wsState.lists.my.countFetch.initiallyLoaded) {
        wsState.lists.my.count = action.payload.myTasks.count;
      }
      if (!wsState.lists.my.groups.board.fetch.initiallyLoaded) {
        wsState.lists.my.groups.board.count =
          action.payload.myTasks.taskBoardGroupsCount;
      }
      if (!wsState.lists.my.groups.campaign.fetch.initiallyLoaded) {
        wsState.lists.my.groups.campaign.count =
          action.payload.myTasks.campaignGroupsCount;
      }
      if (!wsState.lists.assigned.countFetch.initiallyLoaded) {
        wsState.lists.assigned.count = action.payload.assignedToMeTasks.count;
      }
      if (!wsState.lists.assigned.groups.board.fetch.initiallyLoaded) {
        wsState.lists.assigned.groups.board.count =
          action.payload.assignedToMeTasks.taskBoardGroupsCount;
      }
      if (!wsState.lists.assigned.groups.campaign.fetch.initiallyLoaded) {
        wsState.lists.assigned.groups.campaign.count =
          action.payload.assignedToMeTasks.campaignGroupsCount;
      }
      if (!wsState.lists.deleted.countFetch.initiallyLoaded) {
        wsState.lists.deleted.count = action.payload.deletedTasks.count;
      }
      if (!wsState.lists.deleted.groups.board.fetch.initiallyLoaded) {
        wsState.lists.deleted.groups.board.count =
          action.payload.deletedTasks.taskBoardGroupsCount;
      }
      if (!wsState.lists.deleted.groups.campaign.fetch.initiallyLoaded) {
        wsState.lists.deleted.groups.campaign.count =
          action.payload.deletedTasks.campaignGroupsCount;
      }
    });
    builder.addCase<string, PayloadAction<GetAssetSuccessDto>>(
      AssetsActionTypes.ADD_NEW_ASSET,
      (state, action) => {
        const task = Object.values(state.items).find(
          (it) => it?.entity?.id === state.modal?.taskId
        );
        if (!task) return;
        if (!task.assets.fetch.initiallyLoaded) return;
        newRequest(task.assets.fetch, 'refresh');
      }
    );
    builder.addCase<string, PayloadAction<CreateCampaignSuccessDto>>(
      CampaignActionTypes.CREATE_CAMPAIGN,
      (state, action) => {
        const wsState = getOrSetWsState(
          state,
          action.payload.campaign.workspaceId
        );
        if (!wsState.listsShared.campaigns.fetch.initiallyLoaded) return;
        newRequest(wsState.listsShared.campaigns.fetch, 'refresh');
      }
    );
    builder.addCase<string, PayloadAction<string>>(
      MediaViewerActionTypes.DELETE_ASSET_INTERNAL_COMMENT,
      (state, action) => {
        const task = Object.values(state.items).find(
          (x) => x?.entity?.assetVersionComment?.id === action.payload
        )?.entity;
        if (task) task.assetVersionComment = null;
      }
    );
    builder.addCase(fetchTaskById.pending, (state, action) => {
      const { arg, requestId } = action.meta;
      const taskState = getOrSetTaskState(state, arg.id);
      taskState.fetch.requestId = requestId;
    });
    builder.addCase(fetchTaskById.rejected, (state, action) => {
      const { arg, requestId } = action.meta;
      const taskState = getOrSetTaskState(state, arg.id);
      if (taskState.fetch.requestId !== requestId) return;
      if (action.error.code === '404') {
        const { entity: task } = taskState;
        if (task) {
          const toRemove = getCurrentTaskLocation(task, state);
          removeTaskFromLists(task.id, toRemove, state);
        }
        taskState.notFound = true;
      }
    });
    builder.addCase(fetchTaskById.fulfilled, (state, action) => {
      const { arg, requestId } = action.meta;
      const taskState = getOrSetTaskState(state, arg.id);
      if (taskState.fetch.requestId !== requestId) return;
      const task = replaceBoardNames(action.payload.task);
      taskState.fetch.newRequest = null;
      taskState.fetch.initiallyLoaded = true;
      const prevTask = taskState.entity;
      taskState.entity = task;
      taskState.notFound = false;
      taskState.permanentlyDeleted = false;
      if (prevTask) {
        handleUpdatedTask(prevTask, task, state);
        const locations = handleTaskLocationChange(prevTask, task, state);
        refreshCounts(locations, state);
      } else {
        const toAdd = getNewTaskLocation(task);
        addTaskToLists(task.id, toAdd, state);
        refreshCounts([toAdd], state);
      }
    });
    builder.addCase(fetchTaskList.pending, (state, action) => {
      const { arg, requestId } = action.meta;
      const wsState = getOrSetWsState(state, arg.workspaceId);
      const list = wsState.lists[arg.list];
      const listOrGroup = arg.group
        ? list.groups[arg.group.by].items[arg.group.id]
        : list;
      if (!listOrGroup) return;
      listOrGroup.fetch.requestId = requestId;
    });
    builder.addCase(fetchTaskList.fulfilled, (state, action) => {
      const { arg, requestId } = action.meta;
      const wsState = getOrSetWsState(state, arg.workspaceId);
      const list = wsState.lists[arg.list];
      const listOrGroup = arg.group
        ? list.groups[arg.group.by].items[arg.group.id]
        : list;
      if (!listOrGroup || listOrGroup.fetch.requestId !== requestId) return;

      listOrGroup.fetch.newRequest = null;
      listOrGroup.fetch.initiallyLoaded = true;
      const taskIds = action.payload.edges.map((x) => x.node.id);
      if (arg.fetchType === 'more') {
        taskIds.forEach((id) => {
          if (!listOrGroup.taskIds.includes(id)) listOrGroup.taskIds.push(id);
        });
      } else {
        listOrGroup.taskIds = taskIds;
      }
      if (arg.fetchType === 'refresh') {
        listOrGroup.hasMore = listOrGroup.count > listOrGroup.taskIds.length;
      } else {
        listOrGroup.hasMore = action.payload.hasNext;
        if (!listOrGroup.hasMore) {
          listOrGroup.count = listOrGroup.taskIds.length;
        }
      }
      listOrGroup.cursor = action.payload.endCursor;
      setTaskEntitiesFromList(action.payload, state);
    });
    builder.addCase(fetchTasksCount.pending, (state, action) => {
      const { arg, requestId } = action.meta;
      const wsState = getOrSetWsState(state, arg.workspaceId);
      const list = wsState.lists[arg.list];
      const listOrGroup = arg.group
        ? list.groups[arg.group.by].items[arg.group.id]
        : list;
      if (!listOrGroup) return;
      listOrGroup.countFetch.requestId = requestId;
    });
    builder.addCase(fetchTasksCount.fulfilled, (state, action) => {
      const { arg, requestId } = action.meta;
      const wsState = getOrSetWsState(state, arg.workspaceId);
      const list = wsState.lists[arg.list];
      const listOrGroup = arg.group
        ? list.groups[arg.group.by].items[arg.group.id]
        : list;
      if (!listOrGroup || listOrGroup.countFetch.requestId !== requestId)
        return;
      listOrGroup.countFetch.newRequest = null;
      listOrGroup.countFetch.initiallyLoaded = true;
      listOrGroup.count = action.payload.count;
      listOrGroup.hasMore = listOrGroup.count > listOrGroup.taskIds.length;
    });
    builder.addCase(fetchCampaignsForFilter.pending, (state, action) => {
      const { arg, requestId } = action.meta;
      const wsState = getOrSetWsState(state, arg.workspaceId);
      wsState.listsShared.campaigns.fetch.requestId = requestId;
    });
    builder.addCase(fetchCampaignsForFilter.fulfilled, (state, action) => {
      const { arg, requestId } = action.meta;
      const wsState = getOrSetWsState(state, arg.workspaceId);
      if (wsState.listsShared.campaigns.fetch.requestId !== requestId) return;
      wsState.listsShared.campaigns.fetch.newRequest = null;
      wsState.listsShared.campaigns.fetch.initiallyLoaded = true;
      wsState.listsShared.campaigns.list = action.payload.list;
    });
    builder.addCase(fetchAssigneesForFilter.pending, (state, action) => {
      const { arg, requestId } = action.meta;
      const wsState = getOrSetWsState(state, arg.workspaceId);
      wsState.listsShared.potentialAssignees.fetch.requestId = requestId;
    });
    builder.addCase(fetchAssigneesForFilter.fulfilled, (state, action) => {
      const { arg, requestId } = action.meta;
      const wsState = getOrSetWsState(state, arg.workspaceId);
      if (wsState.listsShared.potentialAssignees.fetch.requestId !== requestId)
        return;
      wsState.listsShared.potentialAssignees.fetch.newRequest = null;
      wsState.listsShared.potentialAssignees.fetch.initiallyLoaded = true;
      wsState.listsShared.potentialAssignees.list = action.payload.edges.map(
        (x) => x.node
      );
    });
    builder.addCase(fetchCampaignGroups.pending, (state, action) => {
      const { arg, requestId } = action.meta;
      const wsState = getOrSetWsState(state, arg.workspaceId);
      const list = wsState.lists[arg.list];
      const groups = list.groups.campaign;
      groups.fetch.requestId = requestId;
    });
    builder.addCase(fetchCampaignGroups.fulfilled, (state, action) => {
      const { arg, requestId } = action.meta;
      const wsState = getOrSetWsState(state, arg.workspaceId);
      const list = wsState.lists[arg.list];
      const groups = list.groups.campaign;
      if (groups.fetch.requestId !== requestId) return;
      groups.fetch.newRequest = null;
      groups.fetch.initiallyLoaded = true;
      groups.count = action.payload.list.length;
      groups.ids = action.payload.list.map((x) => x.id);
      action.payload.list.forEach((x) => {
        if (!groups.items[x.id]) {
          groups.items[x.id] = groupInitialState();
        }
        const item = groups.items[x.id] as TaskGroupItemState;
        item.name = x.name;
        item.countFetch.initiallyLoaded = true;
        item.countFetch.newRequest = null;
        item.count = x.tasksCount;
        item.overdueTaskIds = x.overdueTaskIds;
        item.dueSoonTaskIds = x.dueSoonTaskIds;
        item.hasMore = x.tasksCount > item.taskIds.length;
        newRequest(item.fetch, arg.fetchType);
      });
    });
    builder.addCase(fetchTaskBoardGroups.pending, (state, action) => {
      const { arg, requestId } = action.meta;
      const wsState = getOrSetWsState(state, arg.workspaceId);
      const list = wsState.lists[arg.list];
      const groups = list.groups.board;
      groups.fetch.requestId = requestId;
    });
    builder.addCase(fetchTaskBoardGroups.fulfilled, (state, action) => {
      const { arg, requestId } = action.meta;
      const wsState = getOrSetWsState(state, arg.workspaceId);
      const list = wsState.lists[arg.list];
      const groups = list.groups.board;
      if (groups.fetch.requestId !== requestId) return;
      groups.fetch.newRequest = null;
      groups.fetch.initiallyLoaded = true;
      groups.count = action.payload.list.length;
      groups.ids = action.payload.list.map((x) => x.ids.join(','));
      action.payload.list.forEach((x) => {
        const groupId = x.ids.join(',');
        if (!groups.items[groupId]) {
          groups.items[groupId] = groupInitialState();
        }
        const item = groups.items[groupId] as TaskGroupItemState;
        item.name =
          groupId === 'no_assigned'
            ? noAssignedBoardName
            : groupId === 'archived'
            ? archivedBoardName
            : x.name;
        item.countFetch.initiallyLoaded = true;
        item.countFetch.newRequest = null;
        item.count = x.tasksCount;
        item.overdueTaskIds = x.overdueTaskIds;
        item.dueSoonTaskIds = x.dueSoonTaskIds;
        item.hasMore = x.tasksCount > item.taskIds.length;
        newRequest(item.fetch, arg.fetchType);
      });
    });
    builder.addCase(updateTask.pending, (state, action) =>
      withUndo(state, action.meta.requestId, (state) => {
        const { arg: update, requestId } = action.meta;
        const taskState = getOrSetTaskState(state, update.id);
        if (!taskState.entity) return;
        const prevTask = taskState.entity;
        const newTask = produce(nonNull(original(prevTask)), (task) => {
          applyTaskUpdate(task, update);
        });
        taskState.entity = newTask;
        const campaignState = getOrSetCampaignState(state, newTask.campaign.id);
        const prevTasksCount = campaignState.count;
        handleUpdatedTask(prevTask, newTask, state);
        const locations = handleTaskLocationChange(prevTask, newTask, state, {
          workspaceId: newTask.workspaceId,
          dashboard: true,
          lists: {
            assigned: {
              flatList: true,
              campaignIds: [newTask.campaign.id],
              boardIds: [newTask.board.id]
            }
          },
          campaigns: [],
          assetVersionIds: []
        });
        state.countsToRefresh[requestId] = locations;
        if (prevTask.board.id !== newTask.board.id) {
          campaignState.count = prevTasksCount;
          const board = campaignState.boards.items[newTask.board.id];
          if (board && board.taskIds.includes(newTask.id)) {
            board.count += 1;
          }
        }
        if (update.board) {
          moveTaskInBoard(newTask.id, update.board.beforeTaskId, state);
        }
        if (update.customFields?.add) {
          update.customFields.add.forEach(({ id, value }) => {
            const field = campaignState.customFields.list.find(
              (x) => x.id === id
            );
            if (field && field.type === 'tags') {
              if (!field.tags) field.tags = [];
              if (!field.tags.includes(value)) field.tags.push(value);
            }
          });
        }
      })
    );
    builder.addCase(updateTask.fulfilled, (state, action) => {
      const { arg: update, requestId } = action.meta;
      const taskState = getOrSetTaskState(state, update.id);
      if (state.countsToRefresh[requestId]) {
        refreshCounts(nonNull(state.countsToRefresh[requestId]), state);
        delete state.countsToRefresh[requestId];
      }
      newRequest(taskState.events.fetch, 'refresh');
      cancelUndo(state, action);
    });
    builder.addCase(updateTask.rejected, (state, action) => {
      const { arg: update } = action.meta;
      undo(state, action);
      if (action.error.code === '404') {
        const taskState = getOrSetTaskState(state, update.id);
        const { entity: task } = taskState;
        if (task) {
          const toRemove = getCurrentTaskLocation(task, state);
          removeTaskFromLists(task.id, toRemove, state);
        }
        taskState.notFound = true;
      }
    });
    builder.addCase(deleteTask.pending, (state, action) =>
      withUndo(state, action.meta.requestId, (state) => {
        const { arg, requestId } = action.meta;
        state.countsToRefresh[requestId] = handleDeletedTask(
          arg.id,
          !!arg.permanent,
          state
        );
      })
    );
    builder.addCase(deleteTask.fulfilled, (state, action) => {
      const { arg, requestId } = action.meta;
      const taskState = getOrSetTaskState(state, arg.id);
      if (state.countsToRefresh[requestId]) {
        refreshCounts(nonNull(state.countsToRefresh[requestId]), state);
        delete state.countsToRefresh[requestId];
      }
      if (!arg.permanent) newRequest(taskState.events.fetch, 'refresh');
      cancelUndo(state, action);
    });
    builder.addCase(deleteTask.rejected, (state, action) => {
      const { arg } = action.meta;
      undo(state, action);
      if (action.error.code === '404') {
        const taskState = getOrSetTaskState(state, arg.id);
        const { entity: task } = taskState;
        if (task) {
          const toRemove = getCurrentTaskLocation(task, state);
          removeTaskFromLists(task.id, toRemove, state);
        }
        taskState.notFound = true;
      }
    });
    builder.addCase(restoreTask.pending, (state, action) =>
      withUndo(state, action.meta.requestId, (state) => {
        const { arg, requestId } = action.meta;
        state.countsToRefresh[requestId] = handleRestoredTask(arg.id, state);
      })
    );
    builder.addCase(restoreTask.fulfilled, (state, action) => {
      const { arg, requestId } = action.meta;
      const taskState = getOrSetTaskState(state, arg.id);
      if (state.countsToRefresh[requestId]) {
        refreshCounts(nonNull(state.countsToRefresh[requestId]), state);
        delete state.countsToRefresh[requestId];
      }
      newRequest(taskState.events.fetch, 'refresh');
      cancelUndo(state, action);
    });
    builder.addCase(restoreTask.rejected, (state, action) => {
      const { arg } = action.meta;
      undo(state, action);
      if (action.error.code === '404') {
        const taskState = getOrSetTaskState(state, arg.id);
        const { entity: task } = taskState;
        if (task) {
          const toRemove = getCurrentTaskLocation(task, state);
          removeTaskFromLists(task.id, toRemove, state);
        }
        taskState.notFound = true;
      }
    });
    builder.addCase(fetchCampaignTaskBoards.pending, (state, action) => {
      const { arg, requestId } = action.meta;
      const campaignState = getOrSetCampaignState(state, arg.campaignId);
      const { boards } = campaignState;
      boards.fetch.requestId = requestId;
    });
    builder.addCase(fetchCampaignTaskBoards.fulfilled, (state, action) => {
      const { arg, requestId } = action.meta;
      const campaignState = getOrSetCampaignState(state, arg.campaignId);
      const { boards } = campaignState;
      if (boards.fetch.requestId !== requestId) return;
      campaignState.count = action.payload.list.reduce(
        (res, x) => res + x.tasksCount,
        0
      );
      boards.fetch.newRequest = null;
      boards.fetch.initiallyLoaded = true;
      boards.count = action.payload.list.length;
      boards.ids = action.payload.list
        .filter((x) => {
          if (x.id !== 'archived' && !campaignState.searchQuery) return true;
          return x.tasksCount > 0;
        })
        .map((x) => x.id);
      action.payload.list.forEach((x) => {
        const boardId = x.id;
        if (!boards.items[boardId]) {
          boards.items[boardId] = groupInitialState();
        }
        const item = boards.items[boardId] as TaskGroupItemState;
        item.name =
          boardId === 'no_assigned'
            ? noAssignedBoardName
            : boardId === 'archived'
            ? archivedBoardName
            : x.name;
        item.countFetch.initiallyLoaded = true;
        item.countFetch.newRequest = null;
        item.count = x.tasksCount;
        item.overdueTaskIds = x.overdueTaskIds;
        item.dueSoonTaskIds = x.dueSoonTaskIds;
        item.hasMore = x.tasksCount > item.taskIds.length;
        newRequest(item.fetch, arg.fetchType);
      });
      if (arg.fetchType === 'initial') {
        const noTasks = boards.ids.every(
          (boardId) => nonNull(boards.items[boardId]).count === 0
        );
        if (noTasks) {
          boards.openedIds = boards.ids
            .filter(
              (boardId) =>
                boardId !== 'no_assigned' &&
                nonNull(boards.items[boardId]).count === 0
            )
            .slice(0, 1);
        }
        if (!boards.openedIds.length) {
          boards.openedIds = boards.ids
            .filter((boardId) => nonNull(boards.items[boardId]).count > 0)
            .filter((boardId) => boardId !== 'no_assigned')
            .slice(0, 1);
        }
        if (!boards.openedIds.length) {
          boards.openedIds = ['no_assigned'];
        }
      }
    });
    builder.addCase(fetchTaskListInCampaign.pending, (state, action) => {
      const { arg, requestId } = action.meta;
      const campaignState = getOrSetCampaignState(state, arg.campaignId);
      const board = campaignState.boards.items[arg.boardId];
      if (!board) return;
      board.fetch.requestId = requestId;
    });
    builder.addCase(fetchTaskListInCampaign.fulfilled, (state, action) => {
      const { arg, requestId } = action.meta;
      const campaignState = getOrSetCampaignState(state, arg.campaignId);
      const board = campaignState.boards.items[arg.boardId];
      if (!board || board.fetch.requestId !== requestId) return;
      board.fetch.newRequest = null;
      board.fetch.initiallyLoaded = true;
      const taskIds = action.payload.edges.map((x) => x.node.id);
      if (arg.fetchType === 'more') {
        taskIds.forEach((id) => {
          if (!board.taskIds.includes(id)) board.taskIds.push(id);
        });
      } else {
        board.taskIds = taskIds;
      }
      if (arg.fetchType === 'refresh') {
        board.hasMore = board.count > board.taskIds.length;
      } else {
        board.hasMore = action.payload.hasNext;
        if (!board.hasMore) {
          board.count = board.taskIds.length;
          campaignState.count = campaignState.boards.ids.reduce(
            (res, id) => res + (campaignState.boards.items[id]?.count ?? 0),
            0
          );
        }
      }
      board.cursor = action.payload.endCursor;
      setTaskEntitiesFromList(action.payload, state);
    });
    builder.addCase(fetchTasksCountInCampaign.pending, (state, action) => {
      const { arg, requestId } = action.meta;
      const campaignState = getOrSetCampaignState(state, arg.campaignId);
      const board = campaignState.boards.items[arg.boardId];
      if (board) {
        board.countFetch.requestId = requestId;
      }
    });
    builder.addCase(fetchTasksCountInCampaign.fulfilled, (state, action) => {
      const { arg, requestId } = action.meta;
      const campaignState = getOrSetCampaignState(state, arg.campaignId);
      const board = campaignState.boards.items[arg.boardId];
      if (board?.countFetch.requestId === requestId) {
        board.countFetch.newRequest = null;
        board.count = action.payload.count;
        board.hasMore = board.count > board.taskIds.length;
        campaignState.count = campaignState.boards.ids.reduce(
          (res, id) => res + (campaignState.boards.items[id]?.count ?? 0),
          0
        );
      }
    });
    builder.addCase(moveTaskBoard.pending, (state, action) =>
      withUndo(state, action.meta.requestId, (state) => {
        const { arg } = action.meta;
        const campaignState = getOrSetCampaignState(state, arg.campaignId);
        const currentIndex = campaignState.boards.ids.findIndex(
          (x) => x === arg.id
        );
        if (currentIndex !== -1) {
          campaignState.boards.ids.splice(currentIndex, 1);
        }
        const beforeIndex = campaignState.boards.ids.findIndex(
          (x) => x === arg.beforeBoardId
        );
        if (beforeIndex !== -1) {
          campaignState.boards.ids.splice(beforeIndex, 0, arg.id);
        }
      })
    );
    builder.addCase(moveTaskBoard.fulfilled, cancelUndo);
    builder.addCase(moveTaskBoard.rejected, undo);
    builder.addCase(renameTaskBoard.pending, (state, action) =>
      withUndo(state, action.meta.requestId, (state) => {
        const { arg } = action.meta;
        const campaignState = getOrSetCampaignState(state, arg.campaignId);
        const board = campaignState.boards.items[arg.id];
        if (!board) return;
        board.name = arg.name;
      })
    );
    builder.addCase(renameTaskBoard.fulfilled, cancelUndo);
    builder.addCase(renameTaskBoard.rejected, undo);
    builder.addCase(createTask.pending, (state, action) =>
      withUndo(state, action.meta.requestId, (state) => {
        const { arg, requestId } = action.meta;
        const location = getNewTaskLocation({
          ...arg,
          status: arg.status ?? 'todo',
          board: arg.board ?? { id: 'no_assigned' },
          archived: false,
          owner: { user: { me: true } },
          assignees: arg.assignees ?? []
        });
        state.newTasks.push({
          requestId,
          location,
          position: arg.taskPosition
        });
        const campaignState = getOrSetCampaignState(state, arg.campaign.id);
        const boardId = arg.board?.id ?? 'no_assigned';
        if (!campaignState.boards.openedIds.includes(boardId)) {
          campaignState.boards.openedIds.push(boardId);
        }
        const wsState = getOrSetWsState(state, arg.workspaceId);
        Object.keys(wsState.lists).forEach((key) => {
          const listType = key as TaskListType;
          const list = wsState.lists[listType];
          list.groups.board.ids
            .filter((id) => id.includes(boardId))
            .forEach((id) => {
              if (!list.groups.board.openedIds.includes(id)) {
                list.groups.board.openedIds.push(id);
              }
            });
          list.groups.campaign.ids
            .filter((id) => id.includes(arg.campaign.id))
            .forEach((id) => {
              if (!list.groups.campaign.openedIds.includes(id)) {
                list.groups.campaign.openedIds.push(id);
              }
            });
        });
      })
    );
    builder.addCase(createTask.fulfilled, (state, action) => {
      const { arg, requestId } = action.meta;
      state.newTasks = state.newTasks.filter((x) => x.requestId !== requestId);
      const task = replaceBoardNames(action.payload.task);
      const taskState = getOrSetTaskState(state, task.id);
      taskState.fetch.initiallyLoaded = true;
      taskState.fetch.newRequest = null;
      taskState.entity = task;
      const location = getNewTaskLocation(task);
      addTaskToLists(task.id, location, state, arg.taskPosition);
      refreshCounts([location], state);
      cancelUndo(state, action);
    });
    builder.addCase(createTask.rejected, undo);
    builder.addCase(createTaskBoard.pending, (state, action) =>
      withUndo(state, action.meta.requestId, (state) => {
        const { arg, requestId } = action.meta;

        const campaignState = getOrSetCampaignState(state, arg.campaignId);
        campaignState.boards.ids.push(requestId);
        campaignState.boards.openedIds.push(requestId);
        campaignState.boards.count += 1;

        const boardState = groupInitialState();
        boardState.name = arg.name;
        boardState.fetch.initiallyLoaded = true;
        boardState.fetch.newRequest = null;
        boardState.hasMore = false;
        boardState.overdueTaskIds = [];
        boardState.dueSoonTaskIds = [];
        campaignState.boards.items[requestId] = boardState;
      })
    );
    builder.addCase(createTaskBoard.fulfilled, (state, action) => {
      const { arg, requestId } = action.meta;
      const { board } = action.payload;

      const campaignState = getOrSetCampaignState(state, arg.campaignId);

      const oldTaskBoardIndex = campaignState.boards.ids.indexOf(requestId);
      const oldOpenedTaskBoardIndex =
        campaignState.boards.openedIds.indexOf(requestId);

      campaignState.boards.ids.splice(oldTaskBoardIndex, 1);
      campaignState.boards.ids.push(board.id);

      campaignState.boards.openedIds.splice(oldOpenedTaskBoardIndex, 1);
      campaignState.boards.openedIds.push(board.id);

      campaignState.boards.items[board.id] =
        campaignState.boards.items[requestId];
      delete campaignState.boards.items[requestId];
      cancelUndo(state, action);
    });
    builder.addCase(createTaskBoard.rejected, undo);
    builder.addCase(deleteTaskBoard.pending, (state, action) =>
      withUndo(state, action.meta.requestId, (state) => {
        const { arg, requestId } = action.meta;
        const campaignState = getOrSetCampaignState(state, arg.campaignId);
        const board = campaignState.boards.items[arg.id];
        if (!board) return;
        const tasks = board.taskIds
          .filter((id) => !!state.items[id]?.entity)
          .filter((id) => !state.items[id]?.permanentlyDeleted)
          .map((id) => nonNull(state.items[id]?.entity));
        tasks.forEach((task) => {
          if (!arg.deleteAllTasks) {
            const newBoard = { id: 'no_assigned', name: noAssignedBoardName };
            const toRefresh = handleTaskLocationChange(
              task,
              { ...task, board: newBoard },
              state
            );
            task.board = newBoard;
            if (!state.countsToRefresh[requestId]) {
              state.countsToRefresh[requestId] = [];
            }
            state.countsToRefresh[requestId]?.push(...toRefresh);
          } else {
            const toRefresh = handleDeletedTask(task.id, false, state);
            if (!state.countsToRefresh[requestId]) {
              state.countsToRefresh[requestId] = [];
            }
            state.countsToRefresh[requestId]?.push(...toRefresh);
          }
        });
        if (arg.id !== 'archived' && arg.id !== 'no_assigned') {
          campaignState.boards.ids = campaignState.boards.ids.filter(
            (id) => id !== arg.id
          );
          campaignState.boards.count = campaignState.boards.ids.length;
          delete campaignState.boards.items[arg.id];
        }
      })
    );
    builder.addCase(deleteTaskBoard.fulfilled, (state, action) => {
      const { requestId } = action.meta;
      if (state.countsToRefresh[requestId]) {
        refreshCounts(nonNull(state.countsToRefresh[requestId]), state);
        delete state.countsToRefresh[requestId];
      }
      cancelUndo(state, action);
    });
    builder.addCase(deleteTaskBoard.rejected, undo);
    builder.addCase(fetchTaskCustomFields.pending, (state, action) => {
      const { arg, requestId } = action.meta;
      const campaignState = getOrSetCampaignState(state, arg.campaignId);
      campaignState.customFields.fetch.requestId = requestId;
    });
    builder.addCase(fetchTaskCustomFields.fulfilled, (state, action) => {
      const { arg, requestId } = action.meta;
      const campaignState = getOrSetCampaignState(state, arg.campaignId);
      if (campaignState.customFields.fetch.requestId !== requestId) return;
      campaignState.customFields.fetch.newRequest = null;
      campaignState.customFields.fetch.initiallyLoaded = true;
      campaignState.customFields.list = action.payload.fields;
    });
    builder.addCase(createTaskCustomField.fulfilled, (state, action) => {
      const { arg } = action.meta;
      const campaignState = getOrSetCampaignState(state, arg.campaignId);
      campaignState.customFields.list.push(action.payload.field);
    });
    builder.addCase(renameTaskCustomField.pending, (state, action) =>
      withUndo(state, action.meta.requestId, (state) => {
        const { arg } = action.meta;
        const campaignState = getOrSetCampaignState(state, arg.campaignId);
        const field = campaignState.customFields.list.find(
          (x) => x.id === arg.taskFieldId
        );
        if (field) field.name = arg.name;
      })
    );
    builder.addCase(renameTaskCustomField.fulfilled, cancelUndo);
    builder.addCase(renameTaskCustomField.rejected, undo);
    builder.addCase(deleteTaskCustomField.pending, (state, action) =>
      withUndo(state, action.meta.requestId, (state) => {
        const { arg } = action.meta;
        const campaignState = getOrSetCampaignState(state, arg.campaignId);
        campaignState.customFields.list =
          campaignState.customFields.list.filter(
            (x) => x.id !== arg.taskFieldId
          );
      })
    );
    builder.addCase(deleteTaskCustomField.fulfilled, cancelUndo);
    builder.addCase(deleteTaskCustomField.rejected, undo);
    builder.addCase(fetchTaskAssignees.pending, (state, action) => {
      const { arg, requestId } = action.meta;
      const taskState = getOrSetTaskState(state, arg.taskId);
      taskState.potentialAssignees.fetch.requestId = requestId;
    });
    builder.addCase(fetchTaskAssignees.fulfilled, (state, action) => {
      const { arg, requestId } = action.meta;
      const taskState = getOrSetTaskState(state, arg.taskId);
      if (taskState.potentialAssignees.fetch.requestId !== requestId) return;
      taskState.potentialAssignees.fetch.newRequest = null;
      taskState.potentialAssignees.fetch.initiallyLoaded = true;
      taskState.potentialAssignees.list = action.payload.edges.map(
        (x) => x.node
      );
    });
    builder.addCase(fetchTaskDescriptionMentions.pending, (state, action) => {
      const { arg, requestId } = action.meta;
      const taskState = getOrSetTaskState(state, arg.taskId);
      taskState.descriptionMentions.fetch.requestId = requestId;
    });
    builder.addCase(fetchTaskDescriptionMentions.fulfilled, (state, action) => {
      const { arg, requestId } = action.meta;
      const taskState = getOrSetTaskState(state, arg.taskId);
      if (taskState.descriptionMentions.fetch.requestId !== requestId) return;
      taskState.descriptionMentions.fetch.newRequest = null;
      taskState.descriptionMentions.fetch.initiallyLoaded = true;
      taskState.descriptionMentions.list = action.payload.edges.map(
        (x) => x.node
      );
    });
    builder.addCase(fetchTaskCommentMentions.pending, (state, action) => {
      const { arg, requestId } = action.meta;
      const taskState = getOrSetTaskState(state, arg.taskId);
      taskState.commentMentions.fetch.requestId = requestId;
    });
    builder.addCase(fetchTaskCommentMentions.fulfilled, (state, action) => {
      const { arg, requestId } = action.meta;
      const taskState = getOrSetTaskState(state, arg.taskId);
      if (taskState.commentMentions.fetch.requestId !== requestId) return;
      taskState.commentMentions.fetch.newRequest = null;
      taskState.commentMentions.fetch.initiallyLoaded = true;
      taskState.commentMentions.list = action.payload.edges.map((x) => x.node);
    });
    builder.addCase(fetchTaskEvents.pending, (state, action) => {
      const { arg, requestId } = action.meta;
      const taskState = getOrSetTaskState(state, arg.taskId);
      taskState.events.fetch.requestId = requestId;
    });
    builder.addCase(fetchTaskEvents.fulfilled, (state, action) => {
      const { arg, requestId } = action.meta;
      const taskState = getOrSetTaskState(state, arg.taskId);
      if (taskState.events.fetch.requestId !== requestId) return;
      taskState.events.fetch.newRequest = null;
      taskState.events.fetch.initiallyLoaded = true;
      if (arg.fetchType === 'initial' || arg.fetchType === 'reload') {
        taskState.events.startCursor = action.payload.startCursor;
        taskState.events.cursor = action.payload.endCursor;
        taskState.events.hasMore = action.payload.hasNext;
        const list = action.payload.edges.map((x) => x.node);
        taskState.events.list = formatTaskEvents(list);
      } else if (arg.fetchType === 'more') {
        taskState.events.cursor = action.payload.endCursor;
        taskState.events.hasMore = action.payload.hasNext;
        const list = formatTaskEventsList(taskState.events.list);
        action.payload.edges
          .map((x) => x.node)
          .forEach((a) => {
            if (!list.some((b) => a.id === b.id)) {
              const event = applyTaskEventState(a);
              taskState.events.list.push(event);
            }
          });
      } else if (arg.fetchType === 'refresh') {
        if (action.payload.startCursor) {
          taskState.events.startCursor = action.payload.startCursor;
        }
        const list = formatTaskEventsList(taskState.events.list);
        action.payload.edges
          .map((x) => x.node)
          .reverse()
          .forEach((a) => {
            if (!list.some((b) => a.id === b.id)) {
              const event = applyTaskEventState(a);
              taskState.events.list.unshift(event);
            }
          });
      }
    });
    builder.addCase(createTaskComment.fulfilled, (state, action) => {
      const { arg } = action.meta;
      const taskState = getOrSetTaskState(state, arg.taskId);
      const { node: comment } = action.payload;
      const list = formatTaskEventsList(taskState.events.list);
      if (!list.some((x) => x.id === comment.id)) {
        const event = applyTaskEventState(comment);
        taskState.events.list.unshift(event);

        if (taskState.entity) {
          taskState.entity.commentsCount += 1;
        }
      }
    });
    builder.addCase(fetchTaskCampaigns.pending, (state, action) => {
      const { arg, requestId } = action.meta;
      const taskState = getOrSetTaskState(state, arg.taskId);
      taskState.campaigns.fetch.requestId = requestId;
    });
    builder.addCase(fetchTaskCampaigns.fulfilled, (state, action) => {
      const { arg, requestId } = action.meta;
      const taskState = getOrSetTaskState(state, arg.taskId);
      if (taskState.campaigns.fetch.requestId !== requestId) return;
      taskState.campaigns.fetch.newRequest = null;
      taskState.campaigns.fetch.initiallyLoaded = true;
      taskState.campaigns.list = action.payload.list;
    });
    builder.addCase(fetchTaskBoards.pending, (state, action) => {
      const { arg, requestId } = action.meta;
      const taskState = getOrSetTaskState(state, arg.taskId);
      taskState.boards.fetch.requestId = requestId;
    });
    builder.addCase(fetchTaskBoards.fulfilled, (state, action) => {
      const { arg, requestId } = action.meta;
      const taskState = getOrSetTaskState(state, arg.taskId);
      if (taskState.boards.fetch.requestId !== requestId) return;
      taskState.boards.fetch.newRequest = null;
      taskState.boards.fetch.initiallyLoaded = true;
      taskState.boards.list = action.payload.list
        .filter((x) => x.id !== 'archived')
        .map((x) => ({
          id: x.id,
          name: x.id === 'no_assigned' ? noAssignedBoardName : x.name
        }));
    });
    builder.addCase(fetchTaskAssets.pending, (state, action) => {
      const { arg, requestId } = action.meta;
      const taskState = getOrSetTaskState(state, arg.taskId);
      taskState.assets.fetch.requestId = requestId;
    });
    builder.addCase(fetchTaskAssets.fulfilled, (state, action) => {
      const { arg, requestId } = action.meta;
      const taskState = getOrSetTaskState(state, arg.taskId);
      if (taskState.assets.fetch.requestId !== requestId) return;
      taskState.assets.fetch.newRequest = null;
      taskState.assets.fetch.initiallyLoaded = true;
      taskState.assets.cursor = action.payload.endCursor;
      taskState.assets.hasMore = action.payload.hasNext;
      if (arg.fetchType === 'more') {
        action.payload.edges.forEach(({ node }) => {
          if (!taskState.assets.list.some((x) => x.id === node.id)) {
            taskState.assets.list.push(node as any);
          }
        });
      } else {
        taskState.assets.list = action.payload.edges.map((x) => x.node as any);
      }
    });
    builder.addCase(deleteTaskAttachment.pending, (state, action) =>
      withUndo(state, action.meta.requestId, (state) => {
        const { arg } = action.meta;
        const { entity: task } = getOrSetTaskState(state, arg.taskId);
        if (task) {
          task.attachments = task.attachments.filter((x) => x.id !== arg.id);
        }
      })
    );
    builder.addCase(deleteTaskAttachment.fulfilled, (state, action) => {
      const { arg } = action.meta;
      const taskState = getOrSetTaskState(state, arg.taskId);
      newRequest(taskState.events.fetch, 'refresh');
      cancelUndo(state, action);
    });
    builder.addCase(deleteTaskAttachment.rejected, undo);
    builder.addCase(fetchTaskAttachment.fulfilled, (state, action) => {
      const { arg } = action.meta;
      const { attachment } = action.payload;
      const taskState = getOrSetTaskState(state, arg.taskId);
      const { entity: task } = taskState;
      if (task && !task.attachments.some((x) => x.id === attachment.id)) {
        task.attachments.push(attachment);
        newRequest(taskState.events.fetch, 'refresh');
      }
    });
    builder.addCase(fetchDashboardTaskList.pending, (state, action) => {
      const { arg, requestId } = action.meta;
      const { dashboard } = getOrSetWsState(state, arg.workspaceId);
      dashboard.fetch.requestId = requestId;
    });
    builder.addCase(fetchDashboardTaskList.fulfilled, (state, action) => {
      const { arg, requestId } = action.meta;
      const { dashboard } = getOrSetWsState(state, arg.workspaceId);
      if (dashboard.fetch.requestId !== requestId) return;
      dashboard.fetch.newRequest = null;
      dashboard.fetch.initiallyLoaded = true;
      const taskIds = action.payload.edges.map((x) => x.node.id);
      if (arg.fetchType === 'more') {
        taskIds.forEach((id) => {
          if (!dashboard.taskIds.includes(id)) dashboard.taskIds.push(id);
        });
      } else {
        dashboard.taskIds = taskIds;
      }
      if (arg.fetchType === 'refresh') {
        dashboard.hasMore = dashboard.count > dashboard.taskIds.length;
      } else {
        dashboard.hasMore = action.payload.hasNext;
        if (!dashboard.hasMore) {
          dashboard.count = dashboard.taskIds.length;
        }
      }
      dashboard.cursor = action.payload.endCursor;
      setTaskEntitiesFromList(action.payload, state);
    });
    builder.addCase(fetchDashboardTasksCount.pending, (state, action) => {
      const { arg, requestId } = action.meta;
      const { dashboard } = getOrSetWsState(state, arg.workspaceId);
      dashboard.countFetch.requestId = requestId;
    });
    builder.addCase(fetchDashboardTasksCount.fulfilled, (state, action) => {
      const { arg, requestId } = action.meta;
      const { dashboard } = getOrSetWsState(state, arg.workspaceId);
      if (dashboard.countFetch.requestId !== requestId) return;
      dashboard.countFetch.newRequest = null;
      dashboard.countFetch.initiallyLoaded = true;
      dashboard.count = action.payload.count;
      dashboard.hasMore = dashboard.count > dashboard.taskIds.length;
    });
    builder.addCase(fetchTaskListInAssetVerion.pending, (state, action) => {
      const { arg, requestId } = action.meta;
      const avState = getOrSetAssetVersionState(state, arg.assetVersionId);
      avState.fetch.requestId = requestId;
    });
    builder.addCase(fetchTaskListInAssetVerion.fulfilled, (state, action) => {
      const { arg, requestId } = action.meta;
      const avState = getOrSetAssetVersionState(state, arg.assetVersionId);
      if (avState.fetch.requestId !== requestId) return;
      avState.fetch.newRequest = null;
      avState.fetch.initiallyLoaded = true;
      const taskIds = action.payload.edges.map((x) => x.node.id);
      if (arg.fetchType === 'more') {
        taskIds.forEach((id) => {
          if (!avState.taskIds.includes(id)) avState.taskIds.push(id);
        });
      } else {
        avState.taskIds = taskIds;
      }
      if (arg.fetchType === 'refresh') {
        avState.hasMore = avState.count > avState.taskIds.length;
      } else {
        avState.hasMore = action.payload.hasNext;
        if (!avState.hasMore) {
          avState.count = avState.taskIds.length;
        }
      }
      avState.cursor = action.payload.endCursor;
      setTaskEntitiesFromList(action.payload, state);
    });
    builder.addCase(fetchTasksCountInAssetVerion.pending, (state, action) => {
      const { arg, requestId } = action.meta;
      const avState = getOrSetAssetVersionState(state, arg.assetVersionId);
      avState.countFetch.requestId = requestId;
    });
    builder.addCase(fetchTasksCountInAssetVerion.fulfilled, (state, action) => {
      const { arg, requestId } = action.meta;
      const avState = getOrSetAssetVersionState(state, arg.assetVersionId);
      if (avState.countFetch.requestId !== requestId) return;
      avState.countFetch.newRequest = null;
      avState.countFetch.initiallyLoaded = true;
      avState.count = action.payload.count;
      avState.hasMore = avState.count > avState.taskIds.length;
    });
    builder.addCase(fetchAssetAssignees.pending, (state, action) => {
      const { arg, requestId } = action.meta;
      const aState = getOrSetAssetState(state, arg.assetId);
      aState.potentialAssignees.fetch.requestId = requestId;
    });
    builder.addCase(fetchAssetAssignees.fulfilled, (state, action) => {
      const { arg, requestId } = action.meta;
      const aState = getOrSetAssetState(state, arg.assetId);
      if (aState.potentialAssignees.fetch.requestId !== requestId) return;
      aState.potentialAssignees.fetch.newRequest = null;
      aState.potentialAssignees.fetch.initiallyLoaded = true;
      aState.potentialAssignees.list = action.payload.edges.map((x) => x.node);
    });
  }
});

export const {
  setListParams,
  setGroupBy,
  loadMoreTasks,
  setScrollTop,
  setGroupOpened,
  setListNeedRefresh,
  setListAssigneesSearch,
  setListParamsInCampaign,
  setTaskBoardOpenedInCampaign,
  loadMoreCampaignTasks,
  setScrollTopInCampaign,
  setListsNeedRefreshInCampaign,
  openNewTaskModal,
  openTaskModal,
  closeTaskModal,
  disposeTaskModal,
  loadMoreTaskEvents,
  toggleTaskEventsGroup,
  setTaskAssigneesSearch,
  setTaskDescriptionMentionsSearch,
  setTaskCommentMentionsSearch,
  loadMoreTaskAssets,
  setTaskAssetsSearch,
  updateNewTask,
  handleTaskAttachmentDelete,
  handleTaskDelete,
  handleTaskRestore,
  handleTaskCommentCreate,
  setDashboardListNeedRefresh,
  loadMoreDashboardTasks,
  setDashboardTaskListParams,
  setAssetVersionListParams,
  setAssetVersionListNeedRefresh,
  setAssetListNeedRefresh,
  loadMoreAssetVersionTasks
} = tasksSlice.actions;
export default tasksSlice.reducer;
