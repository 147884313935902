import { useAuth } from '@hooks/useAuth';
import { ReactElement, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

interface TrialSignUpProps {
  loader: ReactElement;
}

export function TrialSignUp({ loader }: TrialSignUpProps) {
  const { login } = useAuth();
  const [searchParams] = useSearchParams();
  const trialToken = searchParams.get('ticket');
  useEffect(() => {
    login({
      prompt: 'login',
      appState: {
        returnTo: `/onboarding?trial=${trialToken ?? ''}`
      },
      targetPage: 'register'
    });
  }, [login, trialToken]);
  return loader;
}
