import {
  ICampaignState,
  ICampaignAction,
  CampaignActionTypes
} from '@redux/types/campaignType';

const INITIAL_STATE: ICampaignState = {
  edges: null,
  count: 0,
  hasNext: false,
  startCursor: '',
  endCursor: '',
  selectedCampaign: null
};

const campaignReducer = (
  state = INITIAL_STATE,
  action: ICampaignAction
): ICampaignState => {
  switch (action.type) {
    case CampaignActionTypes.SET_CAMPAIGN_LIST: {
      return {
        ...state,
        ...action.payload
      };
    }
    case CampaignActionTypes.LOAD_MORE_CAMPAIGN: {
      return {
        ...state,
        ...action.payload,
        edges: [...(state.edges || []), ...action.payload.edges]
      };
    }

    case CampaignActionTypes.SET_FAVORITE_CAMPAIGN: {
      const { campaignId, isFavorite, needRemoveFromList } = action.payload;
      let newEdges = state.edges;
      let newSelectedCampaign = state.selectedCampaign;
      if (needRemoveFromList) {
        newEdges = newEdges?.filter((el) => el.node.id !== campaignId) || [];
        return {
          ...state,
          edges: newEdges,
          count: (state.count || 1) - 1
        };
      }
      if (newSelectedCampaign && newSelectedCampaign.id === campaignId) {
        newSelectedCampaign = {
          ...newSelectedCampaign,
          isFavorite
        };
      }
      newEdges =
        state.edges?.map((el) => {
          if (el.node.id === campaignId)
            return {
              ...el,
              node: {
                ...el.node,
                isFavorite
              }
            };

          return el;
        }) || [];
      return {
        ...state,
        edges: newEdges,
        selectedCampaign: newSelectedCampaign
      };
    }

    case CampaignActionTypes.DELETE_CAMPAIGN: {
      const { campaignId } = action.payload;
      const newEdges =
        state.edges?.filter((el) => el.node.id !== campaignId) || [];
      return {
        ...state,
        edges: newEdges,
        count: (state?.count || 1) - 1
      };
    }

    case CampaignActionTypes.RESTORE_DELETED_CAMPAIGN: {
      const { campaignId } = action.payload;
      const newEdges =
        state.edges?.filter((el) => el.node.id !== campaignId) || [];
      return {
        ...state,
        edges: newEdges,
        count: (state?.count || 1) - 1
      };
    }

    case CampaignActionTypes.CREATE_CAMPAIGN: {
      const { campaign } = action.payload;
      const newEdges = [{ node: campaign, cursor: '' }, ...(state.edges || [])];
      return {
        ...state,
        selectedCampaign: campaign,
        edges: newEdges,
        count: (state.count || 0) + 1
      };
    }

    case CampaignActionTypes.UPDATE_CAMPAIGN_COUNTER: {
      const { event, folderId } = action.payload;
      let totalAssetsCount = state.selectedCampaign?.totalAssetsCount || 0;
      let assetsCount = state.selectedCampaign?.assetsCount || 0;
      let foldersCount = state.selectedCampaign?.foldersCount || 0;
      const taskBoardsCount = state.selectedCampaign?.taskBoardsCount || 0;

      if (event === 'deleteFolder') {
        foldersCount = (state.selectedCampaign?.foldersCount || 1) - 1;
      }
      if (event === 'deleteFile') {
        if (!folderId)
          assetsCount = (state.selectedCampaign?.assetsCount || 1) - 1;
        totalAssetsCount = (state.selectedCampaign?.totalAssetsCount || 1) - 1;
      }

      if (event === 'addFolder') {
        foldersCount = (state.selectedCampaign?.foldersCount || 0) + 1;
      }
      if (event === 'addFile') {
        if (!folderId)
          assetsCount = (state.selectedCampaign?.assetsCount || 0) + 1;
        totalAssetsCount = (state.selectedCampaign?.totalAssetsCount || 0) + 1;
      }
      const newEdges =
        state.edges?.map((el) => {
          if (el.node.id === state.selectedCampaign?.id)
            return {
              ...el,
              foldersCount,
              totalAssetsCount,
              assetsCount
            };
          return el;
        }) || [];
      const selectedCampaign = state.selectedCampaign
        ? {
            ...state.selectedCampaign,
            foldersCount,
            totalAssetsCount,
            assetsCount
          }
        : null;
      return {
        ...state,
        edges: newEdges,
        selectedCampaign
      };
    }

    case CampaignActionTypes.SET_CAMPAIGN: {
      return {
        ...state,
        selectedCampaign: action.payload
      };
    }
    case CampaignActionTypes.UPDATE_CAMPAIGN_ADDITIONAL_PERMISSIONS: {
      return {
        ...state,
        selectedCampaign: state.selectedCampaign
          ? {
              ...state.selectedCampaign,
              additionalPermissions: action.payload
            }
          : null
      };
    }
    case CampaignActionTypes.SET_CAMPAIGN_VISIBILITY: {
      const { campaignId, isPrivate } = action.payload;
      let newEdges = state.edges;
      let newSelectedCampaign = state.selectedCampaign;
      if (newSelectedCampaign && newSelectedCampaign.id === campaignId) {
        newSelectedCampaign = {
          ...newSelectedCampaign,
          isPrivate
        };
      }
      newEdges =
        state.edges?.map((el) => {
          if (el.node.id === campaignId)
            return {
              ...el,
              node: {
                ...el.node,
                isPrivate
              }
            };

          return el;
        }) || [];
      return {
        ...state,
        edges: newEdges,
        selectedCampaign: newSelectedCampaign
      };
    }

    case CampaignActionTypes.UPDATE_CAMPAIGN_DETAILS: {
      const { data } = action.payload;
      const { id } = data;
      const newEdges =
        state.edges?.map((el) => {
          if (el.node.id === id)
            return { ...el, node: { ...el.node, ...data } };
          return el;
        }) || [];
      let newSelectedCampaign = state.selectedCampaign;
      if (newSelectedCampaign && newSelectedCampaign.id === id) {
        newSelectedCampaign = {
          ...newSelectedCampaign,
          ...data
        };
      }
      return {
        ...state,
        edges: newEdges,
        selectedCampaign: newSelectedCampaign
      };
    }

    case CampaignActionTypes.UPDATE_CAMPAIGN: {
      const { campaign } = action.payload;
      const edges =
        state.edges?.map((el) => {
          if (el.node.id === campaign.id)
            return {
              ...el,
              node: campaign
            };
          return el;
        }) || null;
      let { selectedCampaign } = state;
      if (selectedCampaign && selectedCampaign.id === campaign.id)
        selectedCampaign = campaign;
      return {
        ...state,
        edges,
        selectedCampaign
      };
    }

    case CampaignActionTypes.RENAME_CAMPAIGN: {
      const { id, name } = action.payload;
      let { selectedCampaign } = state;
      if (selectedCampaign && selectedCampaign.id === id)
        selectedCampaign = {
          ...selectedCampaign,
          name
        };
      const newEdges =
        state.edges?.map((el) => {
          if (el.node.id === id)
            return {
              ...el,
              node: {
                ...el.node,
                name
              }
            };
          return el;
        }) || [];

      return {
        ...state,
        edges: newEdges,
        selectedCampaign
      };
    }

    default: {
      return state;
    }
  }
};

export default campaignReducer;
