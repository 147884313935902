import {
  CreateWorkspaceDto,
  RenameWorkspaceDto,
  WorkspaceControllerDeleteWorkspaceParams,
  WorkspaceControllerGetPictureUploadParamsParams,
  InviteWorkspaceMembersMemberDto,
  WorkspaceControllerFindMembersToInviteParams,
  WorkspaceControllerListMembersParams,
  WorkspaceControllerCountMembersParams,
  WorkspaceControllerDeleteWorkspaceMembersParams,
  ChangeWorkspaceMembersRoleDto,
  WorkspaceControllerDeletePublicInvitationUrlParams,
  LeaveWorkspaceDto,
  WorkspaceControllerGetWorkspaceObjectivesParams,
  ResendWorkspaceInvitationDto,
  WorkspaceControllerGetWorkspaceParams,
  WorkspaceControllerSearchWorkspaceItemsParams,
  WorkspaceControllerSearchAssetCommentsParams,
  WorkspaceControllerSearchAssetCommentsCountParams,
  WorkspaceControllerListMemberGroupsParams,
  WorkspaceControllerCountMemberGroupsParams,
  WorkspaceControllerFindMembersToInviteInGroupParams,
  RequestParams
} from '@api/Api';
import { apiClient } from '@api/client';

export const getWorkspaceCounters = (workspaceId: string) =>
  apiClient.workspace.workspaceControllerGetWorkspaceCounters({ workspaceId });

export const createWorkspace = (data: CreateWorkspaceDto) =>
  apiClient.workspace.workspaceControllerCreateWorkspace(data);

export const getWorkspaceList = () =>
  apiClient.workspace.workspaceControllerListUserWorkspaces();

export const getWorkspace = (params: WorkspaceControllerGetWorkspaceParams) =>
  apiClient.workspace.workspaceControllerGetWorkspace(params);

export const changeWorkspaceName = (data: RenameWorkspaceDto) =>
  apiClient.workspace.workspaceControllerRenameWorkspace(data);

export const uploadWorkspacePicture = (
  params: WorkspaceControllerGetPictureUploadParamsParams
) => apiClient.workspace.workspaceControllerGetPictureUploadParams(params);

export const deleteWorkspace = (
  params: WorkspaceControllerDeleteWorkspaceParams
) => apiClient.workspace.workspaceControllerDeleteWorkspace(params);

export const deleteWorkspacePicture = (workspaceId: string) => {
  apiClient.workspace.workspaceControllerDeltePicture({ workspaceId });
};

export const workspaceListMembers = (
  params: WorkspaceControllerListMembersParams,
  options?: RequestParams
) => apiClient.workspace.workspaceControllerListMembers(params, options);

export const workspaceCountMembers = (
  params: WorkspaceControllerCountMembersParams,
  options?: RequestParams
) => apiClient.workspace.workspaceControllerCountMembers(params, options);

export const workspaceListMemberGroups = (
  params: WorkspaceControllerListMemberGroupsParams,
  options?: RequestParams
) => apiClient.workspace.workspaceControllerListMemberGroups(params, options);

export const workspaceCountMemberGroups = (
  params: WorkspaceControllerCountMemberGroupsParams,
  options?: RequestParams
) => apiClient.workspace.workspaceControllerCountMemberGroups(params, options);

export const inviteWorkspaceMembers = (
  workspaceId: string,
  emails: InviteWorkspaceMembersMemberDto[]
) => {
  return apiClient.workspace.workspaceControllerInviteWorkspaceMembers({
    workspaceId,
    users: emails
  });
};

export const resendInviteWorkspaceMembers = (
  params: ResendWorkspaceInvitationDto
) => {
  return apiClient.workspace.workspaceControllerResendWorkspaceInvitation(
    params
  );
};

export const generateWorkspacePublicInvitationUrl = (
  subdomain: string,
  workspaceId: string
) => {
  const body = {
    workspaceId
  };
  return apiClient.workspace.workspaceControllerGeneratePublicInvitationUrl(
    body
  );
};

export const deleteWorkspacePublicInvitationUrl = (
  params: WorkspaceControllerDeletePublicInvitationUrlParams
) => {
  return apiClient.workspace.workspaceControllerDeletePublicInvitationUrl(
    params
  );
};

export const findWorkspaceMembersToInvite = (
  data: WorkspaceControllerFindMembersToInviteParams,
  params: RequestParams = {}
) => apiClient.workspace.workspaceControllerFindMembersToInvite(data, params);

export const findWorkspaceMembersToInviteInGroup = (
  data: WorkspaceControllerFindMembersToInviteInGroupParams,
  params: RequestParams = {}
) =>
  apiClient.workspace.workspaceControllerFindMembersToInviteInGroup(
    data,
    params
  );

export const deleteWorkspaceMembers = (
  params: WorkspaceControllerDeleteWorkspaceMembersParams
) => apiClient.workspace.workspaceControllerDeleteWorkspaceMembers(params);

export const changeWorkspaceMembersRole = (
  body: ChangeWorkspaceMembersRoleDto
) => apiClient.workspace.workspaceControllerChangeWorkspaceMembersRole(body);

export const leaveWorkspace = (body: LeaveWorkspaceDto) =>
  apiClient.workspace.workspaceControllerLeaveWorkspace(body);

export const workspaceGetWorkspaceObjectives = (
  params: WorkspaceControllerGetWorkspaceObjectivesParams
) => apiClient.workspace.workspaceControllerGetWorkspaceObjectives(params);

export const workspaceSearchWorkspaceItems = (
  params: WorkspaceControllerSearchWorkspaceItemsParams
) => apiClient.workspace.workspaceControllerSearchWorkspaceItems(params);

export const workspaceSearchAssetComments = (
  params: WorkspaceControllerSearchAssetCommentsParams
) => apiClient.workspace.workspaceControllerSearchAssetComments(params);

export const workspaceSearchAssetCommentsCount = (
  params: WorkspaceControllerSearchAssetCommentsCountParams
) => apiClient.workspace.workspaceControllerSearchAssetCommentsCount(params);
