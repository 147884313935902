import React, {
  createContext,
  ReactNode,
  useEffect,
  useRef,
  useState
} from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { nonNull } from '@helpers/non-null';
import {
  externalReviewerSignInAsExternalAwReviewer,
  externalReviewerSignUpAsExternalAwReviewer,
  signInExternalReviewer,
  signUpExternalReviewer
} from '@api/ExternalReviewer';
import moment from 'moment';
import { CompleteOnboardingStepDto, ExternalReviewerDto } from '@api/Api';
import { useMixpanel } from 'react-mixpanel-browser';
import { MixpanelService } from '@services/mixpanelService';
import { datadogLogs } from '@datadog/browser-logs';
import { IAuthContext, User, AuthError } from '@context/AuthContext/types';

export const AuthContext = createContext<
  IAuthContext<boolean, 'external-reviewer' | 'internal'>
>(undefined as any);

interface AuthProviderProps {
  readonly children: ReactNode;
}

const EXTERNAL_REVIEWER_KEY = 'external-reviewer';

function mapExternalUser(
  reviewer: ExternalReviewerDto
): User<'external-reviewer'> {
  const completedOnboardingStepsKey = `streamwork://${reviewer.email}/completed-onboarding-steps`;
  const completedOnboardingSteps = (localStorage
    .getItem(completedOnboardingStepsKey)
    ?.split(',') ?? []) as unknown as CompleteOnboardingStepDto['type'][];
  return {
    type: 'external-reviewer',
    id: reviewer.email,
    createdAt: null,
    canChangePassword: null,
    pictureUrl: reviewer.picture.url ?? null,
    email: reviewer.email,
    emailVerified: false,
    firstName: reviewer.firstName,
    lastName: reviewer.lastName ?? null,
    name: `${reviewer.firstName} ${reviewer.lastName ?? ''}`.trim(),
    companyName: null,
    phone: null,
    timeZone: null,
    notificationFrequency: null,
    twoStepVerificationEnabled: false,
    rememberMe: true,
    isNewUser: null,
    primaryProviderId: null,
    completedOnboardingSteps,
    slackConnected: false
  };
}

export function AuthProvider({ children }: AuthProviderProps) {
  const auth0 = useAuth0();
  const auth0LoginRef = useRef(auth0.loginWithRedirect);
  const auth0LogoutRef = useRef(auth0.logout);
  auth0LoginRef.current = auth0.loginWithRedirect;
  auth0LogoutRef.current = auth0.logout;

  const [externalUser, setExternalUser] = useState<
    | { error: null; isLoading: true }
    | {
        error: null;
        isLoading: boolean;
        user: User<'external-reviewer'>;
        assetReviewId: string | null;
        awId: string | null;
        accessToken: string;
        expiresAt: Date;
      }
    | { error: AuthError; isLoading: boolean }
  >();

  const [auth, setAuth] = useState<
    IAuthContext<boolean, 'external-reviewer' | 'internal'>
  >({
    isLoading: true,
    isAuthenticated: false,
    user: null,
    error: null,
    getAccessToken: null,
    logout: (options) => {
      localStorage.removeItem(EXTERNAL_REVIEWER_KEY);
      for (let i = 0; i < localStorage.length; ++i) {
        const key = localStorage.key(i);
        if (key?.startsWith('asset-review-password-')) {
          localStorage.removeItem(key);
        }
      }
      auth0LogoutRef.current(options);
      setExternalUser(undefined);
    },
    login: async (options) => {
      localStorage.removeItem(EXTERNAL_REVIEWER_KEY);
      return await auth0LoginRef.current(options);
    },
    loginForReview: async (payload) => {
      auth0LogoutRef.current({ localOnly: true });
      try {
        const { data } = await signInExternalReviewer(payload);
        setExternalUser({
          error: null,
          isLoading: false,
          assetReviewId: payload.assetReviewId,
          awId: null,
          accessToken: data.accessToken,
          expiresAt: moment(data.expiresAt).toDate(),
          user: mapExternalUser(data.reviewer)
        });
      } catch (err) {
        const errorCode = (err as any).response?.data?.errorCode;
        (err as any).error = errorCode || 'unknown';
        setExternalUser({ error: err as AuthError, isLoading: false });
      }
    },
    signUpForReview: async (payload) => {
      auth0LogoutRef.current({ localOnly: true });
      try {
        const { data } = await signUpExternalReviewer(payload);
        setExternalUser({
          error: null,
          isLoading: false,
          assetReviewId: payload.assetReviewId,
          awId: null,
          accessToken: data.accessToken,
          expiresAt: moment(data.expiresAt).toDate(),
          user: mapExternalUser(data.reviewer)
        });
      } catch (err) {
        const errorCode = (err as any).response?.data?.errorCode;
        (err as any).error = errorCode || 'unknown';
        setExternalUser({ error: err as AuthError, isLoading: false });
      }
    },
    loginForAW: async (payload) => {
      auth0LogoutRef.current({ localOnly: true });
      try {
        const { data } = await externalReviewerSignInAsExternalAwReviewer(
          payload
        );
        setExternalUser({
          error: null,
          isLoading: false,
          assetReviewId: null,
          awId: payload.assetVersionId,
          accessToken: data.accessToken,
          expiresAt: moment(data.expiresAt).toDate(),
          user: mapExternalUser(data.reviewer)
        });
      } catch (err) {
        const errorCode = (err as any).response?.data?.errorCode;
        (err as any).error = errorCode || 'unknown';
        setExternalUser({ error: err as AuthError, isLoading: false });
      }
    },
    signUpForAW: async (payload) => {
      auth0LogoutRef.current({ localOnly: true });
      try {
        const { data } = await externalReviewerSignUpAsExternalAwReviewer(
          payload
        );
        setExternalUser({
          error: null,
          isLoading: false,
          assetReviewId: null,
          awId: payload.assetVersionId,
          accessToken: data.accessToken,
          expiresAt: moment(data.expiresAt).toDate(),
          user: mapExternalUser(data.reviewer)
        });
      } catch (err) {
        const errorCode = (err as any).response?.data?.errorCode;
        (err as any).error = errorCode || 'unknown';
        setExternalUser({ error: err as AuthError, isLoading: false });
      }
    },
    updateUserProfile: (profile) => {
      setAuth((prev) => {
        if (!prev.user) return prev;
        const updated = { ...prev.user };
        if (profile.firstName !== undefined) {
          updated.firstName = profile.firstName;
        }
        if (profile.lastName !== undefined) {
          updated.lastName = profile.lastName;
        }
        if (profile.emailVerified !== undefined) {
          updated.emailVerified = profile.emailVerified;
        }
        if (profile.companyName !== undefined) {
          updated.companyName = profile.companyName;
        }
        if (profile.phone !== undefined) {
          updated.phone = profile.phone;
        }
        if (profile.timeZone !== undefined) {
          updated.timeZone = profile.timeZone;
        }
        if (profile.notificationFrequency !== undefined) {
          updated.notificationFrequency = profile.notificationFrequency;
        }
        if (profile.pictureUrl !== undefined) {
          updated.pictureUrl = profile.pictureUrl;
        }
        if (profile.twoStepVerificationEnabled !== undefined) {
          updated.twoStepVerificationEnabled =
            profile.twoStepVerificationEnabled;
        }
        if (profile.slackConnected !== undefined) {
          updated.slackConnected = profile.slackConnected;
        }
        updated.name = `${updated.firstName} ${updated.lastName || ''}`.trim();
        if (profile.completedOnboardingSteps !== undefined) {
          updated.completedOnboardingSteps = profile.completedOnboardingSteps;
          const completedOnboardingStepsKey = `streamwork://${updated.email}/completed-onboarding-steps`;
          localStorage.setItem(
            completedOnboardingStepsKey,
            updated.completedOnboardingSteps.join(',')
          );
        }
        return { ...prev, user: updated };
      });
    }
  });

  useEffect(() => {
    if (auth0.isLoading || auth0.isAuthenticated || auth0.error) {
      setAuth((prev) => ({
        ...prev,
        isLoading: auth0.isLoading,
        isAuthenticated: auth0.isAuthenticated,
        user: auth0.user
          ? {
              type: 'internal',
              id: nonNull(auth0.user.app_user_id),
              createdAt: nonNull(auth0.user.created_at) ?? null,
              canChangePassword: nonNull(auth0.user.can_change_password),
              pictureUrl: auth0.user.picture ?? null,
              email: nonNull(auth0.user.email),
              emailVerified: nonNull(auth0.user.email_verified),
              firstName: nonNull(auth0.user.given_name),
              lastName: auth0.user.family_name ?? null,
              name: `${nonNull(auth0.user.given_name)} ${
                auth0.user.family_name || ''
              }`.trim(),
              companyName: auth0.user.company_name ?? null,
              phone: auth0.user.phone ?? null,
              timeZone: auth0.user.time_zone ?? null,
              notificationFrequency: auth0.user.notification_frequency ?? null,
              twoStepVerificationEnabled: nonNull(
                auth0.user.two_step_verification_enabled
              ),
              rememberMe: auth0.user.remember_me ?? null,
              isNewUser: auth0.user.is_new_user ?? null,
              primaryProviderId: auth0.user.primary_provider_id ?? null,
              completedOnboardingSteps: auth0.user.completed_onboarding_steps,
              slackConnected: auth0.user.slack_connected ?? null
            }
          : null,
        error: (auth0.error as any) ?? null,
        getAccessToken: auth0.isAuthenticated
          ? (ignoreCache) => auth0.getAccessTokenSilently({ ignoreCache })
          : null
      }));
    } else if (externalUser?.isLoading) {
      setAuth((prev) => ({
        ...prev,
        isLoading: true,
        isAuthenticated: false,
        user: null,
        error: externalUser.error,
        getAccessToken: null
      }));
    } else if (externalUser?.error) {
      setAuth((prev) => ({
        ...prev,
        isLoading: false,
        isAuthenticated: false,
        user: null,
        error: externalUser.error,
        getAccessToken: null
      }));
    } else if (externalUser) {
      setAuth((prev) => ({
        ...prev,
        isLoading: false,
        isAuthenticated: true,
        user: externalUser.user,
        error: null,
        getAccessToken: async () => {
          const now = new Date();
          const minutesLeft =
            (externalUser.expiresAt.valueOf() - now.valueOf()) / 60000;
          if (minutesLeft > 5) return externalUser.accessToken;
          if (externalUser.assetReviewId) {
            const { data } = await signInExternalReviewer({
              email: externalUser.user.email,
              assetReviewId: externalUser.assetReviewId
            });
            setExternalUser((prev) => {
              if (!prev || prev.isLoading || prev.error) return prev;
              return {
                ...prev,
                accessToken: data.accessToken,
                expiresAt: moment(data.expiresAt).toDate()
              };
            });
            return data.accessToken;
          }
          if (externalUser.awId) {
            const { data } = await externalReviewerSignInAsExternalAwReviewer({
              email: externalUser.user.email,
              assetVersionId: externalUser.awId
            });
            setExternalUser((prev) => {
              if (!prev || prev.isLoading || prev.error) return prev;
              return {
                ...prev,
                accessToken: data.accessToken,
                expiresAt: moment(data.expiresAt).toDate()
              };
            });
            return data.accessToken;
          }
          return externalUser.accessToken;
        }
      }));
    } else {
      setAuth((prev) => ({
        ...prev,
        isLoading: false,
        isAuthenticated: false,
        user: null,
        error: null,
        getAccessToken: null
      }));
    }
  }, [
    auth0.isLoading,
    auth0.isAuthenticated,
    auth0.user,
    auth0.error,
    auth0.getAccessTokenSilently,
    auth0.logout,
    externalUser
  ]);

  const { loginForReview, loginForAW } = auth;
  useEffect(() => {
    const json = localStorage.getItem(EXTERNAL_REVIEWER_KEY);
    if (!json) return;
    try {
      const { email, assetReviewId, awId } = JSON.parse(json);
      if (email && assetReviewId) {
        setExternalUser((prev) => {
          if (!prev) return { isLoading: true, error: null };
          return { ...prev, isLoading: true };
        });
        loginForReview({ email, assetReviewId });
        return;
      }
      if (email && awId) {
        setExternalUser((prev) => {
          if (!prev) return { isLoading: true, error: null };
          return { ...prev, isLoading: true };
        });
        loginForAW({ email, assetVersionId: awId });
      }
    } catch {
      localStorage.removeItem(EXTERNAL_REVIEWER_KEY);
    }
  }, [loginForReview, loginForAW]);

  useEffect(() => {
    if (!externalUser || externalUser.isLoading || externalUser.error) return;
    localStorage.setItem(
      EXTERNAL_REVIEWER_KEY,
      JSON.stringify({
        email: externalUser.user.email,
        assetReviewId: externalUser.assetReviewId,
        awId: externalUser.awId
      })
    );
  }, [externalUser]);

  const mixpanel = useMixpanel();
  const prevAuth = useRef<typeof auth | undefined>(undefined);
  if (auth !== prevAuth.current) {
    prevAuth.current = auth;
    if (!auth.isAuthenticated) {
      datadogLogs.removeGlobalContextProperty('usr.id');
      datadogLogs.removeGlobalContextProperty('usr.email');
      datadogLogs.removeGlobalContextProperty('usr.name');
      MixpanelService.reset(mixpanel);
    } else {
      const user = nonNull(auth.user);
      datadogLogs.setGlobalContextProperty('usr.id', user.id);
      datadogLogs.setGlobalContextProperty('usr.email', user.email);
      datadogLogs.setGlobalContextProperty(
        'usr.name',
        `${user.firstName ?? ''} ${user.lastName ?? ''}`.trim()
      );
      datadogLogs.setGlobalContextProperty('usr.type', user.type);
      MixpanelService.identify(mixpanel, user);
      // eslint-disable-next-line no-multi-assign
      const hubspot = ((window as any)._hsq = (window as any)._hsq || []);
      hubspot.push(['identify', { email: user.email }]);
      hubspot.push(['trackPageView']);
    }
  }
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
}
