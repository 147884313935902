import { NODE_ENV } from '@config/constants';
import { configureStore } from '@reduxjs/toolkit';
import { rootReducer } from '@redux/reducers';
import { persistState as persistTasksState } from '@redux/persistors/tasks';
import { persistState as persistOrganizationsState } from '@redux/persistors/organization';

const DEV_MODE = NODE_ENV === 'development';

const store = configureStore({
  devTools: DEV_MODE,
  reducer: rootReducer
});

store.subscribe(() => {
  const state = store.getState();
  persistTasksState(state.tasks);
  persistOrganizationsState(state.organizations);
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
