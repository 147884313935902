import React, { createContext, ReactNode, useEffect, useState } from 'react';

export const CurrentTimeContext = createContext<number>(-1);

type currentTimeProviderProps = {
  children: ReactNode;
};

export function CurrentTimeProvider({ children }: currentTimeProviderProps) {
  const updateInterval = 1 * 60 * 1000;
  const [rerenderCounter, setRerenderCounter] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setRerenderCounter((prev) => prev + 1);
    }, updateInterval);
    return () => {
      clearInterval(intervalId);
    };
  }, [updateInterval]);
  return (
    <CurrentTimeContext.Provider value={rerenderCounter}>
      {children}
    </CurrentTimeContext.Provider>
  );
}
