import { WorkspaceItemDto } from '@api/Api';
import { useOrganization } from '@components/OrganizationBoundary';
import { nonNull } from '@helpers/non-null';
import { useTypedSelector } from '@hooks';
import { setWorkspaceCounters } from '@redux/actions/workspaceCountersAction';
import { WorkspaceActionTypes } from '@redux/types/workspaceType';
import { useCallback, useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';

function preferredWorkspaceKey(organizationId: string) {
  return `preferred-workspace-${organizationId}`;
}

let abortController = new AbortController();
let pendingWorkspaceId: string | undefined | false = false;

export function useCurrentWorkspace<TRequired extends boolean = true>(
  required: TRequired = true as TRequired
): [
  TRequired extends true ? WorkspaceItemDto : WorkspaceItemDto | undefined,
  (workspaceId: string | undefined) => Promise<void>
] {
  const dispatch = useDispatch();
  const { currentOrganization } = useOrganization(required);
  const workspaces = useTypedSelector((state) => state.workspace.workspaceList);
  const currentWorkspaceId = useTypedSelector(
    (state) => state.workspace.currentWorkspaceId
  );
  const organizationWorkspaces = useMemo(
    () =>
      workspaces.filter(
        (x) => x.organizationId === currentOrganization?.entity?.id
      ),
    [workspaces, currentOrganization]
  );
  const currentWorkspace = useMemo(
    () =>
      currentWorkspaceId
        ? organizationWorkspaces.find((x) => x.id === currentWorkspaceId)
        : undefined,
    [organizationWorkspaces, currentWorkspaceId]
  );
  const currentWorkspaceIdRef = useRef(currentWorkspace?.id);
  currentWorkspaceIdRef.current = currentWorkspace?.id;
  const currentOrganizationIdRef = useRef(currentOrganization?.entity?.id);
  currentOrganizationIdRef.current = currentOrganization?.entity?.id;
  const setWorkspace = useCallback(
    async (workspaceId: string | undefined) => {
      if (workspaceId === pendingWorkspaceId) return;
      if (
        pendingWorkspaceId === false &&
        workspaceId === currentWorkspaceIdRef.current
      ) {
        return;
      }
      abortController.abort('Cancelled');
      pendingWorkspaceId = workspaceId;
      abortController = new AbortController();
      const { signal } = abortController;
      try {
        if (workspaceId) {
          await setWorkspaceCounters(workspaceId)(dispatch);
          if (signal.aborted) throw signal.reason ?? new Error('Aborted');
          localStorage.setItem(
            preferredWorkspaceKey(currentOrganizationIdRef.current || ''),
            workspaceId
          );
        }
        dispatch({
          type: WorkspaceActionTypes.SET_CURRENT_WORKSPACE_ID,
          payload: workspaceId
        });
      } finally {
        pendingWorkspaceId = false;
      }
    },
    [dispatch]
  );
  return [
    required === true
      ? nonNull(currentWorkspace, 'currentWorkspace')
      : (currentWorkspace as any),
    setWorkspace
  ];
}

export function useDefaultWorkspace<TRequired extends boolean = true>(
  required: TRequired = true as TRequired
): TRequired extends true ? WorkspaceItemDto : WorkspaceItemDto | undefined {
  const { currentOrganization } = useOrganization();
  const workspaces = useTypedSelector((state) => state.workspace.workspaceList);
  const organizationWorkspaces = useMemo(
    () =>
      workspaces.filter(
        (x) => x.organizationId === currentOrganization.entity?.id
      ),
    [workspaces, currentOrganization.entity?.id]
  );
  const defaultWorkspace = useMemo(() => {
    const preferredWorkspaceId =
      currentOrganization.entity?.id &&
      localStorage.getItem(
        preferredWorkspaceKey(currentOrganization.entity.id)
      );
    return (
      organizationWorkspaces.find((x) => x.id === preferredWorkspaceId) ??
      organizationWorkspaces[0]
    );
  }, [organizationWorkspaces, currentOrganization.entity?.id]);
  return required === true
    ? nonNull(defaultWorkspace, 'defaultWorkspace')
    : (defaultWorkspace as any);
}
