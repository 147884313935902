import { TaskEventDto, TaskItemCampaignDto, TaskItemDto } from '@api/Api';
import { noAssignedBoardName, archivedBoardName } from '@config/tasks';
import { nonNull } from '@helpers/non-null';
import { UpdateTaskPayload } from '@redux/actions/tasks';
import {
  tasksInWorkspaceInitialState,
  tasksInCampaignInitialState,
  itemInitialState,
  tasksInAssetVersionInitialState,
  tasksInAssetInitialState
} from '@redux/initial-states/tasks';
import {
  TasksState,
  TasksInWorkspaceState,
  TasksInCampaignState,
  TaskItemState,
  TaskLocation,
  TaskListType,
  ListFetchType,
  ItemFetchType,
  NewRequest,
  TasksOrderBy,
  TasksInAssetVersionState,
  TasksInAssetState,
  TaskEventItemState
} from '@redux/types/tasks';
import { PayloadAction } from '@reduxjs/toolkit';
import produce, {
  Draft,
  produceWithPatches,
  original,
  applyPatches
} from 'immer';
import { nanoid } from 'nanoid';

export function getOrSetWsState(state: TasksState, workspaceId: string) {
  const wsState =
    state.byWorkspace[workspaceId] ?? tasksInWorkspaceInitialState();
  if (!state.byWorkspace[workspaceId]) {
    state.byWorkspace[workspaceId] = wsState;
  }
  return wsState as Draft<TasksInWorkspaceState>;
}

export function getOrSetCampaignState(state: TasksState, campaignId: string) {
  const campaignState =
    state.byCampaign[campaignId] ?? tasksInCampaignInitialState();
  if (!state.byCampaign[campaignId]) {
    state.byCampaign[campaignId] = campaignState;
  }
  return campaignState as Draft<TasksInCampaignState>;
}

export function getOrSetAssetVersionState(
  state: TasksState,
  assetVersionId: string
) {
  const avState =
    state.byAssetVersion[assetVersionId] ?? tasksInAssetVersionInitialState();
  if (!state.byAssetVersion[assetVersionId]) {
    state.byAssetVersion[assetVersionId] = avState;
  }
  return avState as Draft<TasksInAssetVersionState>;
}

export function getOrSetAssetState(state: TasksState, assetId: string) {
  const aState = state.byAsset[assetId] ?? tasksInAssetInitialState();
  if (!state.byAsset[assetId]) {
    state.byAsset[assetId] = aState;
  }
  return aState as Draft<TasksInAssetState>;
}

export function getOrSetTaskState(state: TasksState, taskId: string) {
  const taskState = state.items[taskId] ?? itemInitialState();
  if (!state.items[taskId]) {
    state.items[taskId] = taskState;
  }
  return taskState as Draft<TaskItemState>;
}

export function getNewTaskLocation(task: {
  workspaceId: string;
  campaign: { id: string };
  board: { id: string };
  asset?: { selectedVersion: { id: string } } | null;
  status: TaskItemDto['status'];
  archived: boolean;
  beforeArchivedBoard?: { id: string };
  deletedAt?: string | null;
  owner?: { user?: { me?: boolean | null } | null } | null;
  assignees: { user?: { me?: boolean | null } | null }[];
}): TaskLocation {
  const result: TaskLocation = {
    workspaceId: task.workspaceId,
    lists: {},
    campaigns: [
      {
        id: task.campaign.id,
        boardId: task.board.id
      }
    ],
    assetVersionIds: task.asset ? [task.asset.selectedVersion.id] : [],
    dashboard: false
  };
  if (task.archived) {
    result.campaigns = [
      {
        id: task.campaign.id,
        boardId: 'archived'
      }
    ];
  }
  if (task.deletedAt) {
    result.campaigns = [];
    result.assetVersionIds = [];
    result.lists.deleted = {
      flatList: true,
      campaignIds: [task.campaign.id],
      boardIds: [
        task.archived
          ? task.beforeArchivedBoard?.id ?? 'no_assigned'
          : task.board.id
      ]
    };
  }
  if (!task.archived && !task.deletedAt) {
    result.lists.all = {
      flatList: true,
      campaignIds: [task.campaign.id],
      boardIds: [task.board.id]
    };
    if (task.owner?.user?.me) {
      result.lists.my = {
        flatList: true,
        campaignIds: [task.campaign.id],
        boardIds: [task.board.id]
      };
    }
    if (task.assignees.some((x) => x.user?.me)) {
      result.lists.assigned = {
        flatList: true,
        campaignIds: [task.campaign.id],
        boardIds: [task.board.id]
      };
    }
    if (task.status !== 'done') {
      result.dashboard =
        task.owner?.user?.me || task.assignees.some((x) => x.user?.me);
    }
  }
  return result;
}

export function getCurrentTaskLocation(
  task: TaskItemDto,
  state: TasksState
): TaskLocation {
  const result: TaskLocation = {
    workspaceId: task.workspaceId,
    lists: {},
    campaigns: [],
    assetVersionIds: [],
    dashboard: false
  };
  const wsState = getOrSetWsState(state, task.workspaceId);
  Object.keys(wsState.lists).forEach((key) => {
    const listType = key as TaskListType;
    const list = wsState.lists[listType];
    result.lists[listType] = {
      flatList: list.taskIds.includes(task.id),
      boardIds: list.groups.board.ids.filter((id) =>
        list.groups.board.items[id]?.taskIds.includes(task.id)
      ),
      campaignIds: list.groups.campaign.ids.filter((id) =>
        list.groups.campaign.items[id]?.taskIds.includes(task.id)
      )
    };
  });
  const campaignState = state.byCampaign[task.campaign.id];
  const boardId = campaignState?.boards.ids.find((id) =>
    campaignState.boards.items[id]?.taskIds.includes(task.id)
  );
  if (boardId) {
    result.campaigns.push({ id: task.campaign.id, boardId });
  }
  result.assetVersionIds = Object.keys(state.byAssetVersion).filter((id) =>
    state.byAssetVersion[id]?.taskIds.includes(task.id)
  );
  result.dashboard = wsState.dashboard.taskIds.includes(task.id);
  const assumedLocation = getNewTaskLocation(task);
  Object.keys(assumedLocation.lists).forEach((key) => {
    const listType = key as TaskListType;
    const assumedList = nonNull(assumedLocation.lists[listType]);
    const list = result.lists[listType] ?? {
      flatList: false,
      boardIds: [],
      campaignIds: []
    };
    if (!result.lists[listType]) result.lists[listType] = list;
    list.flatList = list.flatList || assumedList.flatList;
    assumedList.campaignIds.forEach((id) => {
      if (!list.campaignIds.includes(id)) {
        list.campaignIds.push(id);
      }
    });
    assumedList.boardIds.forEach((id) => {
      if (!list.boardIds.includes(id)) {
        list.boardIds.push(id);
      }
    });
  });
  assumedLocation.campaigns.forEach(({ id, boardId }) => {
    if (!result.campaigns.some((x) => x.id === id && x.boardId === boardId)) {
      result.campaigns.push({ id, boardId });
    }
  });
  assumedLocation.assetVersionIds.forEach((id) => {
    if (!result.assetVersionIds.includes(id)) {
      result.assetVersionIds.push(id);
    }
  });
  if (assumedLocation.dashboard) result.dashboard = true;
  return result;
}

export function refreshCounts(locations: TaskLocation[], state: TasksState) {
  locations.forEach(
    ({ workspaceId, campaigns, assetVersionIds, dashboard, lists }) => {
      const wsState = getOrSetWsState(state, workspaceId);
      Object.keys(lists).forEach((key) => {
        const listType = key as TaskListType;
        const { flatList, campaignIds, boardIds } = lists[listType] ?? {
          flatList: false,
          campaignIds: [],
          boardIds: []
        };
        const list = wsState.lists[listType];
        if (flatList) newRequest(list.countFetch, 'refresh');
        boardIds.forEach((boardId) => {
          const boardGroupId = boardId
            ? list.groups.board.ids.find((id) => id.includes(boardId))
            : undefined;
          const boardGroup = boardGroupId
            ? list.groups.board.items[boardGroupId]
            : undefined;
          if (boardGroup) newRequest(boardGroup.countFetch, 'refresh');
        });
        campaignIds.forEach((campaignId) => {
          const campaignGroupId = campaignId
            ? list.groups.campaign.ids.find((id) => id.includes(campaignId))
            : undefined;
          const campaignGroup = campaignGroupId
            ? list.groups.campaign.items[campaignGroupId]
            : undefined;
          if (campaignGroup) newRequest(campaignGroup.countFetch, 'refresh');
        });
      });
      campaigns.forEach((campaign) => {
        const campaignState = getOrSetCampaignState(state, campaign.id);
        const board = campaignState.boards.items[campaign.boardId];
        if (board) newRequest(board.countFetch, 'refresh');
      });
      assetVersionIds.forEach((assetVersionId) => {
        const avState = getOrSetAssetVersionState(state, assetVersionId);
        newRequest(avState.countFetch, 'refresh');
      });
      if (dashboard) newRequest(wsState.dashboard.countFetch, 'refresh');
    }
  );
}

const fetchTypePriority = ['more', 'refresh', 'reload', 'initial'];
export function newRequest<T extends ListFetchType | ItemFetchType>(
  state: {
    initiallyLoaded: boolean;
    requestId: string | null;
    newRequest: NewRequest<T> | null;
  },
  fetchType: T,
  debounce?: number
) {
  fetchType =
    state.newRequest &&
    fetchTypePriority.indexOf(state.newRequest.fetchType) >
      fetchTypePriority.indexOf(fetchType)
      ? state.newRequest.fetchType
      : fetchType;
  state.requestId = null;
  state.newRequest = {
    key: nanoid(),
    fetchType,
    invokeTs: debounce ? Date.now() + debounce : undefined
  };
  state.initiallyLoaded = fetchType !== 'initial';
}

export function removeTaskFromLists(
  taskId: string,
  location: TaskLocation,
  state: TasksState
): TaskLocation {
  const res: TaskLocation = {
    workspaceId: location.workspaceId,
    lists: {},
    dashboard: false,
    campaigns: [],
    assetVersionIds: []
  };
  const wsState = getOrSetWsState(state, location.workspaceId);
  Object.keys(location.lists).forEach((key) => {
    const listType = key as TaskListType;
    const { flatList, campaignIds, boardIds } = location.lists[listType] ?? {};
    const list = wsState.lists[listType];
    if (flatList) {
      if (list.taskIds.includes(taskId)) {
        list.count = Math.max(list.count - 1, 0);
        list.taskIds = list.taskIds.filter((id) => id !== taskId);
      } else {
        res.lists[listType] = { flatList: true, campaignIds: [], boardIds: [] };
      }
    }
    boardIds?.forEach((boardId) => {
      const boardGroupId = boardId
        ? list.groups.board.ids.find((id) => id.includes(boardId))
        : undefined;
      const boardGroup = boardGroupId
        ? list.groups.board.items[boardGroupId]
        : undefined;
      if (boardGroup) {
        if (boardGroup.taskIds.includes(taskId)) {
          boardGroup.count = Math.max(boardGroup.count - 1, 0);
          boardGroup.taskIds = boardGroup.taskIds.filter((id) => id !== taskId);
        } else {
          if (!res.lists[listType])
            res.lists[listType] = {
              flatList: false,
              campaignIds: [],
              boardIds: []
            };
          nonNull(res.lists[listType]).boardIds.push(boardId);
        }
      }
    });
    campaignIds?.forEach((campaignId) => {
      const campaignGroupId = campaignId
        ? list.groups.campaign.ids.find((id) => id.includes(campaignId))
        : undefined;
      const campaignGroup = campaignGroupId
        ? list.groups.campaign.items[campaignGroupId]
        : undefined;
      if (campaignGroup) {
        if (campaignGroup.taskIds.includes(taskId)) {
          campaignGroup.count = Math.max(campaignGroup.count - 1, 0);
          campaignGroup.taskIds = campaignGroup.taskIds.filter(
            (id) => id !== taskId
          );
        } else {
          if (!res.lists[listType])
            res.lists[listType] = {
              flatList: false,
              campaignIds: [],
              boardIds: []
            };
          nonNull(res.lists[listType]).campaignIds.push(campaignId);
        }
      }
    });
  });
  location.campaigns.forEach((campaign) => {
    const campaignState = getOrSetCampaignState(state, campaign.id);
    const board = campaignState.boards.items[campaign.boardId];
    if (board) {
      if (board.taskIds.includes(taskId)) {
        campaignState.count = Math.max(campaignState.count - 1, 0);
        board.count = Math.max(board.count - 1, 0);
        board.taskIds = board.taskIds.filter((id) => id !== taskId);
      } else {
        res.campaigns.push(campaign);
      }
    }
  });
  location.assetVersionIds.forEach((assetVersionId) => {
    const avState = getOrSetAssetVersionState(state, assetVersionId);
    if (avState.taskIds.includes(taskId)) {
      avState.count = Math.max(avState.count - 1, 0);
      avState.taskIds = avState.taskIds.filter((id) => id !== taskId);
    } else {
      res.assetVersionIds.push(assetVersionId);
    }
  });
  if (location.dashboard) {
    if (wsState.dashboard.taskIds.includes(taskId)) {
      wsState.dashboard.count = Math.max(wsState.dashboard.count - 1, 0);
      wsState.dashboard.taskIds = wsState.dashboard.taskIds.filter(
        (id) => id !== taskId
      );
    } else {
      res.dashboard = true;
    }
  }
  return res;
}

export function getDefaultTaskPosition(orderBy: TasksOrderBy) {
  const startOfListOrders: TasksOrderBy[] = [
    'createdAt:DESC',
    'deletedAt:DESC',
    'lastEventTime:DESC',
    'order:DESC',
    'status:ASC',
    'status:DESC'
  ];
  return startOfListOrders.includes(orderBy) ? 'start-of-list' : 'end-of-list';
}

export function addTaskToLists(
  taskId: string,
  location: TaskLocation,
  state: TasksState,
  defaultTaskPosition?: 'start-of-list' | 'end-of-list'
) {
  const wsState = getOrSetWsState(state, location.workspaceId);
  const { orderBy } = wsState.listsShared;
  const taskPosition = defaultTaskPosition ?? getDefaultTaskPosition(orderBy);
  Object.keys(location.lists).forEach((key) => {
    const listType = key as TaskListType;
    const { flatList, campaignIds, boardIds } = location.lists[listType] ?? {};
    const list = wsState.lists[listType];
    if (flatList && !list.taskIds.includes(taskId)) {
      const beforeIndex =
        taskPosition === 'start-of-list' ? 0 : list.taskIds.length;
      list.taskIds.splice(beforeIndex, 0, taskId);
    }
    boardIds?.forEach((boardId) => {
      const boardGroupId = boardId
        ? list.groups.board.ids.find((id) => id.includes(boardId))
        : undefined;
      const boardGroup = boardGroupId
        ? list.groups.board.items[boardGroupId]
        : undefined;
      if (boardGroup && !boardGroup.taskIds.includes(taskId)) {
        const beforeIndex =
          taskPosition === 'start-of-list' ? 0 : boardGroup.taskIds.length;
        boardGroup.taskIds.splice(beforeIndex, 0, taskId);
      }
    });
    campaignIds?.forEach((campaignId) => {
      const campaignGroupId = campaignId
        ? list.groups.campaign.ids.find((id) => id.includes(campaignId))
        : undefined;
      const campaignGroup = campaignGroupId
        ? list.groups.campaign.items[campaignGroupId]
        : undefined;
      if (campaignGroup && !campaignGroup.taskIds.includes(taskId)) {
        const beforeIndex =
          taskPosition === 'start-of-list' ? 0 : campaignGroup.taskIds.length;
        campaignGroup.taskIds.splice(beforeIndex, 0, taskId);
      }
    });
  });
  location.campaigns.forEach((campaign) => {
    const campaignState = getOrSetCampaignState(state, campaign.id);
    const board = campaignState.boards.items[campaign.boardId];
    if (board && !board.taskIds.includes(taskId)) {
      const { orderBy } = campaignState;
      const taskPosition =
        defaultTaskPosition ?? getDefaultTaskPosition(orderBy);
      const beforeIndex =
        taskPosition === 'start-of-list' ? 0 : board.taskIds.length;
      board.taskIds.splice(beforeIndex, 0, taskId);
    }
  });
  location.assetVersionIds.forEach((assetVersionId) => {
    const avState = getOrSetAssetVersionState(state, assetVersionId);
    if (!avState.taskIds.includes(taskId)) {
      const { orderBy } = avState;
      const taskPosition =
        defaultTaskPosition ?? getDefaultTaskPosition(orderBy);
      const beforeIndex =
        taskPosition === 'start-of-list' ? 0 : avState.taskIds.length;
      avState.taskIds.splice(beforeIndex, 0, taskId);
    }
  });
  if (location.dashboard) {
    if (!wsState.dashboard.taskIds.includes(taskId)) {
      wsState.dashboard.taskIds.unshift(taskId);
    }
  }
}

export function moveTaskInBoard(
  taskId: string,
  beforeTaskId: string | null | undefined,
  state: TasksState
) {
  const taskState = state.items[taskId];
  if (!taskState || taskState.permanentlyDeleted) return;
  const task = taskState.entity;
  if (!task) return;
  const campaignState = getOrSetCampaignState(state, task.campaign.id);
  const { orderBy } = campaignState;
  const defaultTaskPosition = getDefaultTaskPosition(orderBy);
  const board = campaignState.boards.items[task.board.id];
  if (board) {
    board.taskIds = board.taskIds.filter((id) => id !== task.id);
    let beforeIndex = beforeTaskId
      ? board.taskIds.findIndex((x) => x === beforeTaskId)
      : -1;
    if (beforeIndex === -1) {
      beforeIndex =
        defaultTaskPosition === 'start-of-list' ? 0 : board.taskIds.length;
    }
    board.taskIds.splice(beforeIndex, 0, taskId);
  }
}

export function handleUpdatedTask(
  prevTask: TaskItemDto,
  newTask: TaskItemDto,
  state: TasksState
) {
  const taskState = getOrSetTaskState(state, prevTask.id);
  if (prevTask.lastEventTime !== newTask.lastEventTime) {
    newRequest(taskState.events.fetch, 'refresh');
  }
  if (prevTask.asset?.id !== newTask.asset?.id) {
    taskState.potentialAssignees.list = [];
    newRequest(taskState.potentialAssignees.fetch, 'initial');
    taskState.descriptionMentions.list = [];
    newRequest(taskState.descriptionMentions.fetch, 'initial');
    taskState.commentMentions.list = [];
    newRequest(taskState.commentMentions.fetch, 'initial');
  }
}

export function withUndo(
  state: TasksState,
  requestId: string,
  mutations: (state: TasksState) => void
) {
  const [nextState, _, inversePatches] = produceWithPatches(
    nonNull(original(state)),
    (draft) => {
      mutations(draft);
    }
  );
  return produce(nextState, (draft) => {
    draft.undo[requestId] = inversePatches;
  });
}

export function undo(
  state: TasksState,
  action: PayloadAction<any, any, { requestId: string }, never>
) {
  const { requestId } = action.meta;
  const patches = state.undo[requestId];
  delete state.undo[requestId];
  return patches ? applyPatches(state, patches) : state;
}

export function cancelUndo(
  state: TasksState,
  action: PayloadAction<
    any,
    any,
    { requestId: string; requestStatus: 'fulfilled' },
    never
  >
) {
  const { requestId } = action.meta;
  delete state.undo[requestId];
}

export function replaceBoardNames(task: TaskItemDto) {
  return {
    ...task,
    board: {
      ...task.board,
      name:
        task.board.id === 'no_assigned'
          ? noAssignedBoardName
          : task.board.id === 'archived'
          ? archivedBoardName
          : task.board.name
    }
  };
}

export function setTaskEntitiesFromList(
  list: { edges: { node: TaskItemDto }[] },
  state: TasksState
) {
  list.edges.forEach((x) => {
    const task = replaceBoardNames(x.node);
    const taskState = getOrSetTaskState(state, task.id);
    if (taskState.entity) {
      handleUpdatedTask(taskState.entity, task, state);
      const locations = handleTaskLocationChange(taskState.entity, task, state);
      refreshCounts(locations, state);
    }
    taskState.entity = task;
    taskState.notFound = false;
    taskState.permanentlyDeleted = false;
    taskState.fetch.initiallyLoaded = true;
    taskState.fetch.newRequest = null;
  });
}

export function handleTaskLocationChange(
  prevTask: TaskItemDto,
  newTask: TaskItemDto,
  state: TasksState,
  ignoreRemove?: TaskLocation
) {
  const prevLocation = getCurrentTaskLocation(prevTask, state);
  const newLocation = getNewTaskLocation(newTask);
  const toRemove: TaskLocation = {
    workspaceId: prevLocation.workspaceId,
    lists: Object.keys(prevLocation.lists).reduce((res, key) => {
      const listType = key as TaskListType;
      const prevListLocation = prevLocation.lists[listType] ?? {
        flatList: false,
        campaignIds: [],
        boardIds: []
      };
      const newListLocation = newLocation.lists[listType] ?? {
        flatList: false,
        campaignIds: [],
        boardIds: []
      };
      res[listType] = {
        flatList:
          prevListLocation.flatList !== newListLocation.flatList
            ? ignoreRemove?.lists[listType]?.flatList !==
              prevListLocation.flatList
              ? prevListLocation.flatList
              : false
            : false,
        campaignIds: prevListLocation.campaignIds.filter(
          (id) =>
            !ignoreRemove?.lists[listType]?.campaignIds.includes(id) &&
            !newListLocation.campaignIds.includes(id)
        ),
        boardIds: prevListLocation.boardIds.filter((id) => {
          const ids = id.split(',');
          return ids.every(
            (id) =>
              !ignoreRemove?.lists[listType]?.boardIds.includes(id) &&
              !newListLocation.boardIds.includes(id)
          );
        })
      };
      return res;
    }, {} as TaskLocation['lists']),
    campaigns: prevLocation.campaigns.filter(
      ({ id, boardId }) =>
        !ignoreRemove?.campaigns.some(
          (x) => x.id === id && x.boardId === boardId
        ) &&
        !newLocation.campaigns.some((x) => x.id === id && x.boardId === boardId)
    ),
    assetVersionIds: prevLocation.assetVersionIds.filter(
      (id) =>
        !ignoreRemove?.assetVersionIds.includes(id) &&
        !newLocation.assetVersionIds.includes(id)
    ),
    dashboard:
      prevLocation.dashboard !== newLocation.dashboard
        ? ignoreRemove?.dashboard !== prevLocation.dashboard
          ? prevLocation.dashboard
          : false
        : false
  };
  const toAdd: TaskLocation = {
    workspaceId: newLocation.workspaceId,
    lists: Object.keys(newLocation.lists).reduce((res, key) => {
      const listType = key as TaskListType;
      const prevListLocation = prevLocation.lists[listType] ?? {
        flatList: false,
        campaignIds: [],
        boardIds: []
      };
      const newListLocation = newLocation.lists[listType] ?? {
        flatList: false,
        campaignIds: [],
        boardIds: []
      };
      res[listType] = {
        flatList:
          prevListLocation.flatList !== newListLocation.flatList
            ? newListLocation.flatList
            : false,
        campaignIds: newListLocation.campaignIds.filter(
          (id) => !prevListLocation.campaignIds.includes(id)
        ),
        boardIds: newListLocation.boardIds.filter(
          (id) => !prevListLocation.boardIds.some((ids) => ids.includes(id))
        )
      };
      return res;
    }, {} as TaskLocation['lists']),
    campaigns: newLocation.campaigns.filter(
      ({ id, boardId }) =>
        !prevLocation.campaigns.some(
          (x) => x.id === id && x.boardId === boardId
        )
    ),
    assetVersionIds: newLocation.assetVersionIds.filter(
      (id) => !prevLocation.assetVersionIds.includes(id)
    ),
    dashboard:
      prevLocation.dashboard !== newLocation.dashboard
        ? newLocation.dashboard
        : false
  };
  removeTaskFromLists(prevTask.id, toRemove, state);
  addTaskToLists(newTask.id, toAdd, state);
  return [toAdd];
}

export function handleDeletedTask(
  taskId: string,
  permanent: boolean,
  state: TasksState
): TaskLocation[] {
  const taskState = state.items[taskId];
  if (!taskState || taskState.permanentlyDeleted) return [];
  const { entity: task } = taskState;
  if (!task) return [];
  const toRemove = getCurrentTaskLocation(task, state);
  removeTaskFromLists(task.id, toRemove, state);
  if (!task.deletedAt) {
    task.deletedAt = new Date().toISOString();
  }
  task.assetVersionComment = null;
  taskState.permanentlyDeleted = permanent;
  if (!permanent) {
    const toAdd = getNewTaskLocation(task);
    addTaskToLists(task.id, toAdd, state);
    return [toAdd];
  }
  return [];
}

export function handleRestoredTask(taskId: string, state: TasksState) {
  const taskState = state.items[taskId];
  if (!taskState || taskState.permanentlyDeleted) return [];
  const { entity: task } = taskState;
  if (!task || !task.deletedAt) return [];
  const toRemove = getCurrentTaskLocation(task, state);
  removeTaskFromLists(task.id, toRemove, state);
  task.deletedAt = null;
  const toAdd = getNewTaskLocation(task);
  addTaskToLists(task.id, toAdd, state);
  return [toAdd];
}

export function applyTaskUpdate(
  task: Omit<TaskItemDto, 'campaign'> & {
    campaign: TaskItemCampaignDto | null;
  },
  update: Omit<UpdateTaskPayload, 'mixpanel' | 'userId' | 'planType'> & {
    campaign?: TaskItemCampaignDto;
  }
) {
  task.name = update.name ?? task.name;
  task.description = update.description ?? task.description;
  if (update.campaign) {
    task.campaign = update.campaign;
    task.asset = null;
    task.board = {
      id: 'no_assigned',
      name: noAssignedBoardName
    };
  }
  if (update.descriptionMentions?.add) {
    const toAdd = update.descriptionMentions.add;
    task.descriptionMentions = [...task.descriptionMentions, ...toAdd];
  }
  if (update.descriptionMentions?.remove) {
    const toRemove = update.descriptionMentions.remove;
    task.descriptionMentions = task.descriptionMentions.filter(
      (mention) => !toRemove.includes(mention.id)
    );
  }
  task.priority =
    update.priority !== undefined ? update.priority : task.priority;
  task.status = update.status ?? task.status;
  task.dueDate = update.dueDate !== undefined ? update.dueDate : task.dueDate;
  if (update.asset !== undefined) {
    if (task.asset?.selectedVersion.id !== update.asset?.selectedVersion.id) {
      task.assetVersionComment = null;
    }
    task.asset = update.asset;
    task.archived = false;
    if (task.board.id === 'archived') {
      const newBoardId = task.beforeArchivedBoard?.id ?? 'no_assigned';
      task.board.id = newBoardId;
      task.board.name =
        newBoardId === 'no_assigned'
          ? noAssignedBoardName
          : nonNull(task.beforeArchivedBoard?.name);
    }
  }
  if (update.assignees?.add) {
    const toAdd = update.assignees.add;
    task.assignees = [...task.assignees, ...toAdd];
  }
  if (update.assignees?.remove) {
    const toRemove = update.assignees.remove;
    task.assignees = task.assignees.filter(
      (mention) => !toRemove.includes(mention.id)
    );
  }
  if (update.watchers?.add) {
    const toAdd = update.watchers.add;
    task.watchers = [...task.watchers, ...toAdd];
  }
  if (update.watchers?.remove) {
    const toRemove = update.watchers.remove;
    task.watchers = task.watchers.filter(
      (mention) => !toRemove.includes(mention.id)
    );
  }
  if (update.customFields?.add) {
    const toAdd = update.customFields.add;
    toAdd.forEach(({ id, name, type, value }) => {
      const customField = task.customFields.find((x) => x.id === id);
      if (!customField) {
        task.customFields.push({
          id,
          name,
          type,
          value: type === 'tags' ? [value] : value
        });
      } else {
        if (type !== 'tags') {
          customField.value = value;
        } else {
          customField.value = Array.isArray(customField.value)
            ? customField.value
            : [customField.value];
          if (!customField.value.includes(value)) {
            customField.value.push(value);
          }
        }
      }
    });
  }
  if (update.customFields?.remove) {
    const toRemove = update.customFields.remove;
    toRemove.forEach(({ id, value }) => {
      const customField = task.customFields.find((x) => x.id === id);
      if (customField) {
        if (value === undefined || customField.type !== 'tags') {
          task.customFields = task.customFields.filter((x) => x.id !== id);
        } else {
          customField.value = Array.isArray(customField.value)
            ? customField.value
            : [customField.value];
          customField.value = customField.value.filter((x) => x !== value);
        }
      }
    });
  }
  if (update.board !== undefined) {
    task.board.id = update.board.id;
    task.board.name = update.board.name;
    task.beforeArchivedBoard = task.board;
  }
}

export function applyTaskEventState(event: TaskEventDto) {
  return {
    isExpanded: false,
    isGrouped: false,
    it: event,
    id: event.id
  };
}

export function formatTaskEventsList(events: TaskEventItemState[]) {
  const result: TaskEventDto[] = [];
  events.forEach((it) => {
    if (it.isGrouped) result.push(...(it.it as TaskEventDto[]));
    else result.push(it.it as TaskEventDto);
  });
  return result;
}

export function formatTaskEvents(events: TaskEventDto[]) {
  let result = [];

  const eventsLength = events.length;
  const hasComment = events.some((it) => it.payload.type === 'comment');
  const hasMoreThanThreeEvents = events.length > 3;

  for (let i = 0; i < eventsLength; i++) {
    if (events[i].payload.type === 'comment') {
      result.push(events[i]);
    } else {
      const recipe = [];
      for (let j = i; j < eventsLength; j++) {
        if (events[j].payload.type === 'comment') break;

        const hasEvent = result.flat(1).some((it) => it.id === events[j].id);
        if (!hasEvent) {
          if (hasComment || hasMoreThanThreeEvents) recipe.push(events[j]);
          else result.push(events[j]);
        }
      }
      if (recipe.length > 0) result.push(recipe);
    }
  }
  result = result.map((it) => {
    const isArray = Array.isArray(it);
    const id = isArray ? it[0].id : it.id;
    return { id, it, isGrouped: !!isArray, isExpanded: false };
  });

  return result;
}
