import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import {
  AUTH0_CLIENT_ID,
  AUTH0_DOMAIN,
  AUTH0_AUDIENCE
} from '@config/constants';

function Auth0ProviderWithHistory({ children }) {
  const navigate = useNavigate();
  const onRedirectCallback = (appState) => {
    navigate(appState?.returnTo || window.location.pathname);
    sessionStorage.setItem('send-login-event', JSON.stringify(appState));
  };
  return (
    <Auth0Provider
      domain={AUTH0_DOMAIN}
      clientId={AUTH0_CLIENT_ID}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
      audience={AUTH0_AUDIENCE}
    >
      {children}
    </Auth0Provider>
  );
}

export default Auth0ProviderWithHistory;
