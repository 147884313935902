import { useContext } from 'react';
import { AuthContext } from '@context/AuthContext';
import { IAuthContext } from '@context/AuthContext/types';

export function useAuth<
  TRequireAuth extends boolean = false,
  TRequiredUserType extends
    | 'external-reviewer'
    | 'internal'
    | undefined = undefined
>(
  requireAuth: TRequireAuth = false as any,
  requiredUserType: TRequiredUserType = undefined as any
) {
  const authContext = useContext(AuthContext);
  if (!authContext) {
    throw new Error('Forgot to use AuthProvider?');
  }
  if (
    requiredUserType !== undefined &&
    authContext.user &&
    authContext.user.type !== requiredUserType
  ) {
    if (requireAuth) {
      throw new Error('Auth required but user is not authenticated');
    }
    return {
      ...authContext,
      isLoading: false,
      isAuthenticated: false,
      user: null,
      error: null,
      getAccessToken: null
    } as IAuthContext<
      TRequireAuth extends true ? true : boolean,
      TRequiredUserType extends undefined
        ? 'external-reviewer' | 'internal'
        : TRequiredUserType
    >;
  }
  if (requireAuth && !authContext.isAuthenticated) {
    throw new Error('Auth required but user is not authenticated');
  }
  return authContext as IAuthContext<
    TRequireAuth extends true ? true : boolean,
    TRequiredUserType extends undefined
      ? 'external-reviewer' | 'internal'
      : TRequiredUserType
  >;
}
