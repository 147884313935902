import { OrganizationItemDto } from '@api/Api';
import { nonNull } from '@helpers/non-null';
import {
  itemInitialState,
  listInitialState
} from '@redux/initial-states/organization';
import {
  IOrganizationItemState,
  IOrganizationListState,
  IOrganizationState,
  ItemFetchType,
  ListFetchType,
  NewRequest
} from '@redux/types/organizations';
import { PayloadAction } from '@reduxjs/toolkit';
import produce, {
  applyPatches,
  Draft,
  original,
  produceWithPatches
} from 'immer';
import { nanoid } from 'nanoid';

export function getOrSetOrgsState(state: IOrganizationState) {
  const orgState = state.list ?? listInitialState();

  return orgState as Draft<IOrganizationListState>;
}

export function getOrSetOrgState(
  state: IOrganizationState,
  organizationId: string
) {
  const organizationState = state.items[organizationId] ?? itemInitialState();
  if (!state.items[organizationId]) {
    state.items[organizationId] = organizationState;
  }
  return organizationState as Draft<IOrganizationItemState>;
}

export function setOrganizationEntitiesFromList(
  list: OrganizationItemDto[],
  state: IOrganizationState
) {
  list.forEach((it) => {
    const organizationState = getOrSetOrgState(state, it.id);
    organizationState.entity = it;
    organizationState.fetch.initiallyLoaded = true;
    organizationState.fetch.newRequest = null;
  });
  Object.values(state.items).forEach((it) => {
    if (it.entity && state.list.organizationIds.indexOf(it.entity?.id) <= -1)
      delete state.items[it.entity.id];
  });
}

const fetchTypePriority = ['more', 'refresh', 'reload', 'initial'];
export function newRequest<T extends ListFetchType | ItemFetchType>(
  state: {
    initiallyLoaded: boolean;
    requestId: string | null;
    newRequest: NewRequest<T> | null;
  },
  fetchType: T,
  debounce?: number
) {
  fetchType =
    state.newRequest &&
    fetchTypePriority.indexOf(state.newRequest.fetchType) >
      fetchTypePriority.indexOf(fetchType)
      ? state.newRequest.fetchType
      : fetchType;
  state.requestId = null;
  state.newRequest = {
    key: nanoid(),
    fetchType,
    invokeTs: debounce ? Date.now() + debounce : undefined
  };
  state.initiallyLoaded = fetchType !== 'initial';
}

export function withUndo(
  state: IOrganizationState,
  requestId: string,
  mutations: (state: IOrganizationState) => void
) {
  const [nextState, _, inversePatches] = produceWithPatches(
    nonNull(original(state)),
    (draft) => {
      mutations(draft);
    }
  );
  return produce(nextState, (draft) => {
    draft.undo[requestId] = inversePatches;
  });
}

export function undo(
  state: IOrganizationState,
  action: PayloadAction<any, any, { requestId: string }, never>
) {
  const { requestId } = action.meta;
  const patches = state.undo[requestId];
  delete state.undo[requestId];
  return patches ? applyPatches(state, patches) : state;
}

export function cancelUndo(
  state: IOrganizationState,
  action: PayloadAction<
    any,
    any,
    { requestId: string; requestStatus: 'fulfilled' },
    never
  >
) {
  const { requestId } = action.meta;
  delete state.undo[requestId];
}
