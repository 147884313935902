import {
  IModalState,
  IModalAction,
  ModalActionTypes
} from '@redux/types/modalType';
import { nanoid } from 'nanoid';

const INITIAL_STATE: IModalState = {
  workspaceCreationModal: null,
  workspaceInvitationModal: null,
  workspaceMemberGroupInviteModal: null,
  workspaceMemberGroupCreateModal: null,
  workspaceOrganizationDeletionModal: null,
  workspaceMembersModal: null,
  workspaceOrganizationLeaveModal: null,
  organizationRenameModal: null,
  taskModal: null,
  approvalModal: null,
  uploadAssetModal: null,
  activeModal: null,
  shareAssetModal: null,
  billingModal: null,
  newAssetWithWorkflow: null,
  agencyModal: null,
  entityRenameModal: null,
  entityDeletionModal: null,
  singInExternalReviewerModal: null,
  campaignInfoModal: null,
  campaignFolderCreationModal: null,
  campaignCreatedModal: null,
  campaignInvitationModal: null,
  moveAssetToPrivateFolderModal: null,
  manageAssetVersionsModal: null,
  changePlanDetailsModal: null,
  cancelSubscriptionModal: null,
  reachedEntityLimitModal: null,
  assetStackErrorModal: null,
  manageFolderAccessModal: null,
  changeFolderAccessModal: null,
  combineAssetsModal: null,
  copyAssetVersionModal: null
};

const modalReducer = (
  state = INITIAL_STATE,
  action: IModalAction
): IModalState => {
  switch (action.type) {
    case ModalActionTypes.OPEN_MODAL: {
      return {
        ...state,
        activeModal: {
          modalName: action.payload.modalName,
          payload: action.payload
        }
      };
    }
    case ModalActionTypes.CLOSE_MODAL: {
      return {
        ...state,
        activeModal: null
      };
    }
    case ModalActionTypes.TOGGLE_WORKSPACE_CREATION_MODAL: {
      return {
        ...state,
        workspaceCreationModal: action.payload
      };
    }
    case ModalActionTypes.TOGGLE_WORKSPACE_INVITATION_MODAL: {
      return {
        ...state,
        workspaceInvitationModal: action.payload
      };
    }
    case ModalActionTypes.TOGGLE_WORKSPACE_MEMBER_GROUP_INVITE_MODAL: {
      return {
        ...state,
        workspaceMemberGroupInviteModal: action.payload
          ? { ...action.payload, key: nanoid() }
          : state.workspaceMemberGroupInviteModal
          ? { ...state.workspaceMemberGroupInviteModal, visible: false }
          : null
      };
    }
    case ModalActionTypes.TOGGLE_WORKSPACE_MEMBER_GROUP_CREATE_MODAL: {
      return {
        ...state,
        workspaceMemberGroupCreateModal: action.payload
          ? { ...action.payload, key: nanoid() }
          : state.workspaceMemberGroupCreateModal
          ? { ...state.workspaceMemberGroupCreateModal, visible: false }
          : null
      };
    }
    case ModalActionTypes.TOGGLE_WORKSPACE_MEMBER_GROUP_MODAL: {
      return {
        ...state,
        workspaceMemberGroupModal: action.payload
          ? { ...action.payload, key: nanoid() }
          : state.workspaceMemberGroupModal
          ? { ...state.workspaceMemberGroupModal, visible: false }
          : null
      };
    }
    case ModalActionTypes.TOGGLE_BILLING_MODAL: {
      return {
        ...state,
        billingModal: action.payload
      };
    }
    case ModalActionTypes.TOGGLE_WORKSPACE_ORGANIZATION_DELETION_MODAL: {
      return {
        ...state,
        workspaceOrganizationDeletionModal: action.payload
      };
    }
    case ModalActionTypes.TOGGLE_WORKSPACE_MEMBERS_MODAL: {
      return {
        ...state,
        workspaceMembersModal: action.payload
      };
    }
    case ModalActionTypes.TOGGLE_APPROVAL_MODAL: {
      return {
        ...state,
        approvalModal: action.payload
          ? { key: nanoid(), ...action.payload }
          : state.approvalModal
          ? { ...state.approvalModal, visible: false }
          : null
      };
    }
    case ModalActionTypes.TOGGLE_WORKSPACE_ORGANIZATION_LEAVE_MODAL: {
      return {
        ...state,
        workspaceOrganizationLeaveModal: action.payload
      };
    }
    case ModalActionTypes.TOGGLE_TASK_MODAL: {
      return {
        ...state,
        taskModal: action.payload
      };
    }
    case ModalActionTypes.TOGGLE_UPLOAD_ASSET_MODAL: {
      return {
        ...state,
        uploadAssetModal: action.payload
          ? { ...action.payload, key: nanoid() }
          : state.uploadAssetModal
          ? { ...state.uploadAssetModal, visible: false }
          : null
      };
    }
    case ModalActionTypes.TOGGLE_SHARE_ASSET_MODAL: {
      return {
        ...state,
        shareAssetModal: action.payload
      };
    }
    case ModalActionTypes.TOGGLE_NEW_ASSET_WITH_WORKFLOW_MODAL: {
      return {
        ...state,
        newAssetWithWorkflow: action.payload
      };
    }
    case ModalActionTypes.TOGGLE_RENAME_ENTITY_MODAL: {
      return {
        ...state,
        entityRenameModal: action.payload
      };
    }
    case ModalActionTypes.TOGGLE_SIGN_IN_EXTERNAL_REVIEWER_MODAL: {
      return {
        ...state,
        singInExternalReviewerModal: action.payload
      };
    }
    case ModalActionTypes.TOGGLE_CAMPAIGN_INFO_MODAL: {
      return {
        ...state,
        campaignInfoModal: action.payload
      };
    }
    case ModalActionTypes.TOGGLE_CAMPAIGN_FOLDER_CREATION_MODAL: {
      return {
        ...state,
        campaignFolderCreationModal: action.payload
      };
    }
    case ModalActionTypes.TOGGLE_ORGANIZATION_RENAME_MODAL: {
      return {
        ...state,
        organizationRenameModal: action.payload
      };
    }
    case ModalActionTypes.TOGGLE_CAMPAIGN_CREATED_MODAL: {
      return {
        ...state,
        campaignCreatedModal: action.payload
      };
    }
    case ModalActionTypes.TOGGLE_DELETE_ASSET_MODAL: {
      return {
        ...state,
        entityDeletionModal: action.payload
      };
    }
    case ModalActionTypes.TOGGLE_INVITE_TO_CAMPAIGN_MODAL: {
      return {
        ...state,
        campaignInvitationModal: action.payload
      };
    }
    case ModalActionTypes.TOGGLE_MOVE_ASSET_TO_PRIVATE_FOLDER_MODAL: {
      return {
        ...state,
        moveAssetToPrivateFolderModal: action.payload
      };
    }
    case ModalActionTypes.TOGGLE_MANAGE_ASSET_VERSIONS_MODAL: {
      return {
        ...state,
        manageAssetVersionsModal: action.payload
      };
    }
    case ModalActionTypes.TOGGLE_CHANGE_PLAN_DETAILS_MODAL: {
      return {
        ...state,
        changePlanDetailsModal: action.payload
      };
    }
    case ModalActionTypes.TOGGLE_CANCEL_SUBSCRIPTION_MODAL: {
      return {
        ...state,
        cancelSubscriptionModal: action.payload
      };
    }
    case ModalActionTypes.TOGGLE_REACHED_ENTITY_LIMIT__MODAL: {
      return {
        ...state,
        reachedEntityLimitModal: action.payload
      };
    }
    case ModalActionTypes.TOGGLE_ASSET_STACK_ERROR_MODAL: {
      return {
        ...state,
        assetStackErrorModal: action.payload
      };
    }
    case ModalActionTypes.TOGGLE_MANAGE_FOLDER_ACCESS_MODAL: {
      const prevVisible = !!state.manageFolderAccessModal?.visible;
      const nextVisible = !!action.payload?.visible;
      if (prevVisible && !nextVisible) {
        return {
          ...state,
          manageFolderAccessModal:
            state.manageFolderAccessModal == null
              ? null
              : { ...state.manageFolderAccessModal, visible: false }
        };
      }
      return {
        ...state,
        manageFolderAccessModal:
          action.payload === null ? null : { ...action.payload, key: nanoid() }
      };
    }
    case ModalActionTypes.TOGGLE_CHANGE_FOLDER_ACCESS_MODAL: {
      return {
        ...state,
        changeFolderAccessModal: action.payload
      };
    }
    case ModalActionTypes.TOGGLE_COMBINE_ASSETS_MODAL: {
      return {
        ...state,
        combineAssetsModal:
          action.payload ??
          (state.combineAssetsModal
            ? { ...state.combineAssetsModal, visible: false }
            : null)
      };
    }
    case ModalActionTypes.TOGGLE_COPY_ASSET_VERSION_MODAL: {
      return {
        ...state,
        copyAssetVersionModal: action.payload
          ? action.payload
          : state.copyAssetVersionModal
          ? { ...state.copyAssetVersionModal, visible: false }
          : null
      };
    }
    default: {
      return state;
    }
  }
};

export default modalReducer;
