import { createSelector } from '@reduxjs/toolkit';
import {
  itemInitialState,
  tasksInAssetInitialState,
  tasksInAssetVersionInitialState,
  tasksInCampaignInitialState,
  tasksInWorkspaceInitialState
} from '@redux/initial-states/tasks';
import { RootState } from '@redux/store';
import { TaskListType, TasksGroupBy } from '@redux/types/tasks';
import { nonNull } from '@helpers/non-null';
import { isDateSameOrBefore, isDueSoon } from '@helpers/compareDueDate';

export const workspaceStateSelector = createSelector(
  [
    (state: RootState) => state.tasks.byWorkspace,
    (_, { workspaceId }: { workspaceId: string }) => workspaceId
  ],
  (byWorkspace, workspaceId) =>
    byWorkspace[workspaceId] ?? tasksInWorkspaceInitialState()
);

export const filterCampaignsSelector = createSelector(
  [workspaceStateSelector],
  (wsState) => wsState.listsShared.campaigns
);

export const filterAssigneesSelector = createSelector(
  [workspaceStateSelector],
  (wsState) => wsState.listsShared.potentialAssignees
);

export const tasksListSelector = createSelector(
  [workspaceStateSelector, (_, { list }: { list: TaskListType }) => list],
  (wsState, list) => wsState.lists[list]
);

export const tasksGroupsSelector = createSelector(
  [tasksListSelector, (_, { groupBy }: { groupBy: TasksGroupBy }) => groupBy],
  (list, groupBy) => list.groups[groupBy]
);

export const tasksGroupSelector = createSelector(
  [
    tasksListSelector,
    (_, { groupBy }: { groupBy: TasksGroupBy }) => groupBy,
    (_, { groupId }: { groupId: string }) => groupId
  ],
  (list, groupBy, groupId) => nonNull(list.groups[groupBy].items[groupId])
);

export const dueSoonTasksGroupCountSelector = createSelector(
  [
    (state: RootState) => state.tasks.items,
    (
      state,
      {
        groupBy,
        groupId,
        list,
        workspaceId
      }: {
        groupBy: TasksGroupBy;
        groupId: string;
        list: TaskListType;
        workspaceId: string;
      }
    ) =>
      tasksGroupSelector(state, { groupBy, groupId, list, workspaceId })
        .taskIds,
    (
      state,
      {
        groupBy,
        groupId,
        list,
        workspaceId
      }: {
        groupBy: TasksGroupBy;
        groupId: string;
        list: TaskListType;
        workspaceId: string;
      }
    ) =>
      tasksGroupSelector(state, { groupBy, groupId, list, workspaceId })
        .dueSoonTaskIds,
    (_, { now }: { now: Date }) => now
  ],
  (items, taskIds, dueSoonTaskIds, now) => {
    return (
      dueSoonTaskIds.filter((taskId) => !taskIds.includes(taskId)).length +
      taskIds.filter((id) => {
        const task = items[id]?.entity;

        return (
          task &&
          task.status !== 'done' &&
          isDueSoon({
            dateToCompare: task.dueDate,
            baseDate: now
          })
        );
      }).length
    );
  }
);

export const overdueTasksGroupCountSelector = createSelector(
  [
    (state: RootState) => state.tasks.items,
    (
      state,
      {
        groupBy,
        groupId,
        list,
        workspaceId
      }: {
        groupBy: TasksGroupBy;
        groupId: string;
        list: TaskListType;
        workspaceId: string;
      }
    ) =>
      tasksGroupSelector(state, { groupBy, groupId, list, workspaceId })
        .taskIds,
    (
      state,
      {
        groupBy,
        groupId,
        list,
        workspaceId
      }: {
        groupBy: TasksGroupBy;
        groupId: string;
        list: TaskListType;
        workspaceId: string;
      }
    ) =>
      tasksGroupSelector(state, { groupBy, groupId, list, workspaceId })
        .overdueTaskIds,
    (_, { now }: { now: Date }) => now
  ],
  (items, taskIds, overdueTaskIds, now) => {
    return (
      overdueTaskIds.filter((taskId) => !taskIds.includes(taskId)).length +
      taskIds.filter((id) => {
        const task = items[id]?.entity;
        return (
          task &&
          task.status !== 'done' &&
          isDateSameOrBefore({
            dateToCompare: task.dueDate,
            baseDate: now
          })
        );
      }).length
    );
  }
);

export const tasksListOrGroupSelector = createSelector(
  [
    workspaceStateSelector,
    (_, { list }: { list: TaskListType }) => list,
    (_, { groupId }: { groupId?: string }) => groupId
  ],
  (wsState, list, groupId) => {
    const { groupBy } = wsState.listsShared;
    if (groupId && groupBy) {
      return nonNull(wsState.lists[list].groups[groupBy].items[groupId]);
    }
    return wsState.lists[list];
  }
);

export const tasksTotalCountSelector = createSelector(
  [workspaceStateSelector, tasksListSelector],
  (wsState, listState) => {
    const totalCount = listState.count;
    const { groupBy } = wsState.listsShared;
    if (!groupBy) return totalCount;
    const groups = listState.groups[groupBy];
    if (!groups.fetch.initiallyLoaded) return totalCount;
    const groupItems = groups.ids.map((id) => nonNull(groups.items[id]));
    return groupItems.reduce((res, x) => res + x.count, 0);
  }
);

export const taskIdsSelector = createSelector(
  [tasksListOrGroupSelector],
  (state) => state.taskIds
);

export const taskStateSelector = createSelector(
  [
    (state: RootState) => state.tasks.items,
    (_, { taskId }: { taskId: string }) => taskId
  ],
  (items, taskId) => items[taskId] ?? itemInitialState()
);

export const taskSelector = createSelector([taskStateSelector], (state) =>
  nonNull(state.entity)
);

export const campaignStateSelector = createSelector(
  [
    (state: RootState) => state.tasks.byCampaign,
    (_, { campaignId }: { campaignId: string }) => campaignId
  ],
  (byCampaign, campaignId) =>
    byCampaign[campaignId] ?? tasksInCampaignInitialState()
);

export const tasksBoardSelector = createSelector(
  [campaignStateSelector, (_, { boardId }: { boardId: string }) => boardId],
  (list, boardId) => nonNull(list.boards.items[boardId])
);

export const dueSoonTasksBoardCountSelector = createSelector(
  [
    (state: RootState) => state.tasks.items,
    (state, { campaignId, boardId }: { campaignId: string; boardId: string }) =>
      tasksBoardSelector(state, { campaignId, boardId }).taskIds,
    (state, { campaignId, boardId }: { campaignId: string; boardId: string }) =>
      tasksBoardSelector(state, { campaignId, boardId }).dueSoonTaskIds,
    (_, { now }: { now: Date }) => now
  ],
  (items, taskIds, dueSoonTaskIds, now) => {
    return (
      dueSoonTaskIds.filter((taskId) => !taskIds.includes(taskId)).length +
      taskIds.filter((id) => {
        const task = items[id]?.entity;
        return (
          task &&
          task.status !== 'done' &&
          isDueSoon({
            dateToCompare: task.dueDate,
            baseDate: now
          })
        );
      }).length
    );
  }
);

export const overdueTasksBoardCountSelector = createSelector(
  [
    (state: RootState) => state.tasks.items,
    (state, { campaignId, boardId }: { campaignId: string; boardId: string }) =>
      tasksBoardSelector(state, { campaignId, boardId }).taskIds,
    (state, { campaignId, boardId }: { campaignId: string; boardId: string }) =>
      tasksBoardSelector(state, { campaignId, boardId }).overdueTaskIds,
    (_, { now }: { now: Date }) => now
  ],
  (items, taskIds, overdueTaskIds, now) => {
    return (
      overdueTaskIds.filter((taskId) => !taskIds.includes(taskId)).length +
      taskIds.filter((id) => {
        const task = items[id]?.entity;
        return (
          task &&
          task.status !== 'done' &&
          isDateSameOrBefore({
            dateToCompare: task.dueDate,
            baseDate: now
          })
        );
      }).length
    );
  }
);

const boardIdsSelector = createSelector(
  [campaignStateSelector],
  (state) => state.boards.ids
);

const boardItemsSelector = createSelector(
  [campaignStateSelector],
  (state) => state.boards.items
);

export const boardsSelector = createSelector(
  [boardIdsSelector, boardItemsSelector],
  (ids, items) =>
    ids.map((id) => {
      const board = nonNull(items[id]);
      return { id, name: board.name, count: board.count };
    })
);

export const assetVersionStateSelector = createSelector(
  [
    (state: RootState) => state.tasks.byAssetVersion,
    (_, { assetVersionId }: { assetVersionId: string }) => assetVersionId
  ],
  (byAssetVersion, assetVersionId) =>
    byAssetVersion[assetVersionId] ?? tasksInAssetVersionInitialState()
);

export const assetStateSelector = createSelector(
  [
    (state: RootState) => state.tasks.byAsset,
    (_, { assetId }: { assetId: string }) => assetId
  ],
  (byAsset, assetId) => byAsset[assetId] ?? tasksInAssetInitialState()
);
