import { debounce } from '@helpers/debounce';
import {
  TaskListPersistedState,
  TaskListType,
  TasksInWorkspacePersistedState,
  TasksPersistedState,
  TasksState
} from '@redux/types/tasks';

const key = 'store_tasks';

export const persistState = debounce((state: TasksState) => {
  const toPersist: TasksPersistedState = {
    byWorkspace: Object.entries(state.byWorkspace).reduce(
      (res, [workspaceId, state]) => {
        if (state) {
          res[workspaceId] = {
            listsShared: {
              orderBy: state.listsShared.orderBy,
              groupBy: state.listsShared.groupBy,
              searchQuery: state.listsShared.searchQuery,
              selectedCampaigns: state.listsShared.selectedCampaigns,
              statuses: state.listsShared.statuses,
              assignees: state.listsShared.assignees,
              dueDate: state.listsShared.dueDate
            },
            lists: Object.entries(state.lists).reduce((res, [list, state]) => {
              res[list as TaskListType] = {
                count: state.count,
                scrollTop: state.scrollTop,
                groups: Object.entries(state.groups).reduce(
                  (res, [group, state]) => {
                    res[group as keyof TaskListPersistedState['groups']] = {
                      count: state.count,
                      openedIds: state.openedIds
                    };
                    return res;
                  },
                  {} as TaskListPersistedState['groups']
                )
              };
              return res;
            }, {} as TasksInWorkspacePersistedState['lists']),
            dashboard: {
              count: state.dashboard.count,
              listType: state.dashboard.listType
            }
          };
        }
        return res;
      },
      {} as TasksPersistedState['byWorkspace']
    ),
    byCampaign: Object.entries(state.byCampaign).reduce(
      (res, [campaignId, state]) => {
        if (state) {
          res[campaignId] = {
            orderBy: state.orderBy,
            searchQuery: state.searchQuery,
            count: state.count,
            scrollTop: state.scrollTop,
            boards: {
              count: state.boards.count,
              openedIds: state.boards.openedIds
            },
            customFields: {
              list: state.customFields.list.map((state) => ({
                id: state.id,
                campaignId: state.campaignId,
                type: state.type,
                name: state.name
              }))
            }
          };
        }
        return res;
      },
      {} as TasksPersistedState['byCampaign']
    ),
    byAssetVersion: Object.entries(state.byAssetVersion).reduce(
      (res, [assetVersionId, state]) => {
        if (state) {
          res[assetVersionId] = {
            orderBy: state.orderBy,
            searchQuery: state.searchQuery,
            listType: state.listType,
            hideResolvedTasks: state.hideResolvedTasks,
            count: state.count
          };
        }
        return res;
      },
      {} as TasksPersistedState['byAssetVersion']
    )
  };
  sessionStorage.setItem(key, JSON.stringify(toPersist));
}, 500);

export function getPersistedState(): TasksPersistedState | undefined {
  const json = sessionStorage.getItem(key);
  if (!json) return undefined;
  try {
    return JSON.parse(json);
  } catch (err) {
    console.error(err);
    return undefined;
  }
}
