import { WorkspaceTaskCountersDto } from '@api/Api';

export interface IWorkspaceCountersState {
  allCampaignsCount: number;
  myCampaignsCount: number;
  favoriteCampaignsCount: number;
  deletedCampaignsCount: number;
  allAssetsCount: number;
  myAssetsCount: number;
  favoriteAssetsCount: number;
  deletedAssetsCount: number;
  allTasks: WorkspaceTaskCountersDto;
  myTasks: WorkspaceTaskCountersDto;
  assignedToMeTasks: WorkspaceTaskCountersDto;
  deletedTasks: WorkspaceTaskCountersDto;
  recentlyOpenedAssetsCount: number;
}

export interface IWorkspaceCountersAction {
  type: string;
  payload?: any;
}

export enum WorkspaceCountersTypes {
  SET_WORKSPACE_COUNTERS = 'SET_WORKSPACE_COUNTERS',
  UPDATE_WORKSPACE_COUNTERS = 'UPDATE_WORKSPACE_COUNTERS',
  UPDATE_ASSETS_WORKSPACE_COUNTERS = 'UPDATE_ASSETS_WORKSPACE_COUNTERS',
  UPDATE_CAMPAIGNS_WORKSPACE_COUNTERS = 'UPDATE_CAMPAIGNS_WORKSPACE_COUNTERS'
}
