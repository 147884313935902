import { CampaignItemDto } from '@api/Api';

export interface ICampaignState {
  edges: { node: CampaignItemDto; cursor: string }[] | null;
  count?: number;
  hasNext?: boolean;
  startCursor?: string;
  endCursor?: string;
  selectedCampaign: CampaignItemDto | null;
}

export interface ICampaignAction {
  type: string;
  payload?: any | string;
}

export enum CampaignActionTypes {
  SET_CAMPAIGN_LIST = 'SET_CAMPAIGN_LIST',
  LOAD_MORE_CAMPAIGN = 'LOAD_MORE_CAMPAIGN',
  SET_FAVORITE_CAMPAIGN = 'SET_FAVORITE_CAMPAIGN',
  DELETE_CAMPAIGN = 'DELETE_CAMPAIGN',
  RESTORE_DELETED_CAMPAIGN = 'RESTORE_DELETED_CAMPAIGN',
  CREATE_CAMPAIGN = 'CREATE_CAMPAIGN',
  UPDATE_CAMPAIGN_COUNTER = 'UPDATE_CAMPAIGN_COUNTER',
  SET_CAMPAIGN = 'SET_CAMPAIGN',
  UPDATE_CAMPAIGN_ADDITIONAL_PERMISSIONS = 'UPDATE_CAMPAIGN_ADDITIONAL_PERMISSIONS',
  SET_CAMPAIGN_VISIBILITY = 'SET_CAMPAIGN_VISIBILITY',
  UPDATE_CAMPAIGN_DETAILS = 'UPDATE_CAMPAIGN_DETAILS',
  UPDATE_CAMPAIGN = 'UPDATE_CAMPAIGN',
  RENAME_CAMPAIGN = 'RENAME_CAMPAIGN'
}
