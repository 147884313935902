import moment from 'moment';

type isDateSameOrBeforeProps = {
  dateToCompare: moment.MomentInput;
  baseDate: moment.MomentInput;
};
export function isDateSameOrBefore({
  dateToCompare,
  baseDate
}: isDateSameOrBeforeProps) {
  return moment(dateToCompare).isSameOrBefore(moment(baseDate));
}

export function isDueSoon({
  dateToCompare,
  baseDate
}: isDateSameOrBeforeProps) {
  if (!dateToCompare) return;
  const start = moment(baseDate);
  const end = moment(dateToCompare);
  const x = start.isSameOrBefore(end);
  return x;
}
