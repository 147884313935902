import { AssetItemDto } from '@api/Api';

export type EntitiesStateProps = {
  edges: { node: AssetItemDto; cursor: string }[] | null;
  count?: number;
  hasNext?: boolean;
  startCursor?: string;
  endCursor?: string;
};

export interface IAssetsState {
  files: EntitiesStateProps;
  folders: EntitiesStateProps;
  selectedFolder: AssetItemDto | null;
}

export interface IAssetsAction {
  type: string;
  payload?: any | string;
}

export enum AssetsActionTypes {
  SET_ASSETS_LIST = 'SET_ASSETS_LIST',
  SET_FOLDERS_LIST = 'SET_FOLDERS_LIST',
  UPDATE_FOLDERS_LIST = 'UPDATE_FOLDERS_LIST',
  ADD_NEW_ASSET = 'ADD_NEW_ASSET',
  UPDATE_ASSET = 'UPDATE_ASSET',
  LOAD_MORE_ASSETS = 'LOAD_MORE_ASSETS',
  LOAD_MORE_FOLDERS = 'LOAD_MORE_FOLDERS',
  DELETE_ASSET = 'DELETE_ASSET',
  RESTORE_DELETED_ASSET = 'RESTORE_DELETED_ASSET',
  RESTORE_DELETED_FOLDER = 'RESTORE_DELETED_FOLDER',
  SET_FAVORITE_ASSET = 'SET_FAVORITE_ASSET',
  RENAME_ASSET = 'RENAME_ASSET',
  SET_FOLDER_ACCESSABILITY = 'SET_FOLDER_ACCESSABILITY',
  RENAME_FOLDER = 'RENAME_FOLDER',
  DELETE_FOLDER = 'DELETE_FOLDER',
  CREATE_FOLDER = 'CREATE_FOLDER',
  SET_FOLDER = 'SET_FOLDER',
  MOVE_ASSET_TO_FOLDER = 'MOVE_ASSET_TO_FOLDER',
  ADD_ASSET_TO_STACK = 'ADD_ASSET_TO_STACK',
  REMOVE_ASSET_FROM_STACK = 'REMOVE_ASSET_FROM_STACK',
  UPDATE_FOLDER_COUNTERS = 'UPDATE_FOLDER_COUNTERS',
  UPDATE_FOLDER = 'UPDATE_FOLDER'
}
