import { APP_BASE_URL } from '@config/constants';
import { nonNull } from '@helpers/non-null';
import React, {
  createContext,
  ReactElement,
  ReactNode,
  useContext,
  useEffect,
  useMemo
} from 'react';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

import { IOrganizationItemState } from '@redux/types/organizations';

interface IOrganizationContext {
  readonly currentOrganization?: IOrganizationItemState;
  readonly defaultOrganization?: IOrganizationItemState;
}

const OrganizationContext = createContext<IOrganizationContext>({});
OrganizationContext.displayName = 'Organization';

// eslint-disable-next-line no-undef
const defaultHosts = APP_BASE_URL.map((x) => new URL(x).hostname);
const preferredSubdomainCookieName = 'preferred-subdomain';

function getOrgSubdomain(hostname: string) {
  if (defaultHosts.includes(hostname)) return undefined;
  const domainSegments = hostname.split('.');
  if (domainSegments.length <= 2) return undefined;
  return domainSegments[0];
}

interface OrganizationProviderProps {
  readonly organizations: IOrganizationItemState[];
  readonly children: ReactNode;
}

export function OrganizationProvider({
  children,
  organizations
}: OrganizationProviderProps) {
  const { host, hostname } = window.location;
  const subdomain = useMemo(() => getOrgSubdomain(hostname), [hostname]);

  useEffect(() => {
    if (!subdomain) return;
    Cookies.set(preferredSubdomainCookieName, subdomain, {
      domain: `.${
        defaultHosts.find((x) => host === x || host.endsWith(`.${x}`)) ??
        defaultHosts[0]
      }`,
      expires: 365
    });
  }, [host, subdomain]);

  const currentOrganization = useMemo(
    () => organizations.find((x) => x.entity?.subdomain === subdomain),
    [organizations, subdomain]
  );
  const defaultOrganization = useMemo(() => {
    const preferredSubdomain = Cookies.get(preferredSubdomainCookieName);
    return (
      organizations.find((x) => x.entity?.subdomain === preferredSubdomain) ??
      organizations[0]
    );
  }, [organizations]);
  const contextValue = useMemo(
    () => ({ currentOrganization, defaultOrganization }),
    [currentOrganization, defaultOrganization]
  );
  return (
    <OrganizationContext.Provider value={contextValue}>
      {children}
    </OrganizationContext.Provider>
  );
}

export function useOrganization<TRequired extends boolean = true>(
  required = true as TRequired
): TRequired extends true
  ? Required<IOrganizationContext>
  : IOrganizationContext {
  const context = useContext(OrganizationContext);
  if (required === true) {
    return {
      currentOrganization: nonNull(
        context.currentOrganization,
        'currentOrganization'
      ),
      defaultOrganization: nonNull(
        context.defaultOrganization,
        'defaultOrganization'
      )
    };
  }
  return context as any;
}

export function redirectToSubdomain(
  subdomain: string,
  path?: string,
  queries?: { name: string; value: string }[]
) {
  const { host, hostname } = window.location;
  const domainSegments = host.split('.');
  if (domainSegments.length > 2 && !defaultHosts.includes(hostname)) {
    domainSegments.splice(0, 1);
  }
  domainSegments.unshift(subdomain);
  const { protocol } = window.location;
  const newHost = domainSegments.join('.');
  let url = `${protocol}//${newHost}/`;
  if (path) url += path;
  const newUrl = new URL(url);

  if (queries && queries?.length > 0) {
    queries.forEach(({ name, value }) => {
      newUrl.searchParams.set(name, value);
    });
  }

  window.location.replace(newUrl);
}

interface OrganizationBoundaryProps {
  readonly loader?: ReactElement | null;
}

export function OrganizationBoundary({
  loader
}: Required<OrganizationBoundaryProps>) {
  const { currentOrganization, defaultOrganization } = useOrganization(false);

  const redirectToDefaultOrg = !currentOrganization && defaultOrganization;
  const navigate = useNavigate();
  useEffect(() => {
    if (!redirectToDefaultOrg) return;
    redirectToSubdomain(nonNull(defaultOrganization.entity).subdomain);
  }, [redirectToDefaultOrg, defaultOrganization]);
  useEffect(() => {
    if (!currentOrganization?.entity) return;
    if (currentOrganization.entity.lockDate) {
      const nowDate = new Date().getTime();
      const lockDate = new Date(currentOrganization.entity.lockDate).getTime();

      if (nowDate >= lockDate)
        navigate(
          `/lock?lockDate=${currentOrganization.entity.lockDate}&lockReason=${currentOrganization.entity.lockReasons}&default=${defaultOrganization?.entity?.subdomain}`
        );
    }
  }, [currentOrganization?.entity, navigate, defaultOrganization?.entity]);
  if (!defaultOrganization) return <Navigate to="/onboarding" replace />;

  return redirectToDefaultOrg ? loader : <Outlet />;
}

OrganizationBoundary.defaultProps = {
  loader: null
};
