import {
  FetchState,
  IOrganizationItemState,
  IOrganizationListPersistedState,
  IOrganizationListState,
  IOrganizationPersistedState,
  IOrganizationState,
  ItemFetchType,
  ListFetchType
} from '@redux/types/organizations';
import { nanoid } from 'nanoid';

export function initialFetchState<
  T extends ListFetchType | ItemFetchType
>(): FetchState<T> {
  return {
    initiallyLoaded: false,
    requestId: null,
    newRequest: { key: nanoid(), fetchType: 'initial' as T }
  };
}

export function listInitialState(
  persisted?: IOrganizationListPersistedState
): IOrganizationListState {
  return {
    fetch: initialFetchState(),
    count: persisted?.count ?? 0,
    organizationIds: []
  };
}

export function itemInitialState(): IOrganizationItemState {
  return {
    fetch: initialFetchState(),
    entity: null,
    activeTab: 'manage_workspaces',
    subscription: {
      it: { type: 'free' },
      fetch: initialFetchState()
    },
    seatsCount: {
      count: 0,
      countFetch: initialFetchState()
    },
    assetsSummarySize: {
      size: 0,
      sizeFetch: initialFetchState()
    },
    paymentMethod: {
      it: null,
      fetch: initialFetchState()
    },
    invoices: {
      count: 0,
      cursor: null,
      fetch: initialFetchState(),
      hasMore: false,
      list: []
    },
    workspaces: {
      count: 0,
      fetch: initialFetchState(),
      list: [],
      searchQuery: ''
    },
    members: {
      count: 0,
      countFetch: initialFetchState(),
      cursor: null,
      fetch: initialFetchState(),
      hasMore: false,
      list: [],
      orderBy: ['createdAt:DESC'],
      searchQuery: ''
    }
  };
}

export function initialState(
  persisted?: IOrganizationPersistedState
): IOrganizationState {
  const list = listInitialState(persisted?.list);
  return {
    list,
    items: {},
    undo: {}
  };
}
