import {
  AssetControllerListAssetsParams,
  AssetItemDto,
  CampaignControllerDeleteTaskFieldParams,
  CreateTaskCommentDto,
  CreateTaskDto,
  CreateTaskFieldDto,
  PageDto,
  RenameTaskFieldDto,
  TaskControllerListTasksParams,
  TaskCustomFieldDto,
  TaskEventDto,
  TaskItemAssetDto,
  TaskItemCampaignDto,
  TaskItemDto,
  TaskItemMemberDto,
  UpdateTaskDto
} from '@api/Api';
import { apiClient } from '@api/client';
import { nonNull } from '@helpers/non-null';
import {
  itemInitialState,
  tasksInAssetVersionInitialState,
  tasksInCampaignInitialState,
  tasksInWorkspaceInitialState
} from '@redux/initial-states/tasks';
import { RootState } from '@redux/store';
import {
  ItemFetchType,
  TaskListType,
  ListFetchType,
  TasksGroupBy
} from '@redux/types/tasks';
import { createAsyncThunk, miniSerializeError } from '@reduxjs/toolkit';
import {
  MixpanelEventType,
  MixpanelService,
  PlanNames
} from '@services/mixpanelService';
import axios from 'axios';

function getWsState(state: RootState, workspaceId: string) {
  return state.tasks.byWorkspace[workspaceId] ?? tasksInWorkspaceInitialState();
}

function getCampaignState(state: RootState, campaignId: string) {
  return state.tasks.byCampaign[campaignId] ?? tasksInCampaignInitialState();
}

function getTaskState(state: RootState, taskId: string) {
  return state.tasks.items[taskId] ?? itemInitialState();
}

function getAssetVersionState(state: RootState, assetVersionId: string) {
  return (
    state.tasks.byAssetVersion[assetVersionId] ??
    tasksInAssetVersionInitialState()
  );
}

interface FetchTaskPayload {
  id: string;
  fetchType: ItemFetchType;
}

export const fetchTaskById = createAsyncThunk(
  'tasks/fetchTaskById',
  async (payload: FetchTaskPayload, thunkAPI) => {
    try {
      const cts = axios.CancelToken.source();
      thunkAPI.signal.addEventListener('abort', () => cts.cancel());
      const { data } = await apiClient.task.taskControllerGetTask(
        {
          id: payload.id
        },
        { cancelToken: cts.token }
      );
      return data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  },
  {
    serializeError(err) {
      const result = miniSerializeError(err);
      if (axios.isAxiosError(err)) {
        result.code = err.response?.status.toString();
      }
      return result;
    }
  }
);

interface FetchTaskListPayload {
  workspaceId: string;
  group?: {
    id: string;
    by: TasksGroupBy;
  };
  list: TaskListType;
  fetchType: ListFetchType;
}

export const fetchTaskList = createAsyncThunk(
  'tasks/fetchTaskList',
  async (payload: FetchTaskListPayload, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const { workspaceId } = payload;
      const wsState = getWsState(state, workspaceId);
      const list = wsState.lists[payload.list];
      const groupOrList = payload.group
        ? nonNull(list.groups[payload.group.by].items[payload.group.id])
        : list;
      const { cursor, hasMore } = groupOrList;
      const {
        searchQuery,
        dueDate,
        statuses,
        assignees,
        selectedCampaigns,
        orderBy
      } = wsState.listsShared;
      const cts = axios.CancelToken.source();
      thunkAPI.signal.addEventListener('abort', () => cts.cancel());
      const params: TaskControllerListTasksParams = {
        workspaceId,
        own: payload.list === 'my' ? true : undefined,
        assignedToMe: payload.list === 'assigned' ? true : undefined,
        isDeleted: payload.list === 'deleted' ? true : undefined,
        orderBy: [orderBy],
        searchQuery: searchQuery ?? undefined,
        dueDateFrom: dueDate ? dueDate[0] : undefined,
        dueDateTo: dueDate ? dueDate[1] : undefined,
        statuses,
        assigneeIds: assignees.map((x) => x.id),
        campaignIds:
          payload.group?.by === 'campaign'
            ? [payload.group.id]
            : selectedCampaigns.map((x) => x.id),
        boardIds:
          payload.group?.by === 'board'
            ? payload.group.id.split(',')
            : undefined
      };
      let data:
        | (PageDto & {
            edges: {
              node: TaskItemDto;
              cursor: string;
            }[];
          })
        | undefined;
      if (payload.fetchType === 'refresh' && cursor) {
        // Loading items in backward direction right to the start of the list
        let hasPrevious = false;
        do {
          const response = await apiClient.task.taskControllerListTasks(
            {
              ...params,
              before: data ? data.startCursor : cursor,
              inclusive: !data,
              limit: 100 // max limit
            },
            { cancelToken: cts.token }
          );
          hasPrevious = response.data.hasNext;
          if (!data) {
            data = response.data;
            data.hasNext = hasMore;
          } else {
            data.startCursor = response.data.startCursor;
            const responseIds = response.data.edges.map((x) => x.node.id);
            data.edges = [
              ...response.data.edges,
              ...data.edges.filter((x) => !responseIds.includes(x.node.id))
            ];
          }
        } while (hasPrevious);
      } else {
        const response = await apiClient.task.taskControllerListTasks(
          {
            ...params,
            after:
              payload.fetchType === 'more' ? cursor ?? undefined : undefined
          },
          { cancelToken: cts.token }
        );
        data = response.data;
      }
      return data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }
);

export const fetchTasksCount = createAsyncThunk(
  'tasks/fetchTasksCount',
  async (
    payload: Omit<FetchTaskListPayload, 'fetchType'> & {
      fetchType: ItemFetchType;
    },
    thunkAPI
  ) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const { workspaceId } = payload;
      const wsState = getWsState(state, workspaceId);
      const { searchQuery, dueDate, statuses, assignees, selectedCampaigns } =
        wsState.listsShared;
      const cts = axios.CancelToken.source();
      thunkAPI.signal.addEventListener('abort', () => cts.cancel());
      const { data } = await apiClient.task.taskControllerCountTasks(
        {
          workspaceId,
          own: payload.list === 'my' ? true : undefined,
          assignedToMe: payload.list === 'assigned' ? true : undefined,
          isDeleted: payload.list === 'deleted' ? true : undefined,
          searchQuery: searchQuery ?? undefined,
          dueDateFrom: dueDate ? dueDate[0] : undefined,
          dueDateTo: dueDate ? dueDate[1] : undefined,
          statuses,
          assigneeIds: assignees.map((x) => x.id),
          campaignIds:
            payload.group?.by === 'campaign'
              ? [payload.group.id]
              : selectedCampaigns.map((x) => x.id),
          boardIds:
            payload.group?.by === 'board'
              ? payload.group.id.split(',')
              : undefined
        },
        { cancelToken: cts.token }
      );
      return data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }
);

interface FetchCampaignsForFilterPayload {
  workspaceId: string;
  fetchType: Exclude<ListFetchType, 'more'>;
}

export const fetchCampaignsForFilter = createAsyncThunk(
  'tasks/fetchCampaignsForFilter',
  async (payload: FetchCampaignsForFilterPayload, thunkAPI) => {
    try {
      const { workspaceId } = payload;
      const cts = axios.CancelToken.source();
      thunkAPI.signal.addEventListener('abort', () => cts.cancel());
      const { data } =
        await apiClient.campaign.campaignControllerGetSimpleCampaignsList(
          {
            workspaceId
          },
          { cancelToken: cts.token }
        );
      return data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }
);

interface FetchAssigneesForFilterPayload {
  workspaceId: string;
  fetchType: Exclude<ListFetchType, 'more'>;
}

export const fetchAssigneesForFilter = createAsyncThunk(
  'tasks/fetchAssigneesForFilter',
  async (payload: FetchAssigneesForFilterPayload, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const { workspaceId } = payload;
      const wsState = getWsState(state, workspaceId);
      const { searchQuery } = wsState.listsShared.potentialAssignees;
      const cts = axios.CancelToken.source();
      thunkAPI.signal.addEventListener('abort', () => cts.cancel());
      const { data } =
        await apiClient.task.taskControllerListPotentialAssignees(
          {
            workspaceId,
            limit: 100,
            searchQuery
          },
          { cancelToken: cts.token }
        );
      return data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }
);

interface FetchCampaignGroupsPayload {
  workspaceId: string;
  list: TaskListType;
  fetchType: Exclude<ListFetchType, 'more'>;
}

export const fetchCampaignGroups = createAsyncThunk(
  'tasks/fetchCampaignGroups',
  async (payload: FetchCampaignGroupsPayload, thunkAPI) => {
    try {
      const { workspaceId } = payload;
      const cts = axios.CancelToken.source();
      thunkAPI.signal.addEventListener('abort', () => cts.cancel());
      const state = thunkAPI.getState() as RootState;
      const wsState = getWsState(state, workspaceId);
      const { searchQuery, dueDate, statuses, assignees, selectedCampaigns } =
        wsState.listsShared;
      const { data } = await apiClient.task.taskControllerListCampaignGroups(
        {
          workspaceId,
          own: payload.list === 'my' ? true : undefined,
          assignedToMe: payload.list === 'assigned' ? true : undefined,
          isDeleted: payload.list === 'deleted' ? true : undefined,
          searchQuery: searchQuery ?? undefined,
          dueDateFrom: dueDate ? dueDate[0] : undefined,
          dueDateTo: dueDate ? dueDate[1] : undefined,
          statuses,
          assigneeIds: assignees.map((x) => x.id),
          campaignIds: selectedCampaigns.map((x) => x.id)
        },
        { cancelToken: cts.token }
      );
      return data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }
);

interface FetchTaskBoardGroupsPayload {
  workspaceId: string;
  list: TaskListType;
  fetchType: Exclude<ListFetchType, 'more'>;
}

export const fetchTaskBoardGroups = createAsyncThunk(
  'tasks/fetchTaskBoardGroups',
  async (payload: FetchTaskBoardGroupsPayload, thunkAPI) => {
    try {
      const { workspaceId } = payload;
      const cts = axios.CancelToken.source();
      thunkAPI.signal.addEventListener('abort', () => cts.cancel());
      const state = thunkAPI.getState() as RootState;
      const wsState = getWsState(state, workspaceId);
      const { searchQuery, dueDate, statuses, assignees, selectedCampaigns } =
        wsState.listsShared;
      const { data } = await apiClient.task.taskControllerListTaskBoardGroups(
        {
          workspaceId,
          own: payload.list === 'my' ? true : undefined,
          assignedToMe: payload.list === 'assigned' ? true : undefined,
          isDeleted: payload.list === 'deleted' ? true : undefined,
          searchQuery: searchQuery ?? undefined,
          dueDateFrom: dueDate ? dueDate[0] : undefined,
          dueDateTo: dueDate ? dueDate[1] : undefined,
          statuses,
          assigneeIds: assignees.map((x) => x.id),
          campaignIds: selectedCampaigns.map((x) => x.id)
        },
        { cancelToken: cts.token }
      );
      return data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }
);

interface FetchCampaignTaskBoardsPayload {
  campaignId: string;
  fetchType: Exclude<ListFetchType, 'more'>;
}

export const fetchCampaignTaskBoards = createAsyncThunk(
  'tasks/fetchCampaignTaskBoards',
  async (payload: FetchCampaignTaskBoardsPayload, thunkAPI) => {
    try {
      const { campaignId } = payload;
      const cts = axios.CancelToken.source();
      thunkAPI.signal.addEventListener('abort', () => cts.cancel());
      const state = thunkAPI.getState() as RootState;
      const campaignState = getCampaignState(state, campaignId);
      const { searchQuery } = campaignState;
      const { data } =
        await apiClient.taskBoard.taskBoardControllerListTaskBoards(
          {
            campaignId,
            searchQuery: searchQuery ?? undefined
          },
          { cancelToken: cts.token }
        );
      return data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }
);

interface FetchTasksCountInCampaignPayload {
  workspaceId: string;
  campaignId: string;
  boardId: string;
  fetchType: ItemFetchType;
}

export const fetchTasksCountInCampaign = createAsyncThunk(
  'tasks/fetchTasksCountInCampaign',
  async (payload: FetchTasksCountInCampaignPayload, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const { workspaceId, campaignId, boardId } = payload;
      const campaignState = getCampaignState(state, campaignId);
      const { searchQuery } = campaignState;
      const cts = axios.CancelToken.source();
      thunkAPI.signal.addEventListener('abort', () => cts.cancel());
      const { data } = await apiClient.task.taskControllerCountTasks(
        {
          workspaceId,
          campaignIds: [campaignId],
          boardIds: boardId ? [boardId] : undefined,
          searchQuery: searchQuery ?? undefined,
          allowArchived: !boardId
        },
        { cancelToken: cts.token }
      );
      return data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }
);

interface FetchTaskListInCampaignPayload {
  workspaceId: string;
  campaignId: string;
  boardId: string;
  fetchType: ListFetchType;
}

export const fetchTaskListInCampaign = createAsyncThunk(
  'tasks/fetchTaskListInCampaign',
  async (payload: FetchTaskListInCampaignPayload, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const { workspaceId, campaignId, boardId } = payload;
      const campaignState = getCampaignState(state, campaignId);
      const { searchQuery, orderBy } = campaignState;
      const { cursor, hasMore } = nonNull(campaignState.boards.items[boardId]);
      const cts = axios.CancelToken.source();
      thunkAPI.signal.addEventListener('abort', () => cts.cancel());
      const params: TaskControllerListTasksParams = {
        workspaceId,
        campaignIds: [campaignId],
        boardIds: [boardId],
        searchQuery: searchQuery ?? undefined,
        orderBy: [orderBy]
      };
      let data:
        | (PageDto & {
            edges: {
              node: TaskItemDto;
              cursor: string;
            }[];
          })
        | undefined;
      if (payload.fetchType === 'refresh' && cursor) {
        // Loading items in backward direction right to the start of the list
        let hasPrevious = false;
        do {
          const response = await apiClient.task.taskControllerListTasks(
            {
              ...params,
              before: data ? data.startCursor : cursor,
              inclusive: !data,
              limit: 100 // max limit
            },
            { cancelToken: cts.token }
          );
          hasPrevious = response.data.hasNext;
          if (!data) {
            data = response.data;
            data.hasNext = hasMore;
          } else {
            data.startCursor = response.data.startCursor;
            const responseIds = response.data.edges.map((x) => x.node.id);
            data.edges = [
              ...response.data.edges,
              ...data.edges.filter((x) => !responseIds.includes(x.node.id))
            ];
          }
        } while (hasPrevious);
      } else {
        const response = await apiClient.task.taskControllerListTasks(
          {
            ...params,
            after:
              payload.fetchType === 'more' ? cursor ?? undefined : undefined
          },
          { cancelToken: cts.token }
        );
        data = response.data;
      }
      return data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }
);

export type UpdateTaskPayload = Omit<
  UpdateTaskDto,
  | 'board'
  | 'descriptionMentions'
  | 'assetVersionId'
  | 'assignees'
  | 'watchers'
  | 'customFields'
> & {
  board?: {
    id: string | 'no_assigned';
    name: string;
    beforeTaskId?: string | null;
  };
  asset?: TaskItemAssetDto | null;
  descriptionMentions?: {
    add?: TaskItemMemberDto[];
    remove?: string[];
  };
  assignees?: {
    add?: TaskItemMemberDto[];
    remove?: string[];
  };
  watchers?: {
    add?: TaskItemMemberDto[];
    remove?: string[];
  };
  customFields?: {
    add?: {
      id: string;
      name: string;
      value: string;
      type: TaskCustomFieldDto['type'];
    }[];
    remove?: { id: string; value?: string }[];
  };
  mixpanel: any;
  userId: string;
  planType: PlanNames;
};

export const updateTask = createAsyncThunk(
  'tasks/updateTask',
  async (
    {
      asset,
      descriptionMentions,
      assignees,
      watchers,
      mixpanel,
      userId,
      planType,
      ...payload
    }: UpdateTaskPayload,
    thunkAPI
  ) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const task = state.tasks.items[payload.id]?.entity;
      const response = await apiClient.task.taskControllerUpdateTask({
        ...payload,
        assetVersionId: asset ? asset.selectedVersion.id : asset,
        descriptionMentions: descriptionMentions
          ? {
              ...descriptionMentions,
              add: descriptionMentions.add
                ? descriptionMentions.add.map((x) => x.id)
                : undefined
            }
          : undefined,
        assignees: assignees
          ? {
              ...assignees,
              add: assignees.add ? assignees.add.map((x) => x.id) : undefined
            }
          : undefined,
        watchers: watchers
          ? {
              ...assignees,
              add: watchers.add ? watchers.add.map((x) => x.id) : undefined
            }
          : undefined
      });
      if (task) {
        MixpanelService.track(
          mixpanel,
          userId,
          MixpanelEventType.MANAGE_TASKS,
          {
            taskName: task.name,
            campaignName: task.campaign.name,
            description: task.description ?? '',
            deleteTask: false,
            createTask: false,
            asset: task.asset
              ? `${task.asset.selectedVersion.name}.${task.asset.selectedVersion.extension}`
              : null,
            planType
          }
        );
      }
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  },
  {
    serializeError(err) {
      const result = miniSerializeError(err);
      if (axios.isAxiosError(err)) {
        result.code = err.response?.status.toString();
      }
      return result;
    }
  }
);

export interface DeleteTaskPayload {
  id: string;
  permanent?: boolean;
  mixpanel: any;
  userId: string;
  planType: PlanNames;
}

export const deleteTask = createAsyncThunk(
  'tasks/deleteTask',
  async (payload: DeleteTaskPayload, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const task = state.tasks.items[payload.id]?.entity;
      const response = await apiClient.task.taskControllerDeleteTask({
        id: payload.id,
        permanent: payload.permanent
      });
      if (task) {
        MixpanelService.track(
          payload.mixpanel,
          payload.userId,
          MixpanelEventType.MANAGE_TASKS,
          {
            taskName: task.name,
            campaignName: nonNull(task.campaign?.name),
            description: task.description ?? '',
            deleteTask: true,
            createTask: false,
            asset: task.asset
              ? `${task.asset.selectedVersion.name}.${task.asset.selectedVersion.extension}`
              : null,
            planType: payload.planType
          }
        );
      }
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  },
  {
    serializeError(err) {
      const result = miniSerializeError(err);
      if (axios.isAxiosError(err)) {
        result.code = err.response?.status.toString();
      }
      return result;
    }
  }
);

export const restoreTask = createAsyncThunk(
  'tasks/restoreTask',
  async (payload: { id: string }) => {
    try {
      const response = await apiClient.task.taskControllerRestoreDeletedTask({
        id: payload.id
      });
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  },
  {
    serializeError(err) {
      const result = miniSerializeError(err);
      if (axios.isAxiosError(err)) {
        result.code = err.response?.status.toString();
      }
      return result;
    }
  }
);

export const moveTaskBoard = createAsyncThunk(
  'tasks/moveTaskBoard',
  async (payload: {
    campaignId: string;
    id: string;
    beforeBoardId: string;
  }) => {
    try {
      const response =
        await apiClient.taskBoard.taskBoardControllerMoveTaskBoard({
          campaignId: payload.campaignId,
          id: payload.id,
          beforeBoardId:
            payload.beforeBoardId === 'archived' ? null : payload.beforeBoardId
        });
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }
);

export const createTaskBoard = createAsyncThunk(
  'tasks/createTaskBoard',
  async (payload: { campaignId: string; name: string }) => {
    try {
      const response =
        await apiClient.taskBoard.taskBoardControllerCreateTaskBoard({
          campaignId: payload.campaignId,
          name: payload.name
        });
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }
);

export const renameTaskBoard = createAsyncThunk(
  'tasks/renameTaskBoard',
  async (payload: { campaignId: string; id: string; name: string }) => {
    try {
      const response =
        await apiClient.taskBoard.taskBoardControllerRenameTaskBoard({
          campaignId: payload.campaignId,
          id: payload.id,
          name: payload.name
        });
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }
);

export type CreateTaskPayload = Omit<
  CreateTaskDto,
  | 'campaignId'
  | 'assetVersionId'
  | 'assignees'
  | 'watchers'
  | 'descriptionMentions'
> & {
  workspaceId: string;
  campaign: TaskItemCampaignDto;
  asset?: TaskItemAssetDto | null;
  descriptionMentions?: TaskItemMemberDto[];
  assignees?: TaskItemMemberDto[];
  watchers?: TaskItemMemberDto[];
  taskPosition: 'start-of-list' | 'end-of-list';
  mixpanel: any;
  userId: string;
  planType: PlanNames;
};

export const createTask = createAsyncThunk(
  'tasks/createTask',
  async ({
    mixpanel,
    userId,
    planType,
    campaign,
    asset,
    descriptionMentions,
    assignees,
    watchers,
    taskPosition: _,
    ...payload
  }: CreateTaskPayload) => {
    try {
      const response = await apiClient.task.taskControllerCreateTask({
        ...payload,
        campaignId: campaign.id,
        assetVersionId: asset?.selectedVersion.id,
        descriptionMentions: descriptionMentions?.map((x) => x.id),
        assignees: assignees?.map((x) => x.id),
        watchers: watchers?.map((x) => x.id)
      });
      MixpanelService.track(mixpanel, userId, MixpanelEventType.MANAGE_TASKS, {
        taskName: payload.name,
        campaignName: campaign.name,
        description: payload.description ?? '',
        deleteTask: false,
        createTask: true,
        asset: asset
          ? `${asset.selectedVersion.name}.${asset.selectedVersion.extension}`
          : null,
        planType
      });
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }
);

export const deleteTaskBoard = createAsyncThunk(
  'tasks/deleteTaskBoard',
  async (payload: {
    campaignId: string;
    id: string;
    deleteAllTasks: boolean;
  }) => {
    try {
      const response =
        await apiClient.taskBoard.taskBoardControllerDeleteTaskBoard({
          campaignId: payload.campaignId,
          id: payload.id,
          deleteAllTasks: payload.deleteAllTasks
        });
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }
);

interface fetchTaskCustomFieldsPayload {
  campaignId: string;
  fetchType: Exclude<ListFetchType, 'more'>;
}

export const fetchTaskCustomFields = createAsyncThunk(
  'tasks/fetchTaskCustomFields',
  async (payload: fetchTaskCustomFieldsPayload, thunkAPI) => {
    try {
      const cts = axios.CancelToken.source();
      thunkAPI.signal.addEventListener('abort', () => cts.cancel());
      const response =
        await apiClient.campaign.campaignControllerListTaskFields(
          { campaignId: payload.campaignId },
          { cancelToken: cts.token }
        );
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }
);

export const createTaskCustomField = createAsyncThunk(
  'tasks/createTaskCustomField',
  async (payload: CreateTaskFieldDto) => {
    try {
      const response =
        await apiClient.campaign.campaignControllerCreateTaskField(payload);
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }
);

export const renameTaskCustomField = createAsyncThunk(
  'tasks/renameTaskCustomField',
  async (payload: RenameTaskFieldDto) => {
    try {
      const response =
        await apiClient.campaign.campaignControllerRenameTaskField(payload);
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }
);

export const deleteTaskCustomField = createAsyncThunk(
  'tasks/deleteTaskCustomField',
  async (payload: CampaignControllerDeleteTaskFieldParams) => {
    try {
      const response =
        await apiClient.campaign.campaignControllerDeleteTaskField(payload);
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }
);

interface FetchTaskAssigneesPayload {
  taskId: string;
  fetchType: Exclude<ListFetchType, 'more'>;
}

export const fetchTaskAssignees = createAsyncThunk(
  'tasks/fetchTaskAssignees',
  async (payload: FetchTaskAssigneesPayload, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const { taskId } = payload;
      const taskState = getTaskState(state, taskId);
      const task = nonNull(taskState.entity);
      const { searchQuery } = taskState.potentialAssignees;
      const cts = axios.CancelToken.source();
      thunkAPI.signal.addEventListener('abort', () => cts.cancel());
      const { data } =
        await apiClient.task.taskControllerListPotentialAssignees(
          {
            workspaceId: task.workspaceId,
            campaignId: task.campaign.id || undefined,
            assetId: task.asset?.id,
            limit: 100,
            searchQuery
          },
          { cancelToken: cts.token }
        );
      return data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }
);

interface FetchTaskDescriptionMentionsPayload {
  taskId: string;
  fetchType: Exclude<ListFetchType, 'more'>;
}

export const fetchTaskDescriptionMentions = createAsyncThunk(
  'tasks/fetchTaskDescriptionMentions',
  async (payload: FetchTaskDescriptionMentionsPayload, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const { taskId } = payload;
      const taskState = getTaskState(state, taskId);
      const task = nonNull(taskState.entity);
      const { searchQuery } = taskState.descriptionMentions;
      const cts = axios.CancelToken.source();
      thunkAPI.signal.addEventListener('abort', () => cts.cancel());
      const { data } =
        await apiClient.task.taskControllerListPotentialAssignees(
          {
            workspaceId: task.workspaceId,
            campaignId: task.campaign.id || undefined,
            assetId: task.asset?.id,
            limit: 100,
            searchQuery
          },
          { cancelToken: cts.token }
        );
      return data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }
);

interface FetchTaskCommentMentionsPayload {
  taskId: string;
  fetchType: Exclude<ListFetchType, 'more'>;
}

export const fetchTaskCommentMentions = createAsyncThunk(
  'tasks/fetchTaskCommentMentions',
  async (payload: FetchTaskCommentMentionsPayload, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const { taskId } = payload;
      const taskState = getTaskState(state, taskId);
      const task = nonNull(taskState.entity);
      const { searchQuery } = taskState.commentMentions;
      const cts = axios.CancelToken.source();
      thunkAPI.signal.addEventListener('abort', () => cts.cancel());
      const { data } =
        await apiClient.task.taskControllerListPotentialAssignees(
          {
            workspaceId: task.workspaceId,
            campaignId: task.campaign.id || undefined,
            assetId: task.asset?.id,
            limit: 100,
            searchQuery
          },
          { cancelToken: cts.token }
        );
      return data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }
);

interface FetchTaskEventsPayload {
  taskId: string;
  fetchType: ListFetchType;
}

export const fetchTaskEvents = createAsyncThunk(
  'tasks/fetchTaskEvents',
  async (payload: FetchTaskEventsPayload, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const { taskId, fetchType } = payload;
      const taskState = getTaskState(state, taskId);
      const { startCursor, cursor, hasMore } = taskState.events;
      const cts = axios.CancelToken.source();
      thunkAPI.signal.addEventListener('abort', () => cts.cancel());
      let data:
        | (PageDto & {
            edges: {
              node: TaskEventDto;
              cursor: string;
            }[];
          })
        | undefined;
      if (fetchType === 'refresh' && startCursor) {
        // Loading items in backward direction right to the start of the list
        let hasPrevious = false;
        do {
          const response = await apiClient.task.taskControllerGetTaskEvents(
            {
              taskId,
              before: data ? data.startCursor : startCursor,
              limit: 100 // max limit
            },
            { cancelToken: cts.token }
          );
          hasPrevious = response.data.hasNext;
          if (!data) {
            data = response.data;
            data.hasNext = hasMore;
          } else {
            data.startCursor = response.data.startCursor;
            const responseIds = response.data.edges.map((x) => x.node.id);
            data.edges = [
              ...response.data.edges,
              ...data.edges.filter((x) => !responseIds.includes(x.node.id))
            ];
          }
        } while (hasPrevious);
      } else {
        const response = await apiClient.task.taskControllerGetTaskEvents(
          {
            taskId,
            after:
              payload.fetchType === 'more' ? cursor ?? undefined : undefined
          },
          { cancelToken: cts.token }
        );
        data = response.data;
      }
      return data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }
);

export type CreateTaskCommentPayload = Omit<
  CreateTaskCommentDto,
  'mentions'
> & {
  mentions: TaskItemMemberDto[];
};

export const createTaskComment = createAsyncThunk(
  'tasks/createTaskComment',
  async ({ mentions, ...payload }: CreateTaskCommentPayload) => {
    try {
      const response = await apiClient.task.taskControllerCreateTaskComment({
        ...payload,
        mentions: mentions.map((x) => x.id)
      });
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }
);

interface FetchTaskCampaignsPayload {
  taskId: string;
  fetchType: Exclude<ListFetchType, 'more'>;
}

export const fetchTaskCampaigns = createAsyncThunk(
  'tasks/fetchTaskCampaigns',
  async (payload: FetchTaskCampaignsPayload, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const { taskId } = payload;
      const taskState = getTaskState(state, taskId);
      const task = nonNull(taskState.entity);
      const cts = axios.CancelToken.source();
      thunkAPI.signal.addEventListener('abort', () => cts.cancel());
      const { data } =
        await apiClient.campaign.campaignControllerGetSimpleCampaignsList(
          {
            workspaceId: task.workspaceId
          },
          { cancelToken: cts.token }
        );
      return data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }
);

interface FetchTaskBoardsPayload {
  taskId: string;
  fetchType: Exclude<ListFetchType, 'more'>;
}

export const fetchTaskBoards = createAsyncThunk(
  'tasks/fetchTaskBoards',
  async (payload: FetchTaskBoardsPayload, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const { taskId } = payload;
      const taskState = getTaskState(state, taskId);
      const task = nonNull(taskState.entity);
      const cts = axios.CancelToken.source();
      thunkAPI.signal.addEventListener('abort', () => cts.cancel());
      const { data } =
        await apiClient.taskBoard.taskBoardControllerListTaskBoards(
          {
            campaignId: task.campaign.id
          },
          { cancelToken: cts.token }
        );
      return data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }
);

interface FetchTaskAssetsPayload {
  taskId: string;
  fetchType: ListFetchType;
}

export const fetchTaskAssets = createAsyncThunk(
  'tasks/fetchTaskAssets',
  async (payload: FetchTaskAssetsPayload, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const { taskId, fetchType } = payload;
      const taskState = getTaskState(state, taskId);
      const { cursor, searchQuery, hasMore } = taskState.assets;
      const task = nonNull(taskState.entity);
      const cts = axios.CancelToken.source();
      thunkAPI.signal.addEventListener('abort', () => cts.cancel());
      const params: AssetControllerListAssetsParams = {
        workspaceId: task.workspaceId,
        campaignId: task.campaign.id,
        isFolder: false,
        flatten: true,
        searchQuery,
        orderBy: ['modifiedAt:DESC']
      };
      let data:
        | (PageDto & {
            edges: {
              node: AssetItemDto;
              cursor: string;
            }[];
          })
        | undefined;
      if (fetchType === 'refresh' && cursor) {
        // Loading items in backward direction right to the start of the list
        let hasPrevious = false;
        do {
          const response = await apiClient.asset.assetControllerListAssets(
            {
              ...params,
              before: data ? data.startCursor : cursor,
              inclusive: !data,
              limit: 100 // max limit
            },
            { cancelToken: cts.token }
          );
          hasPrevious = response.data.hasNext;
          if (!data) {
            data = response.data;
            data.hasNext = hasMore;
          } else {
            data.startCursor = response.data.startCursor;
            const responseIds = response.data.edges.map((x) => x.node.id);
            data.edges = [
              ...response.data.edges,
              ...data.edges.filter((x) => !responseIds.includes(x.node.id))
            ];
          }
        } while (hasPrevious);
      } else {
        const response = await apiClient.asset.assetControllerListAssets(
          {
            ...params,
            after: fetchType === 'more' ? cursor ?? undefined : undefined
          },
          { cancelToken: cts.token }
        );
        data = response.data;
      }
      return data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }
);

interface DeleteTaskAttachmentPayload {
  taskId: string;
  id: string;
}

export const deleteTaskAttachment = createAsyncThunk(
  'tasks/deleteTaskAttachment',
  async (payload: DeleteTaskAttachmentPayload) => {
    try {
      const { id } = payload;
      const { data } =
        await apiClient.attachment.attachmentControllerDeleteAttachment({
          id
        });
      return data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }
);

interface FetchTaskAttachmentPayload {
  taskId: string;
  id: string;
}

export const fetchTaskAttachment = createAsyncThunk(
  'tasks/fetchTaskAttachment',
  async (payload: FetchTaskAttachmentPayload, thunkAPI) => {
    try {
      const { id } = payload;
      const cts = axios.CancelToken.source();
      thunkAPI.signal.addEventListener('abort', () => cts.cancel());
      const { data } =
        await apiClient.attachment.attachmentControllerGetAttachment(
          { id },
          { cancelToken: cts.token }
        );
      return data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }
);

interface FetchDashboardTaskListPayload {
  workspaceId: string;
  fetchType: ListFetchType;
}

export const fetchDashboardTaskList = createAsyncThunk(
  'tasks/fetchDashboardTaskList',
  async (payload: FetchDashboardTaskListPayload, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const { workspaceId } = payload;
      const {
        dashboard: { cursor, hasMore, listType }
      } = getWsState(state, workspaceId);
      const cts = axios.CancelToken.source();
      thunkAPI.signal.addEventListener('abort', () => cts.cancel());
      const params: TaskControllerListTasksParams = {
        workspaceId,
        assignedToMe: listType === 'assigned' ? true : undefined,
        own: listType === 'own' ? true : undefined,
        my: listType === 'all' ? true : undefined,
        orderBy: ['dashboard:ASC'],
        statuses: ['in_progress', 'stuck', 'todo']
      };
      let data:
        | (PageDto & {
            edges: {
              node: TaskItemDto;
              cursor: string;
            }[];
          })
        | undefined;
      if (payload.fetchType === 'refresh' && cursor) {
        // Loading items in backward direction right to the start of the list
        let hasPrevious = false;
        do {
          const response = await apiClient.task.taskControllerListTasks(
            {
              ...params,
              before: data ? data.startCursor : cursor,
              inclusive: !data,
              limit: 100 // max limit
            },
            { cancelToken: cts.token }
          );
          hasPrevious = response.data.hasNext;
          if (!data) {
            data = response.data;
            data.hasNext = hasMore;
          } else {
            data.startCursor = response.data.startCursor;
            const responseIds = response.data.edges.map((x) => x.node.id);
            data.edges = [
              ...response.data.edges,
              ...data.edges.filter((x) => !responseIds.includes(x.node.id))
            ];
          }
        } while (hasPrevious);
      } else {
        const response = await apiClient.task.taskControllerListTasks(
          {
            ...params,
            after:
              payload.fetchType === 'more' ? cursor ?? undefined : undefined
          },
          { cancelToken: cts.token }
        );
        data = response.data;
      }
      return data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }
);

export const fetchDashboardTasksCount = createAsyncThunk(
  'tasks/fetchDashboardTasksCount',
  async (
    payload: Omit<FetchDashboardTaskListPayload, 'fetchType'> & {
      fetchType: ItemFetchType;
    },
    thunkAPI
  ) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const { workspaceId } = payload;
      const {
        dashboard: { listType }
      } = getWsState(state, workspaceId);
      const cts = axios.CancelToken.source();
      thunkAPI.signal.addEventListener('abort', () => cts.cancel());
      const { data } = await apiClient.task.taskControllerCountTasks(
        {
          workspaceId,
          assignedToMe: listType === 'assigned' ? true : undefined,
          own: listType === 'own' ? true : undefined,
          my: listType === 'all' ? true : undefined,
          statuses: ['in_progress', 'stuck', 'todo']
        },
        { cancelToken: cts.token }
      );
      return data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }
);

interface FetchTaskListInAssetVerionPayload {
  workspaceId: string;
  assetVersionId: string;
  fetchType: ListFetchType;
}

export const fetchTaskListInAssetVerion = createAsyncThunk(
  'tasks/fetchTaskListInAssetVerion',
  async (payload: FetchTaskListInAssetVerionPayload, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const { workspaceId, assetVersionId, fetchType } = payload;
      const avState = getAssetVersionState(state, assetVersionId);
      const {
        cursor,
        hasMore,
        searchQuery,
        orderBy,
        listType,
        hideResolvedTasks
      } = avState;
      const cts = axios.CancelToken.source();
      thunkAPI.signal.addEventListener('abort', () => cts.cancel());
      const params: TaskControllerListTasksParams = {
        workspaceId,
        assetVersionId,
        assignedToMe: listType === 'assigned' ? true : undefined,
        orderBy: [orderBy],
        searchQuery: searchQuery ?? undefined,
        statuses:
          listType === 'completed'
            ? ['done']
            : listType === 'in-progress'
            ? ['in_progress']
            : hideResolvedTasks
            ? ['in_progress', 'stuck', 'todo']
            : undefined,
        allowArchived: true
      };
      let data:
        | (PageDto & {
            edges: {
              node: TaskItemDto;
              cursor: string;
            }[];
          })
        | undefined;
      if (fetchType === 'refresh' && cursor) {
        // Loading items in backward direction right to the start of the list
        let hasPrevious = false;
        do {
          const response = await apiClient.task.taskControllerListTasks(
            {
              ...params,
              before: data ? data.startCursor : cursor,
              inclusive: !data,
              limit: 100 // max limit
            },
            { cancelToken: cts.token }
          );
          hasPrevious = response.data.hasNext;
          if (!data) {
            data = response.data;
            data.hasNext = hasMore;
          } else {
            data.startCursor = response.data.startCursor;
            const responseIds = response.data.edges.map((x) => x.node.id);
            data.edges = [
              ...response.data.edges,
              ...data.edges.filter((x) => !responseIds.includes(x.node.id))
            ];
          }
        } while (hasPrevious);
      } else {
        const response = await apiClient.task.taskControllerListTasks(
          {
            ...params,
            after: fetchType === 'more' ? cursor ?? undefined : undefined
          },
          { cancelToken: cts.token }
        );
        data = response.data;
      }
      return data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }
);

export const fetchTasksCountInAssetVerion = createAsyncThunk(
  'tasks/fetchTasksCountInAssetVerion',
  async (
    payload: Omit<FetchTaskListInAssetVerionPayload, 'fetchType'> & {
      fetchType: ItemFetchType;
    },
    thunkAPI
  ) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const { workspaceId, assetVersionId } = payload;
      const avState = getAssetVersionState(state, assetVersionId);
      const { searchQuery, listType, hideResolvedTasks } = avState;
      const cts = axios.CancelToken.source();
      thunkAPI.signal.addEventListener('abort', () => cts.cancel());
      const { data } = await apiClient.task.taskControllerCountTasks(
        {
          workspaceId,
          assetVersionId,
          assignedToMe: listType === 'assigned' ? true : undefined,
          searchQuery: searchQuery ?? undefined,
          statuses:
            listType === 'completed'
              ? ['done']
              : listType === 'in-progress'
              ? ['in_progress']
              : hideResolvedTasks
              ? ['in_progress', 'stuck', 'todo']
              : undefined,
          allowArchived: true
        },
        { cancelToken: cts.token }
      );
      return data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }
);

interface FetchAssetAssigneesPayload {
  workspaceId: string;
  assetId: string;
  fetchType: Exclude<ListFetchType, 'more'>;
}

export const fetchAssetAssignees = createAsyncThunk(
  'tasks/fetchAssetAssignees',
  async (payload: FetchAssetAssigneesPayload, thunkAPI) => {
    try {
      const { workspaceId, assetId } = payload;
      const cts = axios.CancelToken.source();
      thunkAPI.signal.addEventListener('abort', () => cts.cancel());
      const { data } =
        await apiClient.task.taskControllerListPotentialAssignees(
          {
            workspaceId,
            assetId,
            limit: 100
          },
          { cancelToken: cts.token }
        );
      return data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }
);
