import {
  TaskListState,
  TaskItemState,
  TasksInWorkspaceState,
  TasksState,
  TaskGroupItemState,
  TasksPersistedState,
  TasksInWorkspacePersistedState,
  TaskListPersistedState,
  TasksInCampaignState,
  TasksInCampaignPersistedState,
  TaskDashboardListPersistedState,
  TaskDashboardListState,
  FetchState,
  ListFetchType,
  ItemFetchType,
  TasksInAssetVersionState,
  TasksInAssetVersionPersistedState,
  TasksInAssetState
} from '@redux/types/tasks';
import { nanoid } from 'nanoid';

export function initialFetchState<
  T extends ListFetchType | ItemFetchType
>(): FetchState<T> {
  return {
    initiallyLoaded: false,
    requestId: null,
    newRequest: { key: nanoid(), fetchType: 'initial' as T }
  };
}

export function groupInitialState(): TaskGroupItemState {
  return {
    fetch: initialFetchState(),
    countFetch: initialFetchState(),
    name: '',
    hasMore: false,
    count: 0,
    cursor: null,
    overdueTaskIds: [],
    dueSoonTaskIds: [],
    taskIds: []
  };
}

export function listInitialState(
  persisted?: TaskListPersistedState
): TaskListState {
  return {
    fetch: initialFetchState(),
    countFetch: initialFetchState(),
    scrollTop: persisted?.scrollTop ?? 0,
    hasMore: false,
    cursor: null,
    count: persisted?.count ?? 0,
    taskIds: [],
    groups: {
      board: {
        fetch: initialFetchState(),
        count: persisted?.groups.board.count ?? 0,
        ids: [],
        openedIds: persisted?.groups.board.openedIds ?? [],
        items: {}
      },
      campaign: {
        fetch: initialFetchState(),
        count: persisted?.groups.campaign.count ?? 0,
        ids: [],
        openedIds: persisted?.groups.campaign.openedIds ?? [],
        items: {}
      }
    }
  };
}

export function dashboardListInitialState(
  persisted?: TaskDashboardListPersistedState
): TaskDashboardListState {
  return {
    fetch: initialFetchState(),
    countFetch: initialFetchState(),
    listType: persisted?.listType || 'all',
    hasMore: false,
    cursor: null,
    count: persisted?.count ?? 0,
    taskIds: []
  };
}

export function itemInitialState(): TaskItemState {
  return {
    fetch: initialFetchState(),
    entity: null,
    permanentlyDeleted: false,
    notFound: false,
    events: {
      fetch: initialFetchState(),
      list: [],
      hasMore: false,
      startCursor: null,
      cursor: null
    },
    campaigns: {
      fetch: initialFetchState(),
      list: []
    },
    boards: {
      fetch: initialFetchState(),
      list: []
    },
    assets: {
      fetch: initialFetchState(),
      list: [],
      searchQuery: '',
      hasMore: false,
      cursor: null
    },
    potentialAssignees: {
      fetch: initialFetchState(),
      list: [],
      searchQuery: ''
    },
    descriptionMentions: {
      fetch: initialFetchState(),
      list: [],
      searchQuery: ''
    },
    commentMentions: {
      fetch: initialFetchState(),
      list: [],
      searchQuery: ''
    }
  };
}

export function tasksInWorkspaceInitialState(
  persisted?: TasksInWorkspacePersistedState
): TasksInWorkspaceState {
  return {
    listsShared: {
      orderBy: persisted?.listsShared.orderBy ?? 'createdAt:DESC',
      groupBy: persisted?.listsShared.groupBy ?? null,
      searchQuery: persisted?.listsShared.searchQuery ?? null,
      selectedCampaigns: persisted?.listsShared.selectedCampaigns ?? [],
      statuses: persisted?.listsShared.statuses ?? [],
      dueDate: persisted?.listsShared.dueDate ?? null,
      assignees: persisted?.listsShared.assignees ?? [],
      potentialAssignees: {
        fetch: initialFetchState(),
        list: [],
        searchQuery: ''
      },
      campaigns: {
        fetch: initialFetchState(),
        list: []
      }
    },
    lists: {
      all: listInitialState(persisted?.lists.all),
      my: listInitialState(persisted?.lists.my),
      assigned: listInitialState(persisted?.lists.assigned),
      deleted: listInitialState(persisted?.lists.deleted)
    },
    dashboard: dashboardListInitialState(persisted?.dashboard)
  };
}
export function tasksInCampaignInitialState(
  persisted?: TasksInCampaignPersistedState
): TasksInCampaignState {
  return {
    orderBy: persisted?.orderBy ?? 'order:ASC',
    searchQuery: persisted?.searchQuery ?? null,
    count: persisted?.count ?? 0,
    scrollTop: persisted?.scrollTop ?? 0,
    boards: {
      fetch: initialFetchState(),
      count: persisted?.boards.count ?? 0,
      ids: [],
      openedIds: persisted?.boards.openedIds ?? [],
      items: {}
    },
    customFields: {
      fetch: initialFetchState(),
      list: persisted?.customFields.list.map((x) => ({ ...x, tags: [] })) ?? []
    }
  };
}

export function tasksInAssetVersionInitialState(
  persisted?: TasksInAssetVersionPersistedState
): TasksInAssetVersionState {
  return {
    orderBy: persisted?.orderBy ?? 'createdAt:DESC',
    searchQuery: persisted?.searchQuery ?? null,
    listType: persisted?.listType ?? 'all',
    hideResolvedTasks: persisted?.hideResolvedTasks ?? false,
    fetch: initialFetchState(),
    countFetch: initialFetchState(),
    hasMore: false,
    cursor: null,
    count: persisted?.count ?? 0,
    taskIds: []
  };
}

export function tasksInAssetInitialState(): TasksInAssetState {
  return {
    potentialAssignees: {
      fetch: initialFetchState(),
      list: []
    }
  };
}

export function initialState(persisted?: TasksPersistedState): TasksState {
  const byWorkspace: Record<string, TasksInWorkspaceState | undefined> = {};
  const byCampaign: Record<string, TasksInCampaignState | undefined> = {};
  const byAssetVersion: Record<string, TasksInAssetVersionState | undefined> =
    {};
  if (persisted) {
    Object.entries(persisted.byWorkspace).forEach(([workspaceId, state]) => {
      byWorkspace[workspaceId] = tasksInWorkspaceInitialState(state);
    });
    Object.entries(persisted.byCampaign).forEach(([campaignId, state]) => {
      byCampaign[campaignId] = tasksInCampaignInitialState(state);
    });
    Object.entries(persisted.byAssetVersion).forEach(
      ([assetVersionId, state]) => {
        byAssetVersion[assetVersionId] = tasksInAssetVersionInitialState(state);
      }
    );
  }
  return {
    byWorkspace,
    byCampaign,
    byAssetVersion,
    byAsset: {},
    items: {},
    newTasks: [],
    modal: null,
    undo: {},
    countsToRefresh: {}
  };
}
