import React, { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Button } from 'antd';
import { resendTwoStepVerificationCode, twoStepVerify } from '@api/Profile';
import { chooseEmailService } from '@helpers/auth';
import { ReactComponent as CircleArrowLeft } from '@assets/icons/arrow-left.svg';
import { AUTH0_DOMAIN } from '@config/constants';
import CodeLine from '@components/CodeLine';
import LottieComponent from '@components/Lottie';

const CODELINE_LENGTH = 6;

function TwoStepVerification() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [isTwoStepConfirmed, setIsTwoStepConfirmed] = useState(false);
  const [error, setError] = useState<string | boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);

  const onTwoStepVerify = async (code: string) => {
    try {
      const token = searchParams.get('authorization_bearer');
      await twoStepVerify(
        { code },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setIsTwoStepConfirmed(true);
      setTimeout(() => {
        const qs = new URLSearchParams(searchParams);
        qs.delete('authorization_bearer');
        window.location.href = `https://${AUTH0_DOMAIN}/continue?${qs.toString()}`;
      }, 4000);
    } catch {
      setError('wrong code');
    }
  };

  const handleChange = async (code: string) => {
    setError(false);
    if (code.length === CODELINE_LENGTH) {
      setDisabled(true);
      await onTwoStepVerify(code);
      setDisabled(false);
    }
  };

  const { service, Logo, link } = chooseEmailService(
    searchParams?.get('email') || ''
  );
  const userEmail = searchParams
    .get('email')
    ?.replace(/(\S{1})(\S+?)(?=[@])/g, (a, b, c) => b + c.replace(/./g, '*'));
  return (
    <div className="onboarding_container">
      <div className="onboarding_container__secure">
        <div
          className="step_container"
          style={{ maxWidth: 420, margin: '0 auto' }}
        >
          <p className="step_container__title">
            {!isTwoStepConfirmed ? 'Two-step verification' : 'Code accepted'}
          </p>
          <p className="step_container__description">
            {!isTwoStepConfirmed ? (
              <>
                Enter below the two-step verification code sent to{' '}
                <span style={{ color: '#161819', fontWeight: 'bold' }}>
                  {userEmail}
                </span>
              </>
            ) : (
              'We’re thrilled to have you on board!'
            )}
          </p>
          <div className="step_container__body" style={{ textAlign: 'center' }}>
            {!isTwoStepConfirmed ? (
              <div>
                <Button
                  type="text"
                  size="large"
                  className="provider"
                  href={link}
                  target="_blank"
                >
                  <Logo />
                  Go To {service}
                </Button>
                <CodeLine
                  length={CODELINE_LENGTH}
                  onChange={(code) => handleChange(code)}
                  isValid={!error}
                  disabled={disabled}
                />
                <p className="resend">
                  <Button
                    type="link"
                    onClick={async () => {
                      const token = searchParams.get('authorization_bearer');
                      await resendTwoStepVerificationCode(
                        { forDisable: false },
                        {
                          headers: {
                            Authorization: `Bearer ${token}`
                          }
                        }
                      );
                    }}
                  >
                    Send code
                  </Button>
                  <span>again or chat with</span>
                  <Button type="link">our support team</Button>
                </p>
              </div>
            ) : (
              <div className="success">
                <LottieComponent view="success" />
              </div>
            )}
          </div>
        </div>
        <div className="onboarding_container__controller">
          <Button
            className="onboarding_container__controller_button onboarding_container__controller_button--prev"
            type="text"
            onClick={() => navigate('../')}
          >
            <div className="icon">
              <CircleArrowLeft />
            </div>
            Back
          </Button>
        </div>
      </div>
    </div>
  );
}

export default TwoStepVerification;
