import React from 'react';
import './ErrorPage.less';

import { ReactComponent as LogoSvg } from '@assets/icons/logo.svg';
import { ReactComponent as AlienSvg } from '@assets/icons/alien-icon.svg';
import { ReactComponent as AlienArrowSvg } from '@assets/icons/alien-arrow-icon.svg';
import { ReactComponent as BigBorderSvg } from '@assets/icons/error-page-big-dashed.svg';
import { ReactComponent as SmallBorderSvg } from '@assets/icons/error-page-small-dashed.svg';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';

function ErrorPage({
  content,
  buttonCallback,
  buttonCopy = 'Go back'
}: {
  content: any;
  buttonCallback(): void;
  buttonCopy?: string;
}) {
  const navigate = useNavigate();

  return (
    <div className="error-page-container">
      <div className="big-background-round">
        <BigBorderSvg />
      </div>
      <div className="small-background-round">
        <SmallBorderSvg />
      </div>
      <LogoSvg className="logo" onClick={() => navigate('/campaigns/all')} />
      <div style={{ position: 'relative' }}>
        {content}
        <Button type="primary" className="back-button" onClick={buttonCallback}>
          {buttonCopy}
        </Button>
        <AlienSvg className="alien-icon" />
        <AlienArrowSvg className="alien-arrow-icon" />
      </div>
    </div>
  );
}

export default ErrorPage;
