import ErrorNetworkPage from '@pages/ErrorPage/ErrorNetworkPage';
import ErrorPage500 from '@pages/ErrorPage/ErrorPage500';
import { isAxiosError, isCancel } from 'axios';
import React from 'react';

const importModuleErrors: readonly string[] = [
  'Failed to fetch dynamically imported module',
  'Failed to load module script',
  'error loading dynamically imported module'
];

export default class ErrorBoundary extends React.Component {
  state = { hasError: false, isNetworkError: false };

  static getDerivedStateFromError(err: any) {
    let isNetworkError = false;
    if (isAxiosError(err)) {
      const { response } = err;
      isNetworkError = !response && !isCancel(err);
    } else if (err instanceof Error) {
      isNetworkError = importModuleErrors.some((x) => err.message.includes(x));
    }
    return { hasError: true, isNetworkError };
  }

  render() {
    if (this.state.hasError) {
      return this.state.isNetworkError ? (
        <ErrorNetworkPage />
      ) : (
        <ErrorPage500 />
      );
    }
    return this.props.children;
  }
}
