import OnboardingProcess from '@components/OnboardingProcess/OnboardingProcess';
import { useAuth } from '@hooks/useAuth';
import React, {
  createContext,
  useMemo,
  ReactElement,
  useState,
  useCallback
} from 'react';
import AppTour from '@components/AppTour/AppTour';
import { CompleteOnboardingStepDto } from '@api/Api';
import { completeOnboardingStep } from '@api/Profile';

interface IOnboardingProcessContext {
  onCompleteStep: ({
    type,
    targetId,
    hideCongrats
  }: {
    type: OnboardingStepsType;
    targetId?: string | null;
    hideCongrats?: boolean;
  }) => void;
  setGoToStep: (v: number) => void;
  onStartTour: ({
    type,
    theme
  }: {
    type: OnboardingStepsType | undefined;
    theme?: 'dark';
  }) => void;
}

export const OnboardingProcessContext =
  createContext<IOnboardingProcessContext>({
    onCompleteStep: () => undefined,
    onStartTour: () => undefined,
    setGoToStep: () => undefined
  } as any);

export type OnboardingStepsType =
  | CompleteOnboardingStepDto['type']
  | 'revisit-onboarding'
  | 'first-enter-steps';

const stepCopies: {
  [index: string]: { title: string; description: string; button: string };
} = {
  'create-campaign': {
    title: 'Nice work!',
    description:
      'You created your first campaign. Continue with onboarding to learn what to do next.',
    button: 'Continue onboarding'
  },
  'create-task': {
    title: 'Voila!',
    description:
      'Now, you can project manage all of your campaign deliverables in StreamWork.',
    button: 'Continue onboarding'
  },
  'upload-asset': {
    title: 'Great job!',
    description:
      'You just uploaded your first media. Now, learn how to collaborate on an media.',
    button: 'Continue onboarding'
  },
  'create-task-from-comment': {
    title: 'Woo hoo!',
    description:
      'You’re a collaboration superstar. Complete the rest of onboarding to get the most out of StreamWork.',
    button: 'Continue onboarding'
  },
  'route-for-approval': {
    title: 'You did it!',
    description:
      'Take advantage of approval routing anytime you need to collect approvals from multiple stakeholders or departments.',
    button: 'Continue onboarding'
  }
};

type OnboardingProcessContextProps = {
  children: ReactElement | ReactElement[];
};

export function OnboardingProcessProvider({
  children
}: OnboardingProcessContextProps) {
  const [tourData, setTourData] = useState<{
    type: OnboardingStepsType;
    theme?: 'dark' | 'white';
  }>();
  const [goToStep, setGoToStep] = useState<number>(0);
  const { user, updateUserProfile } = useAuth(false);
  const [showProcess, setShowProcess] = useState<OnboardingStepsType | false>(
    false
  );

  const onOpenProcess = useCallback((type) => {
    setShowProcess(type);
  }, []);

  const onCloseProcess = useCallback(() => {
    setShowProcess(false);
  }, []);

  const onCompleteStep = useCallback(
    ({
      type,
      hideCongrats
    }: {
      type: OnboardingStepsType;
      hideCongrats?: boolean;
    }) => {
      if (!user?.completedOnboardingSteps) return;
      if (type !== 'revisit-onboarding' && type !== 'first-enter-steps') {
        if (user.completedOnboardingSteps.includes(type)) return;
        updateUserProfile({
          completedOnboardingSteps: [...user.completedOnboardingSteps, type]
        });
        if (user.type === 'internal') completeOnboardingStep({ type });
      }
      if (!hideCongrats) onOpenProcess(type);
    },
    [
      onOpenProcess,
      updateUserProfile,
      user?.completedOnboardingSteps,
      user?.type
    ]
  );

  const onStartTour = useCallback(
    ({
      type,
      theme
    }: {
      type: OnboardingStepsType | undefined;
      theme?: 'white' | 'dark';
    }) => {
      if (!type) return;
      setTourData({ type, theme });
    },
    []
  );

  const providerValue = useMemo(() => {
    return { onCompleteStep, onStartTour, setGoToStep };
  }, [onCompleteStep, onStartTour]);

  const propsValue = useMemo(() => {
    return {
      copies: showProcess ? stepCopies[showProcess] : undefined,
      onCloseProcess,
      onStartTour,
      user
    };
  }, [onCloseProcess, showProcess, user, onStartTour]);

  return (
    <OnboardingProcessContext.Provider value={providerValue}>
      {showProcess && <OnboardingProcess {...propsValue} />}
      {children}
      <AppTour
        goToStep={goToStep}
        setGoToStep={setGoToStep}
        tourData={tourData}
        setTourData={setTourData}
        onCompleteStep={onCompleteStep}
        onCloseProcess={onCloseProcess}
      />
    </OnboardingProcessContext.Provider>
  );
}

export default OnboardingProcessProvider;
