import React, { useRef } from 'react';
import './ErrorPage.less';

import { ReactComponent as InternalServerErrorIcon } from '@assets/icons/error-500-icon.svg';
import { ErrorPage } from '@pages/ErrorPage';
import {
  NavigationType,
  useLocation,
  useNavigate,
  useNavigationType
} from 'react-router-dom';

function ErrorPage500() {
  const navigationType = useNavigationType();
  const navigate = useNavigate();
  const location = useLocation();
  const isErrorRoute = location.pathname === '/error-500';
  const currentKey = location.key;
  const prevKey = useRef(currentKey);

  if (currentKey !== prevKey.current) {
    prevKey.current = currentKey;
    if (!isErrorRoute) window.location.reload();
  }
  return (
    <ErrorPage
      content={
        <div style={{ textAlign: 'center' }}>
          <InternalServerErrorIcon />
          <div className="text-container" style={{ marginTop: 56 }}>
            <span>Internal server error</span>
            <span style={{ maxWidth: 384 }}>
              We&apos;ve encountered a problem and the page you are looking for
              cannot be displayed.
            </span>
          </div>
        </div>
      }
      buttonCallback={() => {
        if (navigationType === NavigationType.Push) {
          navigate(isErrorRoute ? -2 : -1);
        } else if (currentKey !== 'default') {
          navigate(-1);
        } else {
          navigate('/campaigns/all', { replace: true });
        }
      }}
    />
  );
}

export default ErrorPage500;
