import { debounce } from '@helpers/debounce';
import {
  IOrganizationPersistedState,
  IOrganizationState
} from '@redux/types/organizations';

const key = 'store_organizations';

export const persistState = debounce((state: IOrganizationState) => {
  const toPersist: IOrganizationPersistedState = {
    list: {
      count: state.list.count
    }
  };
  sessionStorage.setItem(key, JSON.stringify(toPersist));
}, 500);

export function getPersistedState(): IOrganizationPersistedState | undefined {
  const json = sessionStorage.getItem(key);
  if (!json) return undefined;
  try {
    return JSON.parse(json);
  } catch (err) {
    console.error(err);
    return undefined;
  }
}
