import React from 'react';
import { ReactComponent as LogoSvg } from '@assets/icons/logo.svg';
import { ReactComponent as ExpiredSvg } from '@assets/icons/expired-icon.svg';
import './ExpiredLink.less';
import { useAuth } from '@hooks/useAuth';

function ExpiredLink() {
  const { login } = useAuth();
  return (
    <div className="expired_link_container">
      <div className="expired_link_container__logotype">
        <LogoSvg className="logo" />
      </div>
      <div className="expired_link_container__body">
        <ExpiredSvg />
        <div className="title">This link has expired</div>
        <div className="description">
          Contact the sender to access the media.
        </div>
      </div>
      <div className="expired_link_container__footer">
        New to StreamWork? Create your{' '}
        <span
          className="link"
          onClick={() => {
            login({
              prompt: 'login',
              appState: { returnTo: '/campaigns/all' },
              targetPage: 'register'
            });
          }}
        >
          own account
        </span>{' '}
        and start collaborating.
      </div>
    </div>
  );
}

export default ExpiredLink;
