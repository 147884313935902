import React from 'react';
import { ReactComponent as LogoSvg } from '@assets/icons/logo.svg';
import { ReactComponent as ExpiredSvg } from '@assets/icons/expired-icon.svg';
import '@components/ExpiredLink/ExpiredLink.less';
import ExpiredLink from '@components/ExpiredLink';
import { useNavigate, useSearchParams } from 'react-router-dom';

export default function InvitationExpired() {
  const [params] = useSearchParams();
  const type = params.get('type');
  const navigate = useNavigate();
  if (type === 'asset-review') return <ExpiredLink />;
  return (
    <div className="expired_link_container">
      <a
        className="expired_link_container__logotype"
        href="/"
        onClick={(e) => {
          e.preventDefault();
          navigate('/');
        }}
      >
        <LogoSvg className="logo" />
      </a>
      <div className="expired_link_container__body">
        <ExpiredSvg />
        <div className="title">The invite has expired</div>
        <div className="description">
          Please contact the workspace owner to resend the invitation.
        </div>
      </div>
      <div className="expired_link_container__footer" style={{ height: 36 }} />
    </div>
  );
}
