import {
  IAssetsState,
  IAssetsAction,
  AssetsActionTypes
} from '@redux/types/assetsType';

const INITIAL_STATE: IAssetsState = {
  files: {
    edges: [],
    count: 0,
    hasNext: false,
    startCursor: '',
    endCursor: ''
  },
  folders: {
    edges: [],
    count: 0,
    hasNext: false,
    startCursor: '',
    endCursor: ''
  },
  selectedFolder: null
};

const assetsReducer = (
  state = INITIAL_STATE,
  action: IAssetsAction
): IAssetsState => {
  switch (action.type) {
    case AssetsActionTypes.UPDATE_FOLDER_COUNTERS: {
      const { event, folderId } = action.payload;
      let totalAssetsCount = 0;
      let assetsCount = 0;
      if (event === 'addFile') {
        totalAssetsCount =
          (state.selectedFolder?.folder?.totalAssetsCount || 0) + 1;
        assetsCount = (state.selectedFolder?.folder?.assetsCount || 0) + 1;
      }
      if (event === 'deleteFile') {
        totalAssetsCount =
          (state.selectedFolder?.folder?.totalAssetsCount || 1) - 1;
        assetsCount = (state.selectedFolder?.folder?.assetsCount || 1) - 1;
      }

      const selectedFolder = state.selectedFolder?.folder
        ? {
            ...state.selectedFolder,
            folder: {
              ...state.selectedFolder.folder,
              assetsCount: Math.max(0, assetsCount),
              totalAssetsCount: Math.max(0, totalAssetsCount)
            }
          }
        : null;
      const newEdges =
        state.folders.edges?.map((el) => {
          if (el.node.folder && el.node.id === folderId)
            return {
              ...el,
              node: {
                ...el.node,
                folder: {
                  ...el.node.folder,
                  assetsCount: Math.max(0, assetsCount),
                  totalAssetsCount: Math.max(0, totalAssetsCount)
                }
              }
            };
          return el;
        }) || [];
      return {
        ...state,
        selectedFolder,
        folders: {
          ...state.folders,
          edges: newEdges
        }
      };
    }
    case AssetsActionTypes.SET_ASSETS_LIST: {
      return {
        ...state,
        files: { ...state.files, ...action.payload }
      };
    }
    case AssetsActionTypes.SET_FOLDERS_LIST: {
      return {
        ...state,
        folders: { ...state.folders, ...action.payload }
      };
    }
    case AssetsActionTypes.ADD_NEW_ASSET: {
      if (!state.files.edges) return state;
      const { asset } = action.payload;
      return {
        ...state,
        files: {
          ...state.files,
          edges: [{ node: asset, cursor: '' }, ...state.files.edges],
          count: (state.files.count || 0) + 1
        }
      };
    }
    case AssetsActionTypes.UPDATE_ASSET: {
      if (!state.files.edges) return state;
      const { asset } = action.payload;
      const newEdges = state.files.edges.map((el) => {
        if (el.node.id === asset.id)
          return {
            ...el,
            node: asset
          };
        return el;
      });
      return {
        ...state,
        files: {
          ...state.files,
          edges: newEdges
        }
      };
    }
    case AssetsActionTypes.UPDATE_FOLDER: {
      if (!state.folders.edges) return state;
      const { folder } = action.payload;
      const edges = state.folders.edges.map((el) => {
        if (el.node.id === folder.id)
          return {
            ...el,
            node: folder
          };
        return el;
      });
      let { selectedFolder } = state;
      if (selectedFolder && selectedFolder.id === folder.id)
        selectedFolder = folder;
      return {
        ...state,
        selectedFolder,
        folders: {
          ...state.folders,
          edges
        }
      };
    }
    case AssetsActionTypes.LOAD_MORE_ASSETS: {
      return {
        ...state,
        files: {
          ...state.files,
          ...action.payload,
          edges: [...(state.files.edges || []), ...action.payload.edges]
        }
      };
    }
    case AssetsActionTypes.LOAD_MORE_FOLDERS: {
      return {
        ...state,
        folders: {
          ...state.folders,
          ...action.payload,
          edges: [...(state.folders.edges || []), ...action.payload.edges]
        }
      };
    }
    case AssetsActionTypes.DELETE_ASSET: {
      const { assetId, folderId } = action.payload;
      const newEdges =
        state.files.edges?.filter((el) => el.node.id !== assetId) || [];
      let { folders, selectedFolder } = state;
      if (folderId) {
        if (folders.edges)
          folders = {
            ...folders,
            edges: folders.edges.map((f) => {
              if (f.node.id === folderId && f.node.folder)
                return {
                  ...f,
                  node: {
                    ...f.node,
                    folder: {
                      ...f.node.folder,
                      assetsCount: f.node.folder.assetsCount - 1,
                      totalAssetsCount: f.node.folder.totalAssetsCount - 1
                    }
                  }
                };
              return f;
            })
          };
        if (selectedFolder?.folder && selectedFolder.id === folderId) {
          selectedFolder = {
            ...selectedFolder,
            folder: {
              ...selectedFolder.folder,
              totalAssetsCount: selectedFolder.folder.totalAssetsCount - 1,
              assetsCount: selectedFolder.folder.assetsCount - 1
            }
          };
        }
      }

      return {
        ...state,
        folders,
        selectedFolder,
        files: {
          ...state.files,
          edges: newEdges,
          count: (state?.files.count || 1) - 1
        }
      };
    }
    case AssetsActionTypes.RESTORE_DELETED_ASSET: {
      const { assetId } = action.payload;
      const newEdges =
        state.files.edges?.filter((el) => el.node.id !== assetId) || [];
      return {
        ...state,
        files: {
          ...state.files,
          edges: newEdges,
          count: (state?.files.count || 1) - 1
        }
      };
    }
    case AssetsActionTypes.RESTORE_DELETED_FOLDER: {
      const { folderId } = action.payload;
      const newEdges =
        state.folders.edges?.filter((el) => el.node.id !== folderId) || [];
      return {
        ...state,
        folders: {
          ...state.folders,
          edges: newEdges,
          count: (state?.files.count || 1) - 1
        }
      };
    }
    case AssetsActionTypes.SET_FAVORITE_ASSET: {
      const { assetId, isFavorite, needRemoveFromList } = action.payload;
      let newEdges = state.files.edges;
      if (needRemoveFromList) {
        newEdges = newEdges?.filter((el) => el.node.id !== assetId) || [];
        return {
          ...state,
          files: {
            ...state.files,
            edges: newEdges,
            count: (state.files.count || 1) - 1
          }
        };
      }
      newEdges =
        state.files.edges?.map((el) => {
          if (el.node.id === assetId)
            return {
              ...el,
              node: {
                ...el.node,
                isFavorite
              }
            };

          return el;
        }) || [];
      return {
        ...state,
        files: {
          ...state.files,
          edges: newEdges
        }
      };
    }
    case AssetsActionTypes.RENAME_ASSET: {
      const { name, assetId, assetVersionId } = action.payload;
      const assetToRename: any = state.files.edges?.find(
        (el) => el.node.id === assetId
      );
      if (!assetToRename) return state;
      const newAssetVersions =
        assetToRename.node.asset?.versions.map((el: any) => {
          if (el.id === assetVersionId)
            return {
              ...el,
              name
            };

          return el;
        }) || [];
      const newEdges =
        state.files.edges?.map((el) => {
          if (el.node.id === assetId) {
            return {
              ...el,
              node: {
                ...el.node,
                modifiedAt: new Date().toISOString(),
                asset: el.node.asset
                  ? {
                      ...el.node.asset,
                      versions: newAssetVersions
                    }
                  : undefined
              }
            };
          }

          return el;
        }) || [];
      return {
        ...state,
        files: {
          ...state.files,
          edges: newEdges
        }
      };
    }
    case AssetsActionTypes.SET_FOLDER_ACCESSABILITY: {
      const { folderId, isPrivate } = action.payload;
      let { selectedFolder } = state;
      if (selectedFolder && selectedFolder.id === folderId) {
        selectedFolder = {
          ...selectedFolder,
          isPrivate
        };
      }
      const newEdges =
        state.folders.edges?.map((el) => {
          if (el.node.id === folderId)
            return {
              ...el,
              node: {
                ...el.node,
                isPrivate
              }
            };
          return el;
        }) || [];
      return {
        ...state,
        selectedFolder,
        folders: {
          ...state.folders,
          edges: newEdges
        }
      };
    }
    case AssetsActionTypes.RENAME_FOLDER: {
      const { folderId, name } = action.payload;
      let { selectedFolder } = state;
      if (selectedFolder && selectedFolder.id === folderId) {
        if (selectedFolder.folder)
          selectedFolder = {
            ...selectedFolder,
            folder: {
              ...selectedFolder.folder,
              name
            }
          };
      }
      const newEdges =
        state.folders.edges?.map((el) => {
          if (!el.node.folder) return el;
          if (el.node.id === folderId)
            return {
              ...el,
              node: {
                ...el.node,
                modifiedAt: new Date().toISOString(),
                folder: {
                  ...el.node.folder,
                  name
                }
              }
            };
          return el;
        }) || [];
      return {
        ...state,
        selectedFolder,
        folders: {
          ...state.folders,
          edges: newEdges
        }
      };
    }
    case AssetsActionTypes.DELETE_FOLDER: {
      const { folderId } = action.payload;
      const newEdges =
        state.folders.edges?.filter((el) => el.node.id !== folderId) || [];
      return {
        ...state,
        folders: {
          ...state.folders,
          edges: newEdges,
          count: (state.folders.count || 1) - 1
        }
      };
    }
    case AssetsActionTypes.CREATE_FOLDER: {
      const { folder } = action.payload;
      const newEdges = [
        { node: folder, cursor: '' },
        ...(state.folders.edges || [])
      ];
      return {
        ...state,
        folders: {
          ...state.folders,
          edges: newEdges,
          count: (state.folders.count || 0) + 1
        }
      };
    }
    case AssetsActionTypes.SET_FOLDER: {
      const { folder } = action.payload;
      return {
        ...state,
        selectedFolder: folder
      };
    }
    case AssetsActionTypes.UPDATE_FOLDERS_LIST: {
      const { folder } = action.payload;
      let { selectedFolder, folders } = state;
      if (selectedFolder?.folder && selectedFolder.id === folder.id) {
        selectedFolder = {
          ...selectedFolder,
          folder: {
            ...selectedFolder.folder,
            assetsCount: selectedFolder.folder.assetsCount + 1,
            totalAssetsCount: selectedFolder.folder.totalAssetsCount + 1
          }
        };
      }
      if (folders.edges) {
        folders = {
          ...folders,
          edges: folders.edges.map((f) => {
            if (f.node.folder && f.node.id === folder.id)
              return {
                ...f,
                node: {
                  ...f.node,
                  folder: {
                    ...f.node.folder,
                    totalAssetsCount: f.node.folder.totalAssetsCount + 1,
                    assetsCount: f.node.folder.assetsCount + 1
                  }
                }
              };
            return f;
          })
        };
      }
      return {
        ...state,
        folders,
        selectedFolder
      };
    }
    case AssetsActionTypes.MOVE_ASSET_TO_FOLDER: {
      const { assetId, folderId, oldPath } = action.payload;
      const newEdges =
        state.files.edges?.filter((el) => el.node.id !== assetId) || [];
      let newFolderEdges = state.folders.edges;
      if (folderId && newFolderEdges)
        newFolderEdges = newFolderEdges.map((el) => {
          if (el.node.id === folderId && el.node.folder) {
            return {
              ...el,
              node: {
                ...el.node,
                folder: {
                  ...el.node.folder,
                  assetsCount: el.node.folder.assetsCount + 1,
                  totalAssetsCount: el.node.folder.totalAssetsCount + 1
                }
              }
            };
          }
          if (
            oldPath &&
            oldPath.find((it: any) => it.id === el.node.id) &&
            el.node.folder
          ) {
            return {
              ...el,
              node: {
                ...el.node,
                folder: {
                  ...el.node.folder,
                  assetsCount: el.node.folder.assetsCount - 1,
                  totalAssetsCount: el.node.folder.totalAssetsCount - 1
                }
              }
            };
          }

          return el;
        });
      let { selectedFolder } = state;
      if (
        selectedFolder?.folder &&
        oldPath &&
        oldPath.find((it: any) => it.id === selectedFolder?.id)
      ) {
        selectedFolder = {
          ...selectedFolder,
          folder: {
            ...selectedFolder.folder,
            assetsCount: selectedFolder.folder.assetsCount - 1,
            totalAssetsCount: selectedFolder.folder.totalAssetsCount - 1
          }
        };
      }
      return {
        ...state,
        folders: {
          ...state.folders,
          edges: newFolderEdges
        },
        files: {
          ...state.files,
          edges: newEdges
        },
        selectedFolder
      };
    }
    case AssetsActionTypes.ADD_ASSET_TO_STACK: {
      if (!state.files.edges) return state;
      const { assetId, toAssetId, asset } = action.payload;
      const newEdges = state.files.edges
        .filter(({ node }) => node.id !== assetId)
        .map((el) => {
          if (asset && el.node.id === toAssetId)
            return {
              ...el,
              node: { ...asset }
            };
          return el;
        });
      return {
        ...state,
        files: {
          ...state.files,
          edges: newEdges,
          count: (state.files.count || 1) - 1
        }
      };
    }
    case AssetsActionTypes.REMOVE_ASSET_FROM_STACK: {
      if (!state.files.edges) return state;
      let indexToPutNewAsset = 0;
      const { assetId, currenAsset, newAsset } = action.payload;
      const newEdges = state.files.edges.map((el, idx) => {
        if (el.node.id === assetId) {
          indexToPutNewAsset = idx + 1;
          return {
            ...el,
            node: { ...currenAsset }
          };
        }
        return el;
      });
      newEdges.splice(indexToPutNewAsset, 0, { node: newAsset, cursor: '' });
      return {
        ...state,
        files: {
          ...state.files,
          edges: newEdges,
          count: (state.files.count || 0) + 1
        }
      };
    }
    default: {
      return state;
    }
  }
};

export default assetsReducer;
