import { Dispatch } from 'redux';
import {
  AssetControllerGetAssetParams,
  AssetVersionCommentDto,
  SetAssetFavoriteDto,
  AssetVersionItemDto,
  AssetItemDto
} from '@api/Api';
import { getAsset, setFavoriteAsset } from '@api/Asset';
import {
  MediaViewerActionTypes,
  IMediaViewerAction
} from '@redux/types/mediaViewerType';
import { decisionApprovalWorkflow } from '@api/Approval';

export const getAssetItem =
  (params: AssetControllerGetAssetParams) =>
  async (dispatch: Dispatch<IMediaViewerAction>) => {
    const {
      data: { asset }
    } = await getAsset(params);
    dispatch(setAsset(asset));
    return asset;
  };

export const getAssetFolder =
  (params: AssetControllerGetAssetParams) =>
  async (dispatch: Dispatch<IMediaViewerAction>) => {
    const {
      data: { asset }
    } = await getAsset(params);
    dispatch(setAssetFolder(asset));
    return asset;
  };

export const setAsset = (asset: any) => ({
  type: MediaViewerActionTypes.SET_ASSET,
  payload: asset
});

export const setAssetFolder = (folder: any) => ({
  type: MediaViewerActionTypes.SET_ASSET_FOLDER,
  payload: folder
});

export const setAssetVariant = (id: string | null) => ({
  type: MediaViewerActionTypes.SET_SELECTED_VERSION,
  payload: { id }
});

export const setPlayerTime = (time: (number | null)[]) => ({
  type: MediaViewerActionTypes.SET_PLAYER_TIME,
  payload: time
});

export const setActivePageNumber = (page: number) => ({
  type: MediaViewerActionTypes.SET_ACTIVE_PAGE_NUMBER,
  payload: page
});

export const setSelectedCanvasObject = (selected: boolean) => ({
  type: MediaViewerActionTypes.SET_SELECTED_CANVAS_OBJECT,
  payload: selected
});

export const setCanvasMode = (mode: 'edit' | 'view') => ({
  type: MediaViewerActionTypes.SET_CANVAS_MODE,
  payload: mode
});

// COMMENTS
export const selectComment = (id: string | null) => ({
  type: MediaViewerActionTypes.SELECT_COMMENT,
  payload: id
});

export const getAssetInternalComments = (
  comments: AssetVersionCommentDto[]
) => ({
  type: MediaViewerActionTypes.GET_ASSET_INTERNAL_COMMENTS,
  payload: comments
});

export const createNewComment = (newComment: AssetVersionCommentDto) => ({
  type: MediaViewerActionTypes.CREATE_ASSET_INTERNAL_COMMENT,
  payload: newComment
});

export const editComment = (comment: AssetVersionCommentDto) => {
  return {
    type: MediaViewerActionTypes.UPDATE_ASSET_INTERNAL_COMMENT,
    payload: {
      ...comment,
      updatedAt: new Date().toISOString()
    }
  };
};

export const setCommentLike = (params: any, user: any) => ({
  type: MediaViewerActionTypes.SET_ASSET_INTERNAL_COMMENT_LIKE,
  payload: { params, user }
});

export const removeComment = (id: string) => ({
  type: MediaViewerActionTypes.DELETE_ASSET_INTERNAL_COMMENT,
  payload: id
});

export const updateAssetSelectedVersion = (data: {
  newSelectedVersion: AssetVersionItemDto;
}) => ({
  type: MediaViewerActionTypes.UPDATE_SELECTED_VERSION,
  payload: data
});

export const makeApprovalWorkflowDecision =
  ({
    _asset,
    decision,
    assetVersion
  }: {
    _asset: AssetItemDto;
    decision: 'approved' | 'approved_with_feedback' | 'changes_required' | null;
    assetVersion: AssetVersionItemDto;
  }) =>
  async (dispatch: Dispatch<IMediaViewerAction>) => {
    const {
      data: { asset }
    } = await decisionApprovalWorkflow({
      assetId: _asset.id,
      assetVersionId: assetVersion.id,
      password: assetVersion.approvalWorkflow?.password,
      decision,
      stageId: assetVersion.approvalWorkflow?.currentStageId || ''
    });
    dispatch({
      type: MediaViewerActionTypes.MAKE_AW_DECISION,
      payload: { asset }
    });
  };

export const setToolsExpanded = (isExpanded: boolean) => ({
  type: MediaViewerActionTypes.SET_TOOLS_EXPANDED,
  payload: isExpanded
});

export const setDrawToolsColor = (color: string) => ({
  type: MediaViewerActionTypes.SET_DRAW_TOOLS_COLOR,
  payload: color
});

export const setDrawToolsShape = (shape: string) => ({
  type: MediaViewerActionTypes.SET_DRAW_TOOLS_SHAPE,
  payload: shape
});

export const setDrawToolsMode = (mode: string) => ({
  type: MediaViewerActionTypes.SET_DRAW_TOOLS_MODE,
  payload: mode
});

export const setZoomFactorAction = (zoomFactor: number) => ({
  type: MediaViewerActionTypes.SET_ZOOM_FACTOR,
  payload: { zoomFactor }
});

export const setTurnoverDegree = (turnoverDegree: number) => ({
  type: MediaViewerActionTypes.SET_TURNOVER_DEGREE,
  payload: { turnoverDegree }
});

export const toggleFavoriteAssetInMV =
  (params: SetAssetFavoriteDto) =>
  async (dispatch: Dispatch<IMediaViewerAction>) => {
    await setFavoriteAsset(params);

    dispatch({
      type: MediaViewerActionTypes.TOGGLE_ASSET_FAVORITE,
      payload: { isFavorite: params.isFavorite }
    });
  };

export const setCommentTextAction = (commentText: string | null) => ({
  type: MediaViewerActionTypes.SET_COMMENT_TEXT,
  payload: { commentText }
});

export const setIsTimecodeChecked = (isTimecodeChecked: boolean) => ({
  type: MediaViewerActionTypes.SET_IS_TIMECODE_CHECKED,
  payload: { isTimecodeChecked }
});

export const setTimeFormat = (timeFormat: 'standard' | 'frames') => ({
  type: MediaViewerActionTypes.SET_TIME_FORMAT,
  payload: { timeFormat }
});
export const setIsSourceSelected = (isSourceSelected: boolean) => ({
  type: MediaViewerActionTypes.SET_IS_SOURCE_SELECTED,
  payload: { isSourceSelected }
});

export const setIsCommentFieldFocused = (focused: boolean) => ({
  type: MediaViewerActionTypes.SET_IS_COMMENT_FIELD_FOCUSED,
  payload: focused
});

export const setDocumentHighlight = (
  documentHighlight: {
    text: string;
    rects: { top: number; left: number; width: number; height: number }[];
  } | null
) => {
  return {
    type: MediaViewerActionTypes.SET_DOCUMENT_HIGHLIGHT,
    payload: { documentHighlight: documentHighlight ?? { text: '', rects: [] } }
  };
};
