import { registerSW } from 'virtual:pwa-register';

let promise: Promise<void> | undefined;

export function registerServiceWorker() {
  if (!promise) {
    promise = new Promise((resolve, reject) => {
      registerSW({
        immediate: true,
        onRegistered() {
          resolve();
        },
        onRegisterError(err) {
          console.error(err);
          reject(err);
        }
      });
    });
  }
  return promise;
}
