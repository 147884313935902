import { WorkspaceItemDto } from '@api/Api';

export interface IWorkspaceState {
  workspaceList: WorkspaceItemDto[];
  currentWorkspaceId: string | null;
}

export interface IWorkspaceAction {
  type: string;
  payload?: any;
}

export enum WorkspaceActionTypes {
  GET_WORKSPACE_LIST = 'GET_WORKSPACE_LIST',
  SET_WORKSPACE_LIST = 'SET_WORKSPACE_LIST',
  SET_CURRENT_WORKSPACE_ID = 'SET_CURRENT_WORKSPACE_ID',
  CREATE_NEW_WORKSPACE = 'CREATE_NEW_WORKSPACE',
  REMOVE_WORKSPACE = 'REMOVE_WORKSPACE',
  RENAME_WORKSPACE = 'RENAME_WORKSPACE',
  LEAVE_WORKSPACE = 'LEAVE_WORKSPACE',
  UPDATE_WORKSPACE = 'UPDATE_WORKSPACE'
}
