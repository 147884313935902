
      (function() {
        const s = document.createElement('script');
        s.src = 'https://www.googletagmanager.com/gtag/js?id=G-TF6W1BYVQY';
        s.type = 'text/javascript';
        s.async = true;
        s.defer = true;
        s.crossOrigin = 'anonymous';
        document.body.appendChild(s);
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'G-TF6W1BYVQY');
      })();
    