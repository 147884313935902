import {
  AddAWNotificationSubscriptionDto,
  ApprovalWorkflowControllerCountAssetsWithApprovalParams,
  ApprovalWorkflowControllerDeleteParams,
  ApprovalWorkflowControllerGetAssetByAwParams,
  ApprovalWorkflowControllerListAssetsWithApprovalParams,
  ApprovalWorkflowControllerListPotentialReviewersParams,
  AssetAwCommentsControllerDeleteAssetCommentParams,
  AssetAwCommentsControllerGetAssetCommentParams,
  AssetAwCommentsControllerGetAssetCommentsParams,
  AssetAwCommentsControllerListCommentPotentialMentionsParams,
  CreateAWCommentDto,
  CreateAWDto,
  ReplyAWCommentDto,
  RestartAWDto,
  SendAWInvitationsDto,
  SetAWCommentLikeDto,
  SubmitDecisionDto,
  UpdateAWCommentDto,
  UpdateAWDto
} from '@api/Api';
import { apiClient } from '@api/client';

export const createApprovalWorkflow = (data: CreateAWDto) =>
  apiClient.approvalWorkflow.approvalWorkflowControllerCreate(data);

export const restartApprovalWorkflow = (data: RestartAWDto) =>
  apiClient.approvalWorkflow.approvalWorkflowControllerRestart(data);

export const sendReminderMemberApprovalStage = (data: SendAWInvitationsDto) =>
  apiClient.approvalWorkflow.approvalWorkflowControllerSendInvitations(data);

export const deleteApprovalWorkflow = (
  params: ApprovalWorkflowControllerDeleteParams
) => apiClient.approvalWorkflow.approvalWorkflowControllerDelete(params);

export const decisionApprovalWorkflow = (params: SubmitDecisionDto) =>
  apiClient.approvalWorkflow.approvalWorkflowControllerSubmitDecision(params);

export const updateApprovalWorkflow = (params: UpdateAWDto) =>
  apiClient.approvalWorkflow.approvalWorkflowControllerUpdate(params);

export const getPotentialsApprovalReviewers = (
  params: ApprovalWorkflowControllerListPotentialReviewersParams
) =>
  apiClient.approvalWorkflow.approvalWorkflowControllerListPotentialReviewers(
    params
  );

export const getCountListAssetsWithApproval = (
  params: ApprovalWorkflowControllerCountAssetsWithApprovalParams
) =>
  apiClient.approvalWorkflow.approvalWorkflowControllerCountAssetsWithApproval(
    params
  );

export const getListAssetsWithApproval = (
  params: ApprovalWorkflowControllerListAssetsWithApprovalParams
) =>
  apiClient.approvalWorkflow.approvalWorkflowControllerListAssetsWithApproval(
    params
  );

export const approvalWorkflowAddNotificationSubscription = (
  params: AddAWNotificationSubscriptionDto
) =>
  apiClient.approvalWorkflow.approvalWorkflowControllerAddNotificationSubscription(
    params
  );

export const approvalWorkflowGetAssetByAw = (
  params: ApprovalWorkflowControllerGetAssetByAwParams
) => apiClient.approvalWorkflow.approvalWorkflowControllerGetAssetByAw(params);

export const assetAwCommentsCreateAssetComment = (params: CreateAWCommentDto) =>
  apiClient.approvalWorkflow.assetAwCommentsControllerCreateAssetComment(
    params
  );

export const assetAwCommentsReplyAssetComment = (params: ReplyAWCommentDto) =>
  apiClient.approvalWorkflow.assetAwCommentsControllerReplyAssetComment(params);

export const assetAwCommentsDeleteAssetComment = (
  params: AssetAwCommentsControllerDeleteAssetCommentParams
) =>
  apiClient.approvalWorkflow.assetAwCommentsControllerDeleteAssetComment(
    params
  );

export const assetAwCommentsUpdateAssetComment = (params: UpdateAWCommentDto) =>
  apiClient.approvalWorkflow.assetAwCommentsControllerUpdateAssetComment(
    params
  );

export const assetAwCommentsSetAssetCommentLike = (
  params: SetAWCommentLikeDto
) =>
  apiClient.approvalWorkflow.assetAwCommentsControllerSetAssetCommentLike(
    params
  );

export const assetAwCommentsListCommentPotentialMentions = (
  params: AssetAwCommentsControllerListCommentPotentialMentionsParams
) =>
  apiClient.approvalWorkflow.assetAwCommentsControllerListCommentPotentialMentions(
    params
  );

export const assetAwCommentsGetAssetComments = (
  params: AssetAwCommentsControllerGetAssetCommentsParams
) =>
  apiClient.approvalWorkflow.assetAwCommentsControllerGetAssetComments(params);

export const assetAwCommentsGetAssetComment = (
  params: AssetAwCommentsControllerGetAssetCommentParams
) =>
  apiClient.approvalWorkflow.assetAwCommentsControllerGetAssetComment(params);
