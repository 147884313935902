import { combineReducers } from '@reduxjs/toolkit';
import tasks from '@redux/reducers/tasks';
import organizations from '@redux/reducers/organizations';
import assetsReducer from '@redux/reducers/assetsReducer';
import workspaceCountersReducer from '@redux/reducers/workspaceCountersReducer';
import workspaceReducer from './workspaceReducer';
import campaignReducer from './campaignReducer';
import modalReducer from './modalReducer';
import mediaViewerReducer from './mediaViewerReducer';

export const rootReducer = combineReducers({
  organizations,
  workspace: workspaceReducer,
  campaign: campaignReducer,
  tasks,
  modal: modalReducer,
  mediaViewer: mediaViewerReducer,
  assets: assetsReducer,
  workspaceCounters: workspaceCountersReducer
});
