import { WorkspaceItemDto } from '@api/Api';
import {
  IModalAction,
  ModalActionTypes,
  WorkspaceModalStateProps,
  WorkspaceMemberGroupInviteModalStateProps,
  WorkspaceOrganizationLeaveModalStateProps,
  TaskModalStateProps,
  UploadAssetModalState,
  NewAssetWithWorkflowState,
  ShareAssetModalStateProps,
  BillingModalStateProps,
  RenameEntityModalProps,
  SingInExternalReviewerState,
  CampaignInfoModalProps,
  CampaignFolderCreationModalProps,
  CampaignCreatedModalProps,
  EntityDeleteModalProps,
  CampaignInvitationModalProps,
  OrganizationRenameModalProps,
  MoveAssetToPrivateFolderModalProps,
  ApprovalModalStateProps,
  ManageAssetVersionsModalProps,
  ChangePlanDetailsModalProps,
  CancelSubscriptionModalProps,
  ReachedEntityLimitModalProps,
  AssetStackErrorModalProps,
  ManageFolderAccessModalProps,
  ChangeFolderAccessModalProps,
  CombineAssetsModalModalProps,
  CopyAssetVersionModalProps,
  WorkspaceMemberGroupCreateModalStateProps,
  WorkspaceMemberGroupModalStateProps
} from '@redux/types/modalType';
import { Dispatch } from 'redux';

export const toggleWorkspaceCreationModal = (
  payload: WorkspaceModalStateProps | null
): IModalAction => ({
  type: ModalActionTypes.TOGGLE_WORKSPACE_CREATION_MODAL,
  payload
});

export const toggleWorkspaceInvitationModal = (
  payload: WorkspaceModalStateProps | null
): IModalAction | Promise<WorkspaceItemDto> => ({
  type: ModalActionTypes.TOGGLE_WORKSPACE_INVITATION_MODAL,
  payload
});

export const toggleWorkspaceMemberGroupInviteModal = (
  payload: Omit<WorkspaceMemberGroupInviteModalStateProps, 'key'> | null
): IModalAction | Promise<WorkspaceItemDto> => ({
  type: ModalActionTypes.TOGGLE_WORKSPACE_MEMBER_GROUP_INVITE_MODAL,
  payload
});

export const toggleWorkspaceMemberGroupCreateModal = (
  payload: Omit<WorkspaceMemberGroupCreateModalStateProps, 'key'> | null
): IModalAction | Promise<WorkspaceItemDto> => ({
  type: ModalActionTypes.TOGGLE_WORKSPACE_MEMBER_GROUP_CREATE_MODAL,
  payload
});

export const toggleWorkspaceMemberGroupModal = (
  payload: Omit<WorkspaceMemberGroupModalStateProps, 'key'> | null
): IModalAction | Promise<WorkspaceItemDto> => ({
  type: ModalActionTypes.TOGGLE_WORKSPACE_MEMBER_GROUP_MODAL,
  payload
});

export const toggleWorkspaceOrganizationDeletionModal = (
  payload: WorkspaceOrganizationLeaveModalStateProps | null
): IModalAction => ({
  type: ModalActionTypes.TOGGLE_WORKSPACE_ORGANIZATION_DELETION_MODAL,
  payload
});

export const toggleWorkspaceMembersModal = (
  payload: WorkspaceModalStateProps | null
): IModalAction => ({
  type: ModalActionTypes.TOGGLE_WORKSPACE_MEMBERS_MODAL,
  payload
});

export const toggleWorkspaceOrganizationLeaveModal = (
  payload: WorkspaceOrganizationLeaveModalStateProps | null
): IModalAction => ({
  type: ModalActionTypes.TOGGLE_WORKSPACE_ORGANIZATION_LEAVE_MODAL,
  payload
});

export const toggleTaskModal = (
  payload: TaskModalStateProps | null
): IModalAction => ({
  type: ModalActionTypes.TOGGLE_TASK_MODAL,
  payload
});

export const toggleApprovalModal =
  (payload: (Omit<ApprovalModalStateProps, 'key'> & { key?: string }) | null) =>
  (dispatch: Dispatch<IModalAction>) => {
    const data = {
      ...payload,
      isEditMode:
        !!payload?.isEditMode || !payload?.assetVersion.approvalWorkflow
    };
    dispatch({
      type: ModalActionTypes.TOGGLE_APPROVAL_MODAL,
      payload: data.assetId ? data : null
    });
  };

export const toggleUploadAssetModal = (
  payload: Omit<UploadAssetModalState, 'key'> | null
): IModalAction => ({
  type: ModalActionTypes.TOGGLE_UPLOAD_ASSET_MODAL,
  payload
});

export const toggleShareAssetModal = (
  payload: ShareAssetModalStateProps | null
): IModalAction => ({
  type: ModalActionTypes.TOGGLE_SHARE_ASSET_MODAL,
  payload
});

export const toggleBillingModal = (
  payload: BillingModalStateProps | null
): IModalAction => ({
  type: ModalActionTypes.TOGGLE_BILLING_MODAL,
  payload
});

export const toggleNewAssetWithWorkflowModal = (
  payload: NewAssetWithWorkflowState | null
): IModalAction => ({
  type: ModalActionTypes.TOGGLE_NEW_ASSET_WITH_WORKFLOW_MODAL,
  payload
});

export const toggleRenameEntityModal = (
  payload: RenameEntityModalProps | null
): IModalAction => ({
  type: ModalActionTypes.TOGGLE_RENAME_ENTITY_MODAL,
  payload
});

export const toggleSingInExternalReviewerModal = (
  payload: SingInExternalReviewerState | null
): IModalAction => ({
  type: ModalActionTypes.TOGGLE_SIGN_IN_EXTERNAL_REVIEWER_MODAL,
  payload
});

export const toggleCampaignInfoModal = (
  payload: CampaignInfoModalProps | null
): IModalAction => ({
  type: ModalActionTypes.TOGGLE_CAMPAIGN_INFO_MODAL,
  payload
});

export const toggleCampaignFolderCreationModal = (
  payload: CampaignFolderCreationModalProps | null
): IModalAction => ({
  type: ModalActionTypes.TOGGLE_CAMPAIGN_FOLDER_CREATION_MODAL,
  payload
});

export const toggleOrganizationRenameModal = (
  payload: OrganizationRenameModalProps | null
): IModalAction => ({
  type: ModalActionTypes.TOGGLE_ORGANIZATION_RENAME_MODAL,
  payload
});

export const toggleCampaignCreatedModal = (
  payload: CampaignCreatedModalProps | null
) => ({
  type: ModalActionTypes.TOGGLE_CAMPAIGN_CREATED_MODAL,
  payload
});

export const toggleEntityDeletionModal = (
  payload: EntityDeleteModalProps | null
) => ({
  type: ModalActionTypes.TOGGLE_DELETE_ASSET_MODAL,
  payload
});

export const toggleCampaignInvitationModal = (
  payload: CampaignInvitationModalProps | null
) => ({ type: ModalActionTypes.TOGGLE_INVITE_TO_CAMPAIGN_MODAL, payload });

export const toggleMoveAssetToPrivateFolder = (
  payload: MoveAssetToPrivateFolderModalProps | null
) => ({
  type: ModalActionTypes.TOGGLE_MOVE_ASSET_TO_PRIVATE_FOLDER_MODAL,
  payload
});

export const toggleManageAssetVersionsModal = (
  payload: ManageAssetVersionsModalProps | null
) => ({ type: ModalActionTypes.TOGGLE_MANAGE_ASSET_VERSIONS_MODAL, payload });

export const toggleChangePlanDetailsModal = (
  payload: ChangePlanDetailsModalProps | null
) => ({
  type: ModalActionTypes.TOGGLE_CHANGE_PLAN_DETAILS_MODAL,
  payload
});

export const toggleCancelSubscriptionModal = (
  payload: CancelSubscriptionModalProps | null
) => ({
  type: ModalActionTypes.TOGGLE_CANCEL_SUBSCRIPTION_MODAL,
  payload
});

export const toggleReachedEntityLimitModal = (
  payload: ReachedEntityLimitModalProps | null
) => ({
  type: ModalActionTypes.TOGGLE_REACHED_ENTITY_LIMIT__MODAL,
  payload
});

export const toggleAssetStackErrorModal = (
  payload: AssetStackErrorModalProps | null
) => ({
  type: ModalActionTypes.TOGGLE_ASSET_STACK_ERROR_MODAL,
  payload
});

export const toggleManageFolderAccessModal = (
  payload: Omit<ManageFolderAccessModalProps, 'key'> | null
) => ({
  type: ModalActionTypes.TOGGLE_MANAGE_FOLDER_ACCESS_MODAL,
  payload
});

export const toggleChangeFolderAccessModal = (
  payload: ChangeFolderAccessModalProps | null
) => ({
  type: ModalActionTypes.TOGGLE_CHANGE_FOLDER_ACCESS_MODAL,
  payload
});

export const toggleCombineAssetsModal = (
  payload: CombineAssetsModalModalProps | null
) => ({
  type: ModalActionTypes.TOGGLE_COMBINE_ASSETS_MODAL,
  payload
});

export const toggleCopyAssetVersionModal = (
  payload: CopyAssetVersionModalProps | null
) => ({
  type: ModalActionTypes.TOGGLE_COPY_ASSET_VERSION_MODAL,
  payload
});
