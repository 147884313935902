import {
  AssetControllerCountAssetsParams,
  AssetControllerCountFolderMembersParams,
  AssetControllerDeleteFolderParams,
  AssetControllerFindMembersToInviteInFolderParams,
  AssetControllerGetAssetParams,
  AssetControllerListAssetsParams,
  AssetControllerListFolderMembersParams,
  AssetControllerMoveAssetToFolderParams,
  AssetInternalCommentsControllerDeleteAssetCommentParams,
  AssetInternalCommentsControllerGetAssetCommentsParams,
  AssetInternalCommentsControllerListCommentPotentialMentionsParams,
  CreateAssetVersionCommentDto,
  CreateFolderDto,
  InitAssetVersionUploadDto,
  RenameFolderDto,
  ReplyAssetVersionCommentDto,
  SetAssetVersionCommentLikeDto,
  SetFolderVisibilityDto,
  UpdateAssetVersionCommentDto,
  SetAssetFavoriteDto,
  AssetControllerDeleteAssetParams,
  RestoreAssetDto,
  RenameAssetDto,
  AssetControllerGetSimpleFoldersListParams,
  AssetControllerGetAssetsDownloadListParams,
  AssetControllerDeleteFolderMembersParams,
  SetAssetOpenedDto,
  UpdateAssetVersionDescriptionDto,
  ChangeAssetOrderDto,
  ChangeFolderOrderDto,
  AssetControllerAddAssetToStackParams,
  AssetControllerRemoveAssetVersionFromStackParams,
  AssetInternalCommentsControllerGetAssetCommentParams,
  RestoreFolderDto,
  RequestParams,
  AssetControllerFindMemberGroupsToInviteInFolderParams
} from '@api/Api';
import { apiClient } from '@api/client';

export const createAssetFolder = (data: CreateFolderDto) =>
  apiClient.asset.assetControllerCreateFolder(data);

export const deleteAsset = (params: AssetControllerDeleteAssetParams) =>
  apiClient.asset.assetControllerDeleteAsset(params);

export const renameAsset = (params: RenameAssetDto) =>
  apiClient.asset.assetControllerRename(params);

export const restoreDeletedAsset = (params: RestoreAssetDto) =>
  apiClient.asset.assetControllerRestoreDeletedAsset(params);

export const restoreDeletedFolder = (params: RestoreFolderDto) =>
  apiClient.asset.assetControllerRestoreDeletedFolder(params);

export const deleteAssetFolder = (params: AssetControllerDeleteFolderParams) =>
  apiClient.asset.assetControllerDeleteFolder(params);

export const getAsset = (params: AssetControllerGetAssetParams) =>
  apiClient.asset.assetControllerGetAsset(params);

export const getAssetCount = (
  data: AssetControllerCountAssetsParams,
  params: RequestParams = {}
) => apiClient.asset.assetControllerCountAssets(data, params);

export const getAssetList = (
  data: AssetControllerListAssetsParams,
  params: RequestParams = {}
) => apiClient.asset.assetControllerListAssets(data, params);

export const renameFolder = (data: RenameFolderDto) =>
  apiClient.asset.assetControllerRenameFolder(data);

export const initUploadAssetVersion = (data: InitAssetVersionUploadDto) =>
  apiClient.asset.assetControllerInitUpload(data);

export const setAssetFolderVisibility = (data: SetFolderVisibilityDto) =>
  apiClient.asset.assetControllerSetFolderVisibility(data);

export const setFavoriteAsset = (data: SetAssetFavoriteDto) =>
  apiClient.asset.assetControllerSetFavorite(data);

export const updateAssetVersionDescription = (
  data: UpdateAssetVersionDescriptionDto
) => apiClient.asset.assetControllerUpdateAssetVersionDescription(data);

export const moveAsset = (params: AssetControllerMoveAssetToFolderParams) =>
  apiClient.asset.assetControllerMoveAssetToFolder(params);

export const listFolderMembers = (
  params: AssetControllerListFolderMembersParams
) => apiClient.asset.assetControllerListFolderMembers(params);

export const countFolderMembers = (
  params: AssetControllerCountFolderMembersParams
) => apiClient.asset.assetControllerCountFolderMembers(params);

export const findMembersToInviteInFolder = (
  data: AssetControllerFindMembersToInviteInFolderParams,
  params: RequestParams = {}
) => apiClient.asset.assetControllerFindMembersToInviteInFolder(data, params);

export const findMemberGroupsToInviteInFolder = (
  data: AssetControllerFindMemberGroupsToInviteInFolderParams,
  params: RequestParams = {}
) =>
  apiClient.asset.assetControllerFindMemberGroupsToInviteInFolder(data, params);

export const inviteFolderMembers = ({
  memberIds,
  groupIds,
  folderId
}: {
  memberIds: string[];
  groupIds: string[];
  folderId: string;
}) => {
  const users = memberIds.map((el) => ({ memberIdOrEmail: el }));
  const groups = groupIds.map((el) => ({ id: el }));

  return apiClient.asset.assetControllerInviteFolderMembers({
    folderId,
    users,
    groups
  });
};

export const deleteFolderMembers = (
  params: AssetControllerDeleteFolderMembersParams
) => apiClient.asset.assetControllerDeleteFolderMembers(params);

export const assetInternalCommentsGetAssetComments = (
  params: AssetInternalCommentsControllerGetAssetCommentsParams
) => apiClient.asset.assetInternalCommentsControllerGetAssetComments(params);

export const assetInternalCommentsGetAssetComment = (
  params: AssetInternalCommentsControllerGetAssetCommentParams
) => apiClient.asset.assetInternalCommentsControllerGetAssetComment(params);

export const assetInternalCommentsCreateAssetComment = (
  params: CreateAssetVersionCommentDto
) => apiClient.asset.assetInternalCommentsControllerCreateAssetComment(params);

export const assetInternalCommentsReplyAssetComment = (
  params: ReplyAssetVersionCommentDto
) => apiClient.asset.assetInternalCommentsControllerReplyAssetComment(params);

export const assetInternalCommentsSetAssetCommentLike = (
  params: SetAssetVersionCommentLikeDto
) => apiClient.asset.assetInternalCommentsControllerSetAssetCommentLike(params);

export const assetInternalCommentsDeleteAssetComment = (
  params: AssetInternalCommentsControllerDeleteAssetCommentParams
) => apiClient.asset.assetInternalCommentsControllerDeleteAssetComment(params);

export const assetInternalCommentsUpdateAssetComment = (
  params: UpdateAssetVersionCommentDto
) => apiClient.asset.assetInternalCommentsControllerUpdateAssetComment(params);

export const assetInternalCommentsListCommentPotentialMentions = (
  params: AssetInternalCommentsControllerListCommentPotentialMentionsParams
) =>
  apiClient.asset.assetInternalCommentsControllerListCommentPotentialMentions(
    params
  );

export const assetGetSimpleFoldersList = (
  params: AssetControllerGetSimpleFoldersListParams
) => apiClient.asset.assetControllerGetSimpleFoldersList(params);

export const assetGetAssetsDownloadList = (
  params: AssetControllerGetAssetsDownloadListParams
) => apiClient.asset.assetControllerGetAssetsDownloadList(params);

export const assetSetRecentlyOpened = (params: SetAssetOpenedDto) =>
  apiClient.asset.assetControllerSetRecentlyOpened(params);

export const changeAssetOrder = (params: ChangeAssetOrderDto) =>
  apiClient.asset.assetControllerChangeAssetOrder(params);

export const changeFolderOrder = (params: ChangeFolderOrderDto) =>
  apiClient.asset.assetControllerChangeFolderOrder(params);

export const addAssetToStack = (params: AssetControllerAddAssetToStackParams) =>
  apiClient.asset.assetControllerAddAssetToStack(params);

export const removeAssetVersionFromStack = (
  params: AssetControllerRemoveAssetVersionFromStackParams
) => apiClient.asset.assetControllerRemoveAssetVersionFromStack(params);
