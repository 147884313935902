import { GetAssetSuccessDto, RenameWorkspaceDto } from '@api/Api';
import {
  cancelOrganizationSubscription,
  changeOrganizationMemberRole,
  deleteOrganization,
  deleteOrganizationMember,
  deleteOrganizationPaymentMethod,
  fetchOrganizationById,
  fetchOrganizationInvoiceHistory,
  fetchOrganizationMembersCount,
  fetchOrganizationMembersList,
  fetchOrganizationPaymentMethod,
  fetchOrganizationSeatsUsed,
  fetchOrganizationsList,
  fetchOrganizationStorageUsed,
  fetchOrganizationSubscription,
  fetchOrganizationWorkspacesList,
  leaveOrganization,
  renameOrganization,
  updateOrganizationSubscription
} from '@redux/actions/organizations';
import { initialState } from '@redux/initial-states/organization';
import { getPersistedState } from '@redux/persistors/organization';
import {
  cancelUndo,
  getOrSetOrgsState,
  getOrSetOrgState,
  newRequest,
  setOrganizationEntitiesFromList,
  undo,
  withUndo
} from '@redux/reducers/helpers/organizations';
import { AssetsActionTypes } from '@redux/types/assetsType';

import { WorkspaceActionTypes } from '@redux/types/workspaceType';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const organizationsSlice = createSlice({
  name: 'organizations',
  initialState: initialState(getPersistedState()),
  reducers: {
    loadMoreInvoices(state, action: PayloadAction<{ organizationId: string }>) {
      const organizationState = getOrSetOrgState(
        state,
        action.payload.organizationId
      );
      newRequest(organizationState.invoices.fetch, 'more');
    },
    loadMoreMembers(state, action: PayloadAction<{ organizationId: string }>) {
      const organizationState = getOrSetOrgState(
        state,
        action.payload.organizationId
      );
      newRequest(organizationState.members.fetch, 'more');
    },
    setOrganizationMembersListParams(
      state,
      action: PayloadAction<{
        organizationId: string;
        searchQuery?: string;
        orderBy?: (
          | 'createdAt:ASC'
          | 'createdAt:DESC'
          | 'role:ASC'
          | 'role:DESC'
          | 'isActive:ASC'
          | 'isActive:DESC'
          | 'workspacesCount:ASC'
          | 'workspacesCount:DESC'
        )[];
      }>
    ) {
      const { organizationId, searchQuery, orderBy } = action.payload;
      const { members } = getOrSetOrgState(state, organizationId);
      let debounce = 0;
      if (searchQuery !== undefined) {
        members.searchQuery = searchQuery;
        if (searchQuery) debounce = 300;
      }

      if (orderBy !== undefined) members.orderBy = orderBy;
      newRequest(members.countFetch, 'refresh', debounce);
      newRequest(members.fetch, 'reload', debounce);
    },
    setOrganizationWorkspacesListParams(
      state,
      action: PayloadAction<{
        organizationId: string;
        searchQuery: string;
      }>
    ) {
      const { organizationId, searchQuery } = action.payload;
      const { workspaces } = getOrSetOrgState(state, organizationId);
      workspaces.searchQuery = searchQuery;
    },
    setOrganizationActiveTab(
      state,
      action: PayloadAction<{
        organizationId: string;
        activeTab: 'manage_workspaces' | 'manage_members';
      }>
    ) {
      const { organizationId, activeTab } = action.payload;
      const organizationState = getOrSetOrgState(state, organizationId);
      organizationState.activeTab = activeTab;
    },
    setOrganizationWorkspacesListNeedRefresh(
      state,
      action: PayloadAction<{ organizationId: string }>
    ) {
      const { organizationId } = action.payload;
      const { workspaces } = getOrSetOrgState(state, organizationId);
      if (!workspaces.fetch.initiallyLoaded) return;
      newRequest(workspaces.fetch, 'refresh');
    },
    setOrganizationMembersListNeedRefresh(
      state,
      action: PayloadAction<{ organizationId: string }>
    ) {
      const { organizationId } = action.payload;
      const { members } = getOrSetOrgState(state, organizationId);
      if (!members.fetch.initiallyLoaded) return;
      newRequest(members.fetch, 'refresh');
      newRequest(members.countFetch, 'refresh');
    },
    setOrganizationInvoicesListNeedRefresh(
      state,
      action: PayloadAction<{ organizationId: string }>
    ) {
      const { organizationId } = action.payload;
      const { invoices } = getOrSetOrgState(state, organizationId);
      if (!invoices.fetch.initiallyLoaded) return;
      newRequest(invoices.fetch, 'refresh');
    },
    deleteOrganizationFromList(
      state,
      action: PayloadAction<{ organizationId: string }>
    ) {
      const { organizationId } = action.payload;
      const organizationsState = getOrSetOrgsState(state);
      organizationsState.count -= Math.max(organizationsState.count - 1, 0);
      organizationsState.organizationIds =
        organizationsState.organizationIds.filter(
          (id) => id !== organizationId
        );
      delete state.items[organizationId];
    },
    setOrganizationName(
      state,
      action: PayloadAction<{ name: string; organizationId: string }>
    ) {
      const { organizationId, name } = action.payload;
      const organizationState = getOrSetOrgState(state, organizationId);
      if (!organizationState.entity) return;
      organizationState.entity.name = name;
    }
  },
  extraReducers(builder) {
    builder.addCase(fetchOrganizationsList.pending, (state, action) => {
      const { requestId } = action.meta;
      const orgState = getOrSetOrgsState(state);
      orgState.fetch.requestId = requestId;
    });
    builder.addCase(fetchOrganizationsList.fulfilled, (state, action) => {
      const { requestId } = action.meta;
      const orgState = getOrSetOrgsState(state);

      if (orgState.fetch.requestId !== requestId) return;
      orgState.fetch.newRequest = null;
      orgState.fetch.initiallyLoaded = true;

      const organizationIds = action.payload.list.map((it) => it.id);

      orgState.organizationIds = organizationIds;
      orgState.count = organizationIds.length;

      setOrganizationEntitiesFromList(action.payload.list, state);
    });
    builder.addCase(fetchOrganizationById.pending, (state, action) => {
      const { arg, requestId } = action.meta;
      const organizationState = getOrSetOrgState(state, arg.organizationId);
      organizationState.fetch.requestId = requestId;
    });
    builder.addCase(fetchOrganizationById.fulfilled, (state, action) => {
      const { arg, requestId } = action.meta;
      const { organization } = action.payload;
      const organizationState = getOrSetOrgState(state, arg.organizationId);
      if (organizationState.fetch.requestId !== requestId) return;

      organizationState.fetch.newRequest = null;
      organizationState.fetch.initiallyLoaded = true;
      const prevOrganization = organizationState.entity;

      if (prevOrganization) {
        state.items[arg.organizationId].entity = organization;
      } else {
        state.items[arg.organizationId].entity = organization;
        state.list.organizationIds = [
          ...state.list.organizationIds,
          organization.id
        ];
      }
    });
    builder.addCase(fetchOrganizationById.rejected, (state, action) => {
      const { arg, requestId } = action.meta;
      const organizationState = getOrSetOrgState(state, arg.organizationId);
      if (organizationState.fetch.requestId !== requestId) return;
      if (action.error.code === '404') {
        const { entity } = organizationState;
        if (entity) {
          delete state.items[entity.id];
          state.list.organizationIds = state.list.organizationIds.filter(
            (id) => id !== entity.id
          );
        }
      }
    });
    builder.addCase(fetchOrganizationSubscription.pending, (state, action) =>
      withUndo(state, action.meta.requestId, (state) => {
        const { arg, requestId } = action.meta;
        const organizationState = getOrSetOrgState(state, arg.organizationId);
        organizationState.subscription.fetch.requestId = requestId;
      })
    );
    builder.addCase(
      fetchOrganizationSubscription.fulfilled,
      (state, action) => {
        const { arg, requestId } = action.meta;
        const organizationState = getOrSetOrgState(state, arg.organizationId);
        if (organizationState.subscription.fetch.requestId !== requestId)
          return;
        const { subscription } = action.payload;
        organizationState.subscription.fetch.newRequest = null;
        organizationState.subscription.fetch.initiallyLoaded = true;
        organizationState.subscription.it = subscription;
        cancelUndo(state, action);
      }
    );
    builder.addCase(fetchOrganizationSubscription.rejected, undo);
    builder.addCase(fetchOrganizationSeatsUsed.pending, (state, action) => {
      const { arg, requestId } = action.meta;
      const organizationState = getOrSetOrgState(state, arg.organizationId);
      organizationState.seatsCount.countFetch.requestId = requestId;
    });
    builder.addCase(fetchOrganizationSeatsUsed.fulfilled, (state, action) => {
      const { arg, requestId } = action.meta;
      const organizationState = getOrSetOrgState(state, arg.organizationId);
      if (organizationState.seatsCount.countFetch.requestId !== requestId)
        return;
      const { seatsCount } = action.payload;

      organizationState.seatsCount.countFetch.newRequest = null;
      organizationState.seatsCount.countFetch.initiallyLoaded = true;

      organizationState.seatsCount.count = seatsCount;
    });
    builder.addCase(fetchOrganizationStorageUsed.pending, (state, action) => {
      const { arg, requestId } = action.meta;
      const organizationState = getOrSetOrgState(state, arg.organizationId);
      organizationState.assetsSummarySize.sizeFetch.requestId = requestId;
    });
    builder.addCase(fetchOrganizationStorageUsed.fulfilled, (state, action) => {
      const { arg, requestId } = action.meta;
      const organizationState = getOrSetOrgState(state, arg.organizationId);
      if (organizationState.assetsSummarySize.sizeFetch.requestId !== requestId)
        return;
      const { assetsSummarySize } = action.payload;

      organizationState.assetsSummarySize.sizeFetch.newRequest = null;
      organizationState.assetsSummarySize.sizeFetch.initiallyLoaded = true;

      organizationState.assetsSummarySize.size = assetsSummarySize;
    });

    builder.addCase(fetchOrganizationPaymentMethod.pending, (state, action) => {
      const { arg, requestId } = action.meta;
      const organizationState = getOrSetOrgState(state, arg.organizationId);
      organizationState.paymentMethod.fetch.requestId = requestId;
    });
    builder.addCase(
      fetchOrganizationPaymentMethod.fulfilled,
      (state, action) => {
        const { arg, requestId } = action.meta;
        const organizationState = getOrSetOrgState(state, arg.organizationId);
        if (organizationState.paymentMethod.fetch.requestId !== requestId)
          return;
        const { card } = action.payload;

        organizationState.paymentMethod.fetch.newRequest = null;
        organizationState.paymentMethod.fetch.initiallyLoaded = true;

        organizationState.paymentMethod.it = card;
      }
    );
    builder.addCase(deleteOrganization.fulfilled, (state, action) => {
      const { arg } = action.meta;
      const organizationsState = getOrSetOrgsState(state);
      organizationsState.organizationIds =
        organizationsState.organizationIds.filter(
          (id) => id !== arg.organizationId
        );
      organizationsState.count = organizationsState.organizationIds.length;
      delete state.items[arg.organizationId];
    });
    builder.addCase(leaveOrganization.fulfilled, (state, action) => {
      const { arg } = action.meta;
      const organizationsState = getOrSetOrgsState(state);
      organizationsState.organizationIds =
        organizationsState.organizationIds.filter(
          (id) => id !== arg.organizationId
        );
      organizationsState.count = organizationsState.organizationIds.length;
      delete state.items[arg.organizationId];
    });
    builder.addCase(renameOrganization.pending, (state, action) =>
      withUndo(state, action.meta.requestId, (state) => {
        const { arg } = action.meta;
        const organizationState = getOrSetOrgState(state, arg.organizationId);
        if (!organizationState.entity) return;
        organizationState.entity.name = arg.name;
      })
    );
    builder.addCase(renameOrganization.fulfilled, cancelUndo);
    builder.addCase(renameOrganization.rejected, undo);
    builder.addCase(deleteOrganizationPaymentMethod.pending, (state, action) =>
      withUndo(state, action.meta.requestId, (state) => {
        const { arg } = action.meta;
        const organizationState = getOrSetOrgState(state, arg.organizationId);
        organizationState.paymentMethod.it = null;
      })
    );
    builder.addCase(deleteOrganizationPaymentMethod.fulfilled, cancelUndo);
    builder.addCase(deleteOrganizationPaymentMethod.rejected, undo);
    builder.addCase(cancelOrganizationSubscription.pending, (state, action) => {
      const { arg, requestId } = action.meta;
      const organizationState = getOrSetOrgState(state, arg.organizationId);
      organizationState.subscription.fetch.requestId = requestId;
    });
    builder.addCase(
      cancelOrganizationSubscription.fulfilled,
      (state, action) => {
        const { arg, requestId } = action.meta;
        const organizationState = getOrSetOrgState(state, arg.organizationId);
        const { subscription } = action.payload;
        if (organizationState.subscription.fetch.requestId !== requestId)
          return;
        organizationState.subscription.it = subscription;
      }
    );

    builder.addCase(updateOrganizationSubscription.pending, (state, action) => {
      const { arg, requestId } = action.meta;
      const organizationState = getOrSetOrgState(state, arg.organizationId);
      organizationState.subscription.fetch.requestId = requestId;
    });
    builder.addCase(
      updateOrganizationSubscription.fulfilled,
      (state, action) => {
        const { arg, requestId } = action.meta;
        const organizationState = getOrSetOrgState(state, arg.organizationId);
        const { subscription } = action.payload;
        if (organizationState.subscription.fetch.requestId !== requestId)
          return;
        organizationState.subscription.it = subscription;
      }
    );

    builder.addCase(
      fetchOrganizationInvoiceHistory.pending,
      (state, action) => {
        const { arg, requestId } = action.meta;
        const organizationState = getOrSetOrgState(state, arg.organizationId);
        organizationState.invoices.fetch.requestId = requestId;
      }
    );
    builder.addCase(
      fetchOrganizationInvoiceHistory.fulfilled,
      (state, action) => {
        const { arg, requestId } = action.meta;
        const organizationState = getOrSetOrgState(state, arg.organizationId);

        if (organizationState.invoices.fetch.requestId !== requestId) return;
        organizationState.invoices.fetch.newRequest = null;
        organizationState.invoices.fetch.initiallyLoaded = true;
        organizationState.invoices.cursor = action.payload.endCursor;
        organizationState.invoices.hasMore = action.payload.hasNext;

        if (arg.fetchType === 'more') {
          action.payload.edges.forEach(({ node }) => {
            if (
              !organizationState.invoices.list.some((x) => x.id === node.id)
            ) {
              organizationState.invoices.list.push(node);
            }
          });
        } else {
          organizationState.invoices.list = action.payload.edges.map(
            (x) => x.node
          );
        }
        organizationState.invoices.count =
          organizationState.invoices.list.length;
      }
    );
    builder.addCase(
      fetchOrganizationWorkspacesList.pending,
      (state, action) => {
        const { arg, requestId } = action.meta;
        const organizationState = getOrSetOrgState(state, arg.organizationId);
        organizationState.workspaces.fetch.requestId = requestId;
      }
    );
    builder.addCase(
      fetchOrganizationWorkspacesList.fulfilled,
      (state, action) => {
        const { arg, requestId } = action.meta;
        const organizationState = getOrSetOrgState(state, arg.organizationId);
        if (organizationState.workspaces.fetch.requestId !== requestId) return;
        organizationState.workspaces.fetch.newRequest = null;
        organizationState.workspaces.fetch.initiallyLoaded = true;
        organizationState.workspaces.list = action.payload.workspaces;
        organizationState.workspaces.count = action.payload.workspaces.length;
      }
    );
    builder.addCase(fetchOrganizationMembersList.pending, (state, action) => {
      const { arg, requestId } = action.meta;
      const organizationState = getOrSetOrgState(state, arg.organizationId);
      organizationState.members.fetch.requestId = requestId;
    });
    builder.addCase(fetchOrganizationMembersList.fulfilled, (state, action) => {
      const { arg, requestId } = action.meta;
      const { members } = getOrSetOrgState(state, arg.organizationId);
      if (members.fetch.requestId !== requestId) return;
      members.fetch.newRequest = null;
      members.fetch.initiallyLoaded = true;
      members.cursor = action.payload.endCursor;
      members.hasMore = action.payload.hasNext;
      if (arg.fetchType === 'more') {
        action.payload.edges.forEach(({ node }) => {
          if (!members.list.some((it) => it.id === node.id)) {
            members.list.push(node);
          }
        });
      } else {
        members.list = action.payload.edges.map((it) => it.node as any);
      }
    });
    builder.addCase(fetchOrganizationMembersCount.pending, (state, action) => {
      const { arg, requestId } = action.meta;
      const { members } = getOrSetOrgState(state, arg.organizationId);
      members.countFetch.requestId = requestId;
    });
    builder.addCase(
      fetchOrganizationMembersCount.fulfilled,
      (state, action) => {
        const { arg, requestId } = action.meta;
        const { members } = getOrSetOrgState(state, arg.organizationId);
        if (members.countFetch.requestId !== requestId) return;
        members.countFetch.newRequest = null;
        members.countFetch.initiallyLoaded = true;
        members.count = action.payload.count;
        members.hasMore = members.count > members.list.length;
      }
    );
    builder.addCase(deleteOrganizationMember.pending, (state, action) =>
      withUndo(state, action.meta.requestId, (state) => {
        const { arg } = action.meta;
        const { members } = getOrSetOrgState(state, arg.organizationId);
        members.list = members.list.filter(
          (it) => !arg.memberId.includes(it.id)
        );
        members.count = Math.max(members.count - 1, 0);
      })
    );
    builder.addCase(deleteOrganizationMember.fulfilled, cancelUndo);
    builder.addCase(deleteOrganizationMember.rejected, undo);
    builder.addCase(changeOrganizationMemberRole.pending, (state, action) =>
      withUndo(state, action.meta.requestId, (state) => {
        const { arg } = action.meta;
        const { members } = getOrSetOrgState(state, arg.organizationId);
        members.list = members.list.map((it) => {
          const memberToChange = arg.members.find(
            ({ memberId }) => memberId === it.id
          );
          if (!memberToChange) return it;
          return {
            ...it,
            organizationRole: memberToChange.role
          };
        });
      })
    );
    builder.addCase(changeOrganizationMemberRole.fulfilled, cancelUndo);
    builder.addCase(changeOrganizationMemberRole.rejected, undo);

    // TODO: change it after finishing ws refactor
    // - add case to update user list
    // - add case to update workspace
    // - add case to update seats count
    builder.addCase<
      string,
      PayloadAction<{ workspaceId: string; organizationId: string }>
    >(WorkspaceActionTypes.REMOVE_WORKSPACE, (state, action) => {
      const { organizationId, workspaceId } = action.payload;
      const organizationState = getOrSetOrgState(state, organizationId);
      if (!organizationState.workspaces.fetch.initiallyLoaded) return;
      organizationState.workspaces.list =
        organizationState.workspaces.list.filter((it) => it.id !== workspaceId);
    });
    builder.addCase<
      string,
      PayloadAction<RenameWorkspaceDto & { organizationId: string }>
    >(WorkspaceActionTypes.RENAME_WORKSPACE, (state, action) => {
      const { name, workspaceId, organizationId } = action.payload;
      const organizationState = getOrSetOrgState(state, organizationId);
      if (!organizationState.workspaces.fetch.initiallyLoaded) return;
      organizationState.workspaces.list = organizationState.workspaces.list.map(
        (it) => {
          if (it.id === workspaceId) return { ...it, name };
          return it;
        }
      );
    });
    builder.addCase<string, PayloadAction<GetAssetSuccessDto>>(
      AssetsActionTypes.ADD_NEW_ASSET,
      (state, action) => {
        const { organizationId } = action.payload.asset;
        const organizationState = getOrSetOrgState(state, organizationId);
        if (!organizationState.assetsSummarySize.sizeFetch.initiallyLoaded)
          return;
        newRequest(organizationState.assetsSummarySize.sizeFetch, 'refresh');
      }
    );
  }
});

export const {
  loadMoreInvoices,
  loadMoreMembers,
  setOrganizationMembersListParams,
  setOrganizationWorkspacesListParams,
  setOrganizationActiveTab,
  setOrganizationWorkspacesListNeedRefresh,
  setOrganizationMembersListNeedRefresh,
  setOrganizationInvoicesListNeedRefresh,
  deleteOrganizationFromList,
  setOrganizationName
} = organizationsSlice.actions;
export default organizationsSlice.reducer;
