import React, { memo, useCallback, useMemo } from 'react';
import { Button } from 'antd';
import { ReactComponent as CloseSvg } from '@assets/icons/cross.svg';
import { ReactComponent as SuccessSvg } from '@assets/icons/approvals-empty.svg';

import './OnboardingProcess.less';
import { useNavigate } from 'react-router-dom';
import { IsOnboardingCompleted } from '@helpers/StartOnboardingSteps';
import { useCurrentWorkspace } from '@hooks/workspace';
import { User } from '@context/AuthContext/types';
import { OnboardingStepsType } from '@context/OnboardingProcessProvider';
import LottieComponent from '@components/Lottie';

type OnboardingProcessProps = {
  copies?: { title: string; description: string; button: string };
  onCloseProcess: () => void;
  onStartTour: ({
    type,
    theme
  }: {
    type: OnboardingStepsType | undefined;
    theme?: 'dark';
  }) => void;
  user: User<'internal' | 'external-reviewer'> | null;
};

export default memo(function OnboardingProcess({
  copies,
  onCloseProcess,
  onStartTour,
  user
}: OnboardingProcessProps) {
  const [currentWorkspace] = useCurrentWorkspace(false);
  const navigate = useNavigate();

  const onContinueOnboarding = useCallback(() => {
    navigate('/start');
    onCloseProcess();
  }, [navigate, onCloseProcess]);

  const isOnboardingCompleted = useMemo(() => {
    return IsOnboardingCompleted(
      currentWorkspace?.role === 'workspace://contributor',
      user?.completedOnboardingSteps ?? []
    );
  }, [user?.completedOnboardingSteps, currentWorkspace?.role]);

  const onCloseOnboardingProcess = useCallback(() => {
    onCloseProcess();
    if (isOnboardingCompleted) {
      navigate('/start');
      onStartTour({ type: 'revisit-onboarding' });
    }
  }, [navigate, onCloseProcess, onStartTour, isOnboardingCompleted]);

  if (isOnboardingCompleted)
    return (
      <div className="onboarding-completed-modal">
        <LottieComponent view="confetti" className="icon" isLooped={false} />
        <div className="onboarding-completed-modal-inner">
          <div
            className="onboarding-completed-modal-inner--close"
            onClick={onCloseProcess}
          >
            <CloseSvg />
          </div>
          <div className="onboarding-completed-modal-inner--icon">
            <SuccessSvg />
          </div>
          <div className="onboarding-completed-modal-inner--title">
            Woohoo! You made it!
          </div>
          <div className="onboarding-completed-modal-inner--description">
            Now it&apos;s time to become a StreamWork pro. Set-up a workspace,
            invite team members, and start shipping creative faster today!
          </div>
          <div className="onboarding-completed-modal-inner--button">
            <Button type="primary" onClick={onCloseOnboardingProcess}>
              Awesome
            </Button>
          </div>
        </div>
      </div>
    );

  return (
    <div className="onboarding-process">
      <div className="onboarding-process-wrapper">
        <div className="onboarding-process-wrapper-top">
          <div className="onboarding-process-wrapper-top__icon">
            <SuccessSvg />
          </div>
          <div className="onboarding-process-wrapper-top__copies">
            <h3>{copies?.title}</h3>
            <p>{copies?.description}</p>
          </div>
          <div className="onboarding-process-wrapper-top__close">
            <CloseSvg onClick={onCloseOnboardingProcess} />
          </div>
        </div>
        <div className="onboarding-process-wrapper-bottom">
          <div className="onboarding-process-wrapper-bottom__button">
            <Button type="text" onClick={onContinueOnboarding}>
              {copies?.button}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
});
