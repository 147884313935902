import React from 'react';
import { ErrorPage } from '@pages/ErrorPage/index';
import { ReactComponent as Error } from '@assets/icons/network-error.svg';

export default function ErrorNetworkPage() {
  return (
    <ErrorPage
      content={
        <div style={{ textAlign: 'center', position: 'relative' }}>
          <Error />
          <div className="text-container" style={{ marginTop: 56 }}>
            <span>Network Error</span>
            <span style={{ maxWidth: 384 }}>
              Something is temporarily wrong with your network connection.
              Please make sure you are connected to the internet and then reload
              your browser.
            </span>
          </div>
        </div>
      }
      buttonCallback={() => {
        window.location.reload();
      }}
      buttonCopy="Reload"
    />
  );
}
