import { AssetItemDto, CampaignItemDto } from '@api/Api';
import { getWorkspaceCounters } from '@api/Workspace';
import {
  IWorkspaceCountersAction,
  WorkspaceCountersTypes
} from '@redux/types/workspaceCountersType';
import { Dispatch } from 'react';

export const setWorkspaceCounters =
  (workspaceId: string) =>
  async (dispatch: Dispatch<IWorkspaceCountersAction>) => {
    const { data } = await getWorkspaceCounters(workspaceId);

    dispatch({
      type: WorkspaceCountersTypes.SET_WORKSPACE_COUNTERS,
      payload: {
        ...data,
        workspaceId
      }
    });
  };

export const updateWorkspaceCounters = ({
  isTaskCounter,
  entity,
  count
}: {
  isTaskCounter?: boolean;
  entity: string;
  count: number;
}) => ({
  type: WorkspaceCountersTypes.UPDATE_WORKSPACE_COUNTERS,
  payload: { isTaskCounter, entity, count }
});

export const updateAssetsWorkspaceCounters = (
  event:
    | 'create'
    | 'setFavorite'
    | 'unsetFavorite'
    | 'deletePermanent'
    | 'delete'
    | 'restore'
    | 'addToStack'
    | 'revertFromStack',
  asset?: AssetItemDto
) => ({
  type: WorkspaceCountersTypes.UPDATE_ASSETS_WORKSPACE_COUNTERS,
  payload: { event, asset }
});

export const updateCampaignsWorkspaceCounters = (
  event:
    | 'create'
    | 'setFavorite'
    | 'unsetFavorite'
    | 'deletePermanent'
    | 'delete'
    | 'lostAccess'
    | 'restore',
  campaign?: CampaignItemDto | null
) => ({
  type: WorkspaceCountersTypes.UPDATE_CAMPAIGNS_WORKSPACE_COUNTERS,
  payload: { event, campaign }
});
