import {
  WorkspaceActionTypes,
  IWorkspaceState,
  IWorkspaceAction
} from '@redux/types/workspaceType';

const INITIAL_STATE: IWorkspaceState = {
  workspaceList: [],
  currentWorkspaceId: null
};

const workspaceReducer = (
  state = INITIAL_STATE,
  action: IWorkspaceAction
): IWorkspaceState => {
  switch (action.type) {
    case WorkspaceActionTypes.SET_WORKSPACE_LIST: {
      return {
        ...state,
        workspaceList: action.payload
      };
    }
    case WorkspaceActionTypes.SET_CURRENT_WORKSPACE_ID: {
      return {
        ...state,
        currentWorkspaceId: action.payload
      };
    }
    case WorkspaceActionTypes.CREATE_NEW_WORKSPACE: {
      const hasWorkspace = state.workspaceList.find(
        (el) => el.id === action.payload.workspace.id
      );
      if (hasWorkspace) return state;
      return {
        ...state,
        workspaceList: [...state.workspaceList, action.payload.workspace]
      };
    }
    case WorkspaceActionTypes.REMOVE_WORKSPACE: {
      const workspaceList = state.workspaceList.filter(
        (el) => el.id !== action.payload.workspaceId
      );
      return {
        ...state,
        workspaceList
      };
    }
    case WorkspaceActionTypes.RENAME_WORKSPACE: {
      const workspaceList = state.workspaceList.map((el) => {
        if (el.id === action.payload.workspaceId)
          return { ...el, name: action.payload.name };
        return el;
      });
      return {
        ...state,
        workspaceList
      };
    }
    case WorkspaceActionTypes.LEAVE_WORKSPACE: {
      const workspaceList = state.workspaceList.filter(
        (el) => el.id !== action.payload
      );
      return {
        ...state,
        workspaceList
      };
    }
    case WorkspaceActionTypes.UPDATE_WORKSPACE: {
      const { workspace } = action.payload;
      const workspaceList = state.workspaceList.map((el) => {
        if (el.id === workspace.id) return workspace;
        return el;
      });
      return {
        ...state,
        workspaceList
      };
    }
    default: {
      return state;
    }
  }
};

export default workspaceReducer;
