import React, { useState, useEffect, useRef } from 'react';
import { Form, Input } from 'antd';

const BACKSPACE_KEY = 8;
const LEFT_ARROW_KEY = 37;
const UP_ARROW_KEY = 38;
const RIGHT_ARROW_KEY = 39;
const DOWN_ARROW_KEY = 40;
const E_KEY = 69;

function CodeLine({
  length,
  isValid,
  onChange,
  disabled
}: {
  length: number;
  isValid?: boolean;
  onChange?: (value: string) => void;
  disabled?: boolean;
}) {
  const [input, setInput] = useState<string[]>([]);

  const textInput = useRef<any[]>([]);

  useEffect(() => {
    const newInput = [];

    for (let i = 0; i < Number(length); i += 1) {
      if (i < 32) {
        newInput.push('');
      }
    }

    setInput(newInput);
  }, [length]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = String(e.target.value).replace(/[^\d]/g, '');
    const newInput = [...input];

    if (newValue !== '') {
      if (newValue.length > 1) {
        newValue.split('').map((chart, i) => {
          if (Number(e.target.dataset.id) + i < length) {
            newInput[Number(e.target.dataset.id) + i] = chart;
          }
          return false;
        });
      } else {
        newInput[Number(e.target.dataset.id)] = newValue;
      }

      newInput.map((s: string, i: number) => {
        if (textInput.current[i]) {
          textInput.current[i].value = s;
        }
        return false;
      });

      const newTarget =
        textInput.current[
          Number(e.target.dataset.id) < input.length
            ? Number(e.target.dataset.id) + 1
            : Number(e.target.dataset.id)
        ];

      if (newTarget) {
        newTarget.focus();
        newTarget.select();
      }

      const fullValue = String(newInput.join(''));

      setInput(newInput);

      if (onChange && fullValue) {
        onChange(fullValue);
      }
    }
  };

  const handleKeyDown = (e: any) => {
    const target = Number(e.target.dataset.id);
    const nextTarget = textInput.current[target + 1];
    const prevTarget = textInput.current[target - 1];

    let newInput;
    let newValue;

    switch (e.keyCode) {
      case BACKSPACE_KEY:
        e.preventDefault();
        textInput.current[target].value = '';
        newInput = input.slice();
        newInput[target] = '';
        newValue = newInput.join('');

        setInput(newInput);
        if (textInput.current[target].value === '') {
          if (prevTarget) {
            prevTarget.focus();
            prevTarget.select();
          }
        }
        if (onChange) {
          onChange(newValue);
        }
        break;

      case LEFT_ARROW_KEY:
        e.preventDefault();
        if (prevTarget) {
          prevTarget.focus();
          prevTarget.select();
        }
        break;

      case RIGHT_ARROW_KEY:
        e.preventDefault();
        if (nextTarget) {
          nextTarget.focus();
          nextTarget.select();
        }
        break;

      case UP_ARROW_KEY:
        e.preventDefault();
        break;

      case DOWN_ARROW_KEY:
        e.preventDefault();
        break;

      case E_KEY:
        if (e.target.type === 'number') {
          e.preventDefault();
          break;
        }
        break;

      default:
        break;
    }
  };

  return (
    <div className="code_line">
      <div className="code_line__items">
        {input.map((val: string, i: number) => {
          return (
            <Form.Item
              validateStatus={!isValid ? 'error' : ''}
              key={`input_${i}`}
              className="input_block"
            >
              <Input
                ref={(ref) => {
                  textInput.current[i] = ref;
                }}
                data-id={i}
                value={val}
                type="text"
                min={0}
                max={9}
                maxLength={input.length === i + 1 ? 1 : input.length}
                autoComplete="off"
                onFocus={(e) => e.target.select()}
                onChange={(e) => handleChange(e)}
                onKeyDown={(e) => handleKeyDown(e)}
                disabled={disabled}
                pattern="[0-9]*"
                inputMode="numeric"
                placeholder=" "
              />
              <span className="input_block__line" />
            </Form.Item>
          );
        })}
      </div>

      {!isValid && (
        <span className="code_line__error ant-form-item-explain-error">
          Whoops! The verification code you entered is incorrect or expired
        </span>
      )}
    </div>
  );
}

CodeLine.defaultProps = {
  onChange: undefined,
  disabled: false,
  isValid: true
};

export default CodeLine;
