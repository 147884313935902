import {
  ChangeOrganizationMembersRoleMemberDto,
  InvoiceItemDto,
  OrganizationControllerListMembersParams,
  OrganizationMemberDto,
  PageDto,
  PaidSubscriptionDTO,
  SubscriptionControllerGetInvoicesListParams
} from '@api/Api';
import { apiClient } from '@api/client';
import { toggleReachedEntityLimitModal } from '@redux/actions/modalAction';
import { itemInitialState } from '@redux/initial-states/organization';
import { RootState } from '@redux/store';
import { ItemFetchType, ListFetchType } from '@redux/types/organizations';
import { createAsyncThunk, miniSerializeError } from '@reduxjs/toolkit';
import { MixpanelEventType, MixpanelService } from '@services/mixpanelService';
import axios, { isAxiosError } from 'axios';

function getOrgState(state: RootState, organizationId: string) {
  return state.organizations.items[organizationId] ?? itemInitialState();
}

interface IFetchOrganizationsPayload {
  fetchType: Exclude<ListFetchType, 'more'>;
}

export const fetchOrganizationsList = createAsyncThunk(
  'organizations/fetchOrganizationsList',
  async (payload: IFetchOrganizationsPayload, thunkAPI) => {
    try {
      const cts = axios.CancelToken.source();
      thunkAPI.signal.addEventListener('abort', () => cts.cancel());
      const {
        data: { list }
      } =
        await apiClient.organization.organizationControllerListUserOrganizations();
      return { list };
    } catch (err) {
      console.error(err);
      throw err;
    }
  }
);

interface IFetchOrganizationByIdPayload {
  organizationId: string;
  fetchType: ItemFetchType;
}

export const fetchOrganizationById = createAsyncThunk(
  'organizations/fetchOrganizationById',
  async (payload: IFetchOrganizationByIdPayload, thunkAPI) => {
    try {
      const cts = axios.CancelToken.source();
      thunkAPI.signal.addEventListener('abort', () => cts.cancel());
      const { organizationId } = payload;
      const {
        data: { organization }
      } = await apiClient.organization.organizationControllerGetOrganization({
        organizationId
      });
      return { organization };
    } catch (err) {
      console.error(err);
      throw err;
    }
  },
  {
    serializeError(err) {
      const result = miniSerializeError(err);
      if (axios.isAxiosError(err)) {
        result.code = err.response?.status.toString();
      }
      return result;
    }
  }
);

interface IDeleteOrganization {
  organizationId: string;
}

export const deleteOrganization = createAsyncThunk(
  'organizations/deleteOrganization',
  async (payload: IDeleteOrganization, thunkAPI) => {
    try {
      const cts = axios.CancelToken.source();
      thunkAPI.signal.addEventListener('abort', () => cts.cancel());
      const { organizationId } = payload;
      await apiClient.organization.organizationControllerDeleteOrganization({
        organizationId
      });
    } catch (err) {
      console.error(err);
      throw err;
    }
  }
);

interface IRenameOrganization {
  organizationId: string;
  name: string;
}

export const renameOrganization = createAsyncThunk(
  'organizations/renameOrganization',
  async (payload: IRenameOrganization, thunkAPI) => {
    try {
      const cts = axios.CancelToken.source();
      thunkAPI.signal.addEventListener('abort', () => cts.cancel());
      const { organizationId, name } = payload;
      await apiClient.organization.organizationControllerRenameOrganization({
        organizationId,
        name
      });
    } catch (err) {
      console.error(err);
      throw err;
    }
  }
);

interface ILeaveOrganization {
  organizationId: string;
}

export const leaveOrganization = createAsyncThunk(
  'organizations/leaveOrganization',
  async (payload: ILeaveOrganization, thunkAPI) => {
    try {
      const cts = axios.CancelToken.source();
      thunkAPI.signal.addEventListener('abort', () => cts.cancel());
      const { organizationId } = payload;
      await apiClient.organization.organizationControllerLeaveOrganization({
        organizationId
      });
    } catch (err) {
      console.error(err);
      throw err;
    }
  }
);

interface IFetchOrganizationWorkspacesListPayload {
  fetchType: Exclude<ListFetchType, 'more'>;
  organizationId: string;
}

export const fetchOrganizationWorkspacesList = createAsyncThunk(
  'organizations/fetchOrganizationWorkspacesList',
  async (payload: IFetchOrganizationWorkspacesListPayload, thunkAPI) => {
    try {
      const cts = axios.CancelToken.source();
      thunkAPI.signal.addEventListener('abort', () => cts.cancel());
      const { organizationId } = payload;
      const {
        data: { workspaces }
      } =
        await apiClient.organization.organizationControllerListOrganizationWorkpsaces(
          {
            organizationId
          }
        );
      return { workspaces };
    } catch (err) {
      console.error(err);
      throw err;
    }
  }
);

interface IFetchOrganizationMembersListPayload {
  fetchType: ListFetchType;
  organizationId: string;
}

export const fetchOrganizationMembersList = createAsyncThunk(
  'organizations/fetchOrganizationMembersList',
  async (payload: IFetchOrganizationMembersListPayload, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const { organizationId } = payload;
      const organizationState = getOrgState(state, organizationId);
      const { searchQuery, orderBy, cursor, hasMore } =
        organizationState.members;
      const cts = axios.CancelToken.source();
      thunkAPI.signal.addEventListener('abort', () => cts.cancel());
      const params: OrganizationControllerListMembersParams = {
        organizationId,
        orderBy,
        searchQuery
      };
      let data:
        | (PageDto & {
            edges: {
              node: OrganizationMemberDto;
              cursor: string;
            }[];
          })
        | undefined;
      if (payload.fetchType === 'refresh' && cursor) {
        // Loading items in backward direction right to the start of the list
        let hasPrevious = false;
        do {
          const response =
            await apiClient.organization.organizationControllerListMembers(
              {
                ...params,
                before: data ? data.startCursor : cursor,
                inclusive: !data,
                limit: 100 // max limit
              },
              { cancelToken: cts.token }
            );
          hasPrevious = response.data.hasNext;
          if (!data) {
            data = response.data;
            data.hasNext = hasMore;
          } else {
            data.startCursor = response.data.startCursor;
            const responseIds = response.data.edges.map((x) => x.node.id);
            data.edges = [
              ...response.data.edges,
              ...data.edges.filter((x) => !responseIds.includes(x.node.id))
            ];
          }
        } while (hasPrevious);
      } else {
        const response =
          await apiClient.organization.organizationControllerListMembers(
            {
              ...params,
              after:
                payload.fetchType === 'more' ? cursor ?? undefined : undefined
            },
            { cancelToken: cts.token }
          );
        data = response.data;
      }
      return data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }
);

interface IFetchOrganizationMembersCountPayload {
  fetchType: ItemFetchType;
  organizationId: string;
}

export const fetchOrganizationMembersCount = createAsyncThunk(
  'organizations/fetchOrganizationMembersCount',
  async (payload: IFetchOrganizationMembersCountPayload, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const cts = axios.CancelToken.source();
      thunkAPI.signal.addEventListener('abort', () => cts.cancel());
      const { organizationId } = payload;
      const organizationState = getOrgState(state, organizationId);
      const { searchQuery } = organizationState.members;
      const {
        data: { count }
      } = await apiClient.organization.organizationControllerCountMembers({
        organizationId,
        searchQuery
      });
      return { count };
    } catch (err) {
      console.error(err);
      throw err;
    }
  }
);

type IDeleteOrganizationMemberPayload = {
  memberId: string[];
  organizationId: string;
};

export const deleteOrganizationMember = createAsyncThunk(
  'organizations/deleteOrganizationMember',
  async (payload: IDeleteOrganizationMemberPayload, thunkAPI) => {
    try {
      const cts = axios.CancelToken.source();
      thunkAPI.signal.addEventListener('abort', () => cts.cancel());
      const { organizationId, memberId } = payload;
      await apiClient.organization.organizationControllerDeleteOrganizationMembers(
        { memberId, organizationId }
      );
    } catch (err) {
      console.error(err);
      throw err;
    }
  }
);

type IChangeOrganizationMemberRole = {
  organizationId: string;
  members: ChangeOrganizationMembersRoleMemberDto[];
};

export const changeOrganizationMemberRole = createAsyncThunk(
  'organizations/changeOrganizationMemberRole',
  async (payload: IChangeOrganizationMemberRole, thunkAPI) => {
    try {
      const cts = axios.CancelToken.source();
      thunkAPI.signal.addEventListener('abort', () => cts.cancel());
      const { organizationId, members } = payload;
      await apiClient.organization.organizationControllerChangeOrganizationMembersRole(
        { members, organizationId }
      );
    } catch (err) {
      if (
        isAxiosError(err) &&
        err.response?.data.errorCode === 'feature_limit_exceeded'
      ) {
        thunkAPI.dispatch(
          toggleReachedEntityLimitModal({
            visible: true,
            entity:
              err.response?.data.feature === 'admins' ? 'admins' : 'unknown'
          })
        );
      }
      throw err;
    }
  }
);

interface IFetchOrganizationSubscriptionPayload {
  fetchType: ItemFetchType;
  organizationId: string;
}

export const fetchOrganizationSubscription = createAsyncThunk(
  'organizations/fetchOrganizationSubscription',
  async (payload: IFetchOrganizationSubscriptionPayload, thunkAPI) => {
    try {
      const cts = axios.CancelToken.source();
      thunkAPI.signal.addEventListener('abort', () => cts.cancel());
      const { organizationId } = payload;
      const {
        data: { subscription }
      } = await apiClient.subscription.subscriptionControllerGetSubscription({
        organizationId
      });
      return { subscription };
    } catch (err) {
      console.error(err);
      throw err;
    }
  }
);

export interface IUpdateOrganizationSubscriptionPayload {
  organizationId: string;
  type: 'starter' | 'team';
  period: 'monthly' | 'annually';
  state?: string;
  mixpanel: any;
  userId: string;
}

export const updateOrganizationSubscription = createAsyncThunk(
  'organizations/updateOrganizationSubscription',
  async (payload: IUpdateOrganizationSubscriptionPayload, thunkAPI) => {
    try {
      const rootState = thunkAPI.getState() as RootState;
      const organization =
        rootState.organizations.items[payload.organizationId];
      const cts = axios.CancelToken.source();
      thunkAPI.signal.addEventListener('abort', () => cts.cancel());
      const { organizationId, type, period, state, mixpanel, userId } = payload;
      const {
        data: { subscription, paymentUrl }
      } = await apiClient.subscription.subscriptionControllerUpdateSubscription(
        {
          period,
          type,
          organizationId,
          state
        }
      );
      if (organization.entity) {
        await MixpanelService.track(
          mixpanel,
          userId,
          MixpanelEventType.CHANGE_PLAN,
          {
            oldPlanType: organization.subscription.it.type,
            newPlanType: type,
            updateDate: new Date().toISOString(),
            period,
            oldPeriod: (organization.subscription as any)?.period ?? null,
            usersCount: organization.seatsCount.count
          }
        );
      }
      return { subscription, paymentUrl };
    } catch (err) {
      console.error(err);
      throw err;
    }
  }
);

export interface ICancelOrganizationSubscriptionPayload {
  organizationId: string;
  mixpanel: any;
  userId: string;
}

export const cancelOrganizationSubscription = createAsyncThunk(
  'organizations/cancelOrganizationSubscription',
  async (payload: ICancelOrganizationSubscriptionPayload, thunkAPI) => {
    try {
      const rootState = thunkAPI.getState() as RootState;
      const organization =
        rootState.organizations.items[payload.organizationId];
      const cts = axios.CancelToken.source();
      thunkAPI.signal.addEventListener('abort', () => cts.cancel());
      const { organizationId, mixpanel, userId } = payload;
      const {
        data: { subscription }
      } = await apiClient.subscription.subscriptionControllerCancelSubscription(
        {
          organizationId
        }
      );
      if (organization.entity) {
        await MixpanelService.track(
          mixpanel,
          userId,
          MixpanelEventType.CHANGE_PLAN,
          {
            oldPlanType: organization.subscription.it.type,
            newPlanType: 'free',
            oldPeriod:
              (organization.subscription.it as PaidSubscriptionDTO).period ??
              null,
            period: null,
            updateDate: new Date().toISOString(),
            usersCount: organization.seatsCount.count
          }
        );
      }
      return { subscription };
    } catch (err) {
      console.error(err);
      throw err;
    }
  }
);

interface IFetchOrganizationSeatsUsedPayload {
  fetchType: ItemFetchType;
  organizationId: string;
}

export const fetchOrganizationSeatsUsed = createAsyncThunk(
  'organizations/fetchOrganizationSeatsUsed',
  async (payload: IFetchOrganizationSeatsUsedPayload, thunkAPI) => {
    try {
      const cts = axios.CancelToken.source();
      thunkAPI.signal.addEventListener('abort', () => cts.cancel());
      const { organizationId } = payload;
      const {
        data: { seatsCount }
      } = await apiClient.subscription.subscriptionControllerGetSeatsUsage({
        organizationId
      });
      return { seatsCount };
    } catch (err) {
      console.error(err);
      throw err;
    }
  }
);

interface IFetchOrganizationStorageUsedPayload {
  fetchType: ItemFetchType;
  organizationId: string;
}

export const fetchOrganizationStorageUsed = createAsyncThunk(
  'organizations/fetchOrganizationStorageUsed',
  async (payload: IFetchOrganizationStorageUsedPayload, thunkAPI) => {
    try {
      const cts = axios.CancelToken.source();
      thunkAPI.signal.addEventListener('abort', () => cts.cancel());
      const { organizationId } = payload;
      const {
        data: { assetsSummarySize }
      } = await apiClient.subscription.subscriptionControllerGetAssetsUsage({
        organizationId
      });
      return { assetsSummarySize };
    } catch (err) {
      console.error(err);
      throw err;
    }
  }
);

interface IFetchOrganizationPaymentMethod {
  fetchType: ItemFetchType;
  organizationId: string;
}

export const fetchOrganizationPaymentMethod = createAsyncThunk(
  'organizations/fetchOrganizationPaymentMethod',
  async (payload: IFetchOrganizationPaymentMethod, thunkAPI) => {
    try {
      const cts = axios.CancelToken.source();
      thunkAPI.signal.addEventListener('abort', () => cts.cancel());
      const { organizationId } = payload;
      const {
        data: { card }
      } = await apiClient.subscription.subscriptionControllerGetCard({
        organizationId
      });
      return { card };
    } catch (err) {
      console.error(err);
      throw err;
    }
  }
);

interface IDeleteOrganizationPaymentMethod {
  organizationId: string;
}

export const deleteOrganizationPaymentMethod = createAsyncThunk(
  'organizations/deleteOrganizationPaymentMethod',
  async (payload: IDeleteOrganizationPaymentMethod, thunkAPI) => {
    try {
      const cts = axios.CancelToken.source();
      thunkAPI.signal.addEventListener('abort', () => cts.cancel());
      const { organizationId } = payload;
      await apiClient.subscription.subscriptionControllerDeleteCard({
        organizationId
      });
    } catch (err) {
      console.error(err);
      throw err;
    }
  }
);

interface IFetchOrganizationInvoiceHistory {
  organizationId: string;
  fetchType: ListFetchType;
}

export const fetchOrganizationInvoiceHistory = createAsyncThunk(
  'organizations/fetchOrganizationInvoiceHistory',
  async (payload: IFetchOrganizationInvoiceHistory, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const { organizationId } = payload;
      const organizationState = getOrgState(state, organizationId);
      const { cursor, hasMore } = organizationState.invoices;
      const cts = axios.CancelToken.source();
      thunkAPI.signal.addEventListener('abort', () => cts.cancel());

      const params: SubscriptionControllerGetInvoicesListParams = {
        organizationId
      };
      let data:
        | (PageDto & {
            edges: {
              node: InvoiceItemDto;
              cursor: string;
            }[];
          })
        | undefined;
      if (payload.fetchType === 'refresh' && cursor) {
        let hasPrevious = false;
        do {
          const response =
            await apiClient.subscription.subscriptionControllerGetInvoicesList(
              {
                ...params,
                before: data ? data.startCursor : cursor,
                inclusive: !data,
                limit: 100 // max limit
              },
              { cancelToken: cts.token }
            );
          hasPrevious = response.data.hasNext;
          if (!data) {
            data = response.data;
            data.hasNext = hasMore;
          } else {
            data.startCursor = response.data.startCursor;
            const responseIds = response.data.edges.map((x) => x.node.id);
            data.edges = [
              ...response.data.edges,
              ...data.edges.filter((x) => !responseIds.includes(x.node.id))
            ];
          }
        } while (hasPrevious);
      } else {
        const response =
          await apiClient.subscription.subscriptionControllerGetInvoicesList(
            {
              ...params,
              after:
                payload.fetchType === 'more' ? cursor ?? undefined : undefined
            },
            { cancelToken: cts.token }
          );
        data = response.data;
      }
      return data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }
);
