import {
  createCampaignSteps,
  createTaskFromCommentSteps,
  createTaskSteps,
  firstEnterSteps,
  revisitOnboardingSteps,
  routeForApprovalSteps,
  uploadAssetSteps
} from '@components/AppTour/Steps';
import { OnboardingStepsType } from '@context/OnboardingProcessProvider';
import { useTypedSelector } from '@hooks';
import useTypedDispatch from '@hooks/useTypedDispatch';
import { updateAssetSelectedVersion } from '@redux/actions/mediaViewerAction';
import { toggleApprovalModal } from '@redux/actions/modalAction';
import { createTaskBoard } from '@redux/actions/tasks';
import { openNewTaskModal } from '@redux/reducers/tasks';
import { boardsSelector } from '@redux/selectors/tasks';
import { Button } from 'antd';
import classNames from 'classnames';
import React, {
  memo,
  useCallback,
  useRef,
  useMemo,
  useState,
  useEffect
} from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Tour, { ReactourStep } from 'reactour';

type setTourDataProps = {
  type: OnboardingStepsType;
  theme?: 'dark' | 'white';
};

type AppTourProps = {
  tourData: { type: OnboardingStepsType; theme?: 'dark' | 'white' } | undefined;
  goToStep: number;
  setTourData: (data: setTourDataProps | undefined) => void;
  setGoToStep: (v: number) => void;
  onCompleteStep: ({
    type,
    targetId,
    hideCongrats
  }: {
    type: OnboardingStepsType;
    targetId?: string | null;
    hideCongrats?: boolean;
  }) => void;
  onCloseProcess: () => void;
};

export default memo(function AppTour({
  tourData,
  goToStep,
  setGoToStep,
  setTourData,
  onCompleteStep,
  onCloseProcess
}: AppTourProps) {
  const [currentStep, setCurrentStep] = useState<number>(0);

  const dispatch = useTypedDispatch();
  const selectedCampaign = useTypedSelector(
    (state) => state.campaign.selectedCampaign
  );
  const campaignId = selectedCampaign?.id;
  const boardsLength = useTypedSelector((state) =>
    boardsSelector(state, { campaignId: campaignId ?? '' })
  ).length;
  const nonAssignedBoard = useTypedSelector((state) =>
    boardsSelector(state, { campaignId: campaignId ?? '' })
  )[0];
  const namedBoard = useTypedSelector((state) =>
    boardsSelector(state, { campaignId: campaignId ?? '' })
  )[1];
  const assets = useTypedSelector((state) => state.mediaViewer.assets);
  const selectedVersion = useTypedSelector(
    (state) => state.mediaViewer.selectedVersion
  );
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const searchParamsRef = useRef(searchParams);
  searchParamsRef.current = searchParams;

  const setSearchParamsRef = useRef(setSearchParams);
  setSearchParamsRef.current = setSearchParams;

  const nextButton = useMemo(() => {
    let style = {};
    let copy = 'Next';
    if (tourData?.type === 'first-enter-steps' && currentStep === 0) {
      copy = 'Let’s Get Started';
      style = { height: '48px', padding: '11px 15px' };
    }

    return (
      <Button type="primary" style={style}>
        {copy}
      </Button>
    );
  }, [tourData?.type, currentStep]);

  const lastStepNextButton = useMemo(() => {
    let copy = 'Done';
    if (tourData?.type === 'upload-asset') copy = 'Got it';
    if (tourData?.type === 'revisit-onboarding') copy = 'Ok';
    return <Button type="primary">{copy}</Button>;
  }, [tourData?.type]);

  useEffect(() => {
    setGoToStep(0);
    setCurrentStep(0);
  }, [setGoToStep, tourData?.type]);

  const onClose = useCallback(
    (hideCongrats?: boolean) => {
      setTourData(undefined);
      if (!tourData?.type) return;
      let _hideCongrats = hideCongrats;
      if (tourData?.type === 'revisit-onboarding') _hideCongrats = true;
      if (tourData?.type === 'first-enter-steps') _hideCongrats = true;
      onCompleteStep({ type: tourData.type, hideCongrats: _hideCongrats });
      searchParamsRef.current.delete('startTour');
      setSearchParamsRef.current(searchParamsRef.current);
    },
    [onCompleteStep, setTourData, tourData?.type]
  );

  const onNextButtonClick = useCallback(() => {
    if (tourData?.type === 'create-task' && selectedCampaign) {
      if (currentStep === 0 && boardsLength <= 1) {
        dispatch(
          createTaskBoard({
            campaignId: selectedCampaign.id,
            name: 'Name task group'
          })
        );

        setGoToStep(currentStep + 1);

        return;
      }
      if (currentStep === 2) {
        const board =
          nonAssignedBoard.count > 0 ? nonAssignedBoard : namedBoard;
        dispatch(
          openNewTaskModal({
            organizationId: selectedCampaign.organizationId,
            workspaceId: selectedCampaign.workspaceId,
            board,
            campaign: selectedCampaign
          })
        );
        return;
      }
    }
    if (
      tourData?.type === 'route-for-approval' &&
      selectedVersion &&
      assets?.id
    ) {
      if (currentStep === 0) {
        dispatch(
          toggleApprovalModal({
            visible: true,
            assetVersion: selectedVersion,
            isLastVersion: true,
            assetId: assets.id,
            campaignId: assets.campaignId,
            allowCreate: !!assets.asset?.permissions.createApprovalWorkflows,
            callback: (asset) => {
              const variant = asset.asset?.versions.find(
                (el) => el.id === selectedVersion.id
              );
              if (!variant) return;
              dispatch(
                updateAssetSelectedVersion({ newSelectedVersion: variant })
              );
            }
          })
        );
        setTimeout(() => {
          setGoToStep(currentStep + 1);
        }, 500);

        return;
      }
    }
    setGoToStep(currentStep + 1);
  }, [
    currentStep,
    boardsLength,
    dispatch,
    setGoToStep,
    selectedCampaign,
    tourData?.type,
    assets?.id,
    selectedVersion,
    nonAssignedBoard,
    namedBoard
  ]);

  const steps: ReactourStep[] | undefined = useMemo(() => {
    if (!tourData?.type) return [];
    if (tourData.type === 'first-enter-steps') return firstEnterSteps();
    if (tourData.type === 'revisit-onboarding') return revisitOnboardingSteps();
    if (tourData.type === 'create-campaign') return createCampaignSteps();
    if (tourData.type === 'upload-asset')
      return uploadAssetSteps({ navigate, onClose, onCloseProcess });
    if (tourData.type === 'create-task-from-comment')
      return createTaskFromCommentSteps();
    if (tourData.type === 'create-task' && selectedCampaign) {
      return createTaskSteps({
        dispatch,
        setGoToStep,
        selectedCampaign,
        boardsLength
      });
    }

    if (tourData.type === 'route-for-approval' && selectedVersion && assets?.id)
      return routeForApprovalSteps({
        dispatch,
        setGoToStep,
        selectedVersion,
        assets
      });

    return [];
  }, [
    setGoToStep,
    boardsLength,
    dispatch,
    selectedCampaign,
    navigate,
    onClose,
    tourData?.type,
    assets?.id,
    onCloseProcess,
    selectedVersion
  ]);

  if (!steps.length) return null;
  return (
    <Tour
      className={classNames(
        { 'app-tour-assist': true },
        `app-tour-assist--${tourData?.theme}`,
        `app-tour-assist--${tourData?.type}`,
        {
          'app-tour-assist-first-enter':
            tourData?.type === 'first-enter-steps' && currentStep === 0
        }
      )}
      steps={steps}
      goToStep={goToStep}
      isOpen={!!tourData?.type}
      onRequestClose={() => onClose()}
      showNumber={false}
      startAt={0}
      showNavigation={false}
      closeWithMask={false}
      inViewThreshold={100}
      disableInteraction={true}
      getCurrentStep={setCurrentStep}
      nextStep={onNextButtonClick}
      nextButton={nextButton}
      lastStepNextButton={lastStepNextButton}
      rounded={8}
      maskSpace={8}
    />
  );
});
